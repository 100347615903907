<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Shared Hosting</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/legal">Legal</a></li>
                        <li>Shared Hosting</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="terms-conditions ptb-100">
    <div class="container">
        <div class="single-privacy">
            <h1 style="text-align: center">Pagina in manutenzione</h1>
            <h3 style="text-align: center">Ci scusiamo per il disagio!</h3>
            <h3 style="text-align: center">
                Stiamo lavorando duramente per migliorare la tua esperienza sul
                nostro sito. Al momento, alcune pagine sono in fase di aggiornamento
                e potrebbero non essere accessibili. Non temere, il nostro team
                è al lavoro per completarle nel minor tempo possibile.
            </h3>
        </div>
    </div>
</section>
