<section class="frequently-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Domande frequenti</h2>
            <p>Queste sono alcune delle domande più frequenti. Se vuoi maggiori dettagli consulta la pagina dedicata alle FAQ.</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="frequently-img">
                    <img src="assets/img/frequently-img.png" alt="Image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="frequently-content-wrap">
                    <ul>
                        <li>
                            <i class="flaticon-database"></i>
                            <h3>Cos'è WeeBo?</h3>
                            <p>WeeBo è un fornitore di servizi IT: hosting, VPS, sviluppo web e IT per aziende trentine. Velocità e semplicità al tuo servizio! Assistenza hardware e software a 360 gradi.</p>
                        </li>
                        <li>
                            <i class="flaticon-database-1"></i>
                            <h3>Perchè scegliere WeeBo?</h3>
                            <p>Scegliere WeeBo significa scegliere affidabilità, flessibilità e assistenza dedicata. Contattaci oggi stesso per scoprire come possiamo aiutare la tua azienda a crescere.</p>
                        </li>
                        <li>
                            <i class="flaticon-uptime"></i>
                            <h3>Cosa include il servizio a 360 gradi offerto da WeeBo?</h3>
                            <p>Il nostro servizio a 360 gradi comprende progettazione e sviluppo software su misura, sviluppo dell'hardware, e la messa online di soluzioni IT personalizzate per le esigenze specifiche della tua azienda. Scopri come possiamo supportare integralmente la crescita e lo sviluppo tecnologico della tua attività.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>