<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>FAQ</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Supporto</li>
                        <li>FAQ</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="protected-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Domande frequenti</h2>
            <p>Qua puoi trovare alcune delle domande più frequenti degli utenti.</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="frequently-content-wrap">
                    <ul>
                        <li>
                            <i class="flaticon-database-1"></i>
                            <h3>Quali sono i metodi di pagamento disponibili?</h3>
                            <p>Attualmente, offriamo la possibilità di effettuare pagamenti tramite bonifico bancario o con carta di credito mediante l'utilizzo del servizio Stripe.</p>
                        </li>
                        <li>
                            <i class="flaticon-diagram"></i>
                            <h3>Come si acquista un nome di dominio?</h3>
                            <p>Per ottenere un nome di dominio, ti consigliamo di rivolgerti a un registrar autorizzato. Attualmente, non supportiamo direttamente l'acquisto di domini.</p>
                        </li>
                        <li>
                            <i class="flaticon-uptime"></i>
                            <h3>Cos'è un web hosting?</h3>
                            <p>In breve, il web hosting consiste nell'offerta di spazio online per ospitare siti web, mentre un dominio web rappresenta l'indirizzo inserito nella barra degli URL del browser per accedere al tuo sito internet.</p>
                        </li>
                        <li>
                            <i class="flaticon-migrating"></i>
                            <h3>Cosa significa DNS?</h3>
                            <p>Il termine DNS sta per "Domain Name System". Si tratta di un sistema che traduce gli indirizzi IP numerici delle risorse online in nomi di dominio comprensibili, consentendo agli utenti di accedere ai siti web utilizzando gli indirizzi web familiari come www.esempio.com. I DNS svolgono un ruolo cruciale nel reindirizzare le richieste del tuo browser al server corretto, facilitando così la navigazione su Internet.</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="frequently-content-wrap">
                    <ul>
                        <li>
                            <i class="flaticon-migrating"></i>
                            <h3>Qual è il piano giusto per me?</h3>
                            <p>La scelta del piano dovrebbe rispecchiare le esigenze specifiche della tua attività. Se hai dubbi sul piano più adatto alle tue necessità, non esitare a contattarci per una consulenza gratuita. Siamo qui per aiutarti a trovare la soluzione perfetta per te.</p>
                        </li>
                        <li>
                            <i class="flaticon-network-1"></i>
                            <h3>WeeBo Hosting è affidabile?</h3>
                            <p>Hai dubbi sulla nostra affidabilità? Chiedi ai nostri clienti, sono loro la nostra migliore risposta.</p>
                        </li>
                        <li>
                            <i class="flaticon-database"></i>
                            <h3>Come si acquista?</h3>
                            <p>Seleziona il piano che meglio si adatta alle tue esigenze, premi il pulsante 'Iniziamo', inserisci il tuo dominio (se già di tua proprietà), completa il modulo di registrazione, effettua il pagamento e imposta i DNS di WeeBo nel tuo registrar. L'attivazione sarà immediata, consentendoti di utilizzare il servizio senza attese.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<app-contact-form-white></app-contact-form-white>