<section class="services-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Servizi di hosting</h2>
            <p>Alcuni dei nostri servizi.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-technology">
                    <div class="technology-icon-bg">
                        <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                        <i class="flaticon-uptime"></i>
                    </div>
                    <h3>99% Uptime</h3>
                    <p>Sempre online! Puoi consultare lo stato e verificare l'uptime dei nostri server nella tua area personale oppure infondo alla pagina.</p>
                    <a class="read-more" routerLink="/shared-hosting">Scopri di più <i class="bx bx-chevron-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-technology active">
                    <div class="technology-icon-bg">
                        <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                        <i class="flaticon-speedometer-1"></i>
                    </div>
                    <h3>Prestazioni Veloci</h3>
                    <p>Basta rallantementi e attese interminabili. Ora il tuo Hosting è vicino a te, riduci i tempi di caricamento delle pagine.</p>
                    <a class="read-more" routerLink="/shared-hosting">Scopri di più <i class="bx bx-chevron-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-technology">
                    <div class="technology-icon-bg">
                        <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                        <i class="flaticon-database-1"></i>
                    </div>
                    <h3>Garanzia di rimborso</h3>
                    <p>Se non siete soddisfatti delle prestazioni o dal servizio offerto è possibile ricevere un rimborso completo entro 15 giorni dall'attivazione.</p>
                    <a class="read-more" routerLink="/shared-hosting">Scopri di più <i class="bx bx-chevron-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>