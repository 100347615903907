import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-news-right-sidebar',
  templateUrl: './news-right-sidebar.component.html',
  styleUrls: ['./news-right-sidebar.component.scss']
})
export class NewsRightSidebarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
