<section class="hosting-services-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Cosa facciamo</h2>
            <p>Offriamo una vasta gamma di servizi IT, tra cui hosting, VPS, sviluppo web su misura e assistenza tecnica specializzata. La nostra missione è fornire soluzioni innovative e affidabili per soddisfare le esigenze dei nostri clienti.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-hosting-service">
                    <i class="flaticon-settings"></i>
                    <h3>IT Consulting</h3>
                    <p>Possiamo accompagnarvi e assistervi nelle scelte tecniche informatiche, offrirvi supporto o manutenzione, consigliarvi sistemi informatici innovativi, efficienti e veloci da utilizzare.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-hosting-service">
                    <i class="flaticon-network"></i>
                    <h3>Web Hosting</h3>
                    <p>Hosting affidabile e scalabile per ogni tipo di sito. Garantiamo sicurezza, performance e supporto dedicato per massimizzare il successo online. Supporto tecnico dedicato per assicurare la migliore esperienza.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-hosting-service">
                    <i class="flaticon-diagram"></i>
                    <h3>Macchine Virtuali VPS</h3>
                    <p>Forniamo macchine virtuali VPS potenti e personalizzabili, offrendo flessibilità e controllo totale sulle risorse del server per le tue applicazioni e siti web.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-hosting-service">
                    <i class="flaticon-backup"></i>
                    <h3>Sviluppo Web App</h3>
                    <p>Siamo esperti nello sviluppo di applicazioni web personalizzate e performanti, progettate per soddisfare le tue esigenze specifiche e migliorare la tua presenza online.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-hosting-service">
                    <i class="flaticon-database-1"></i>
                    <h3>Gestione Reti e Sistemi IT</h3>
                    <p>Gestiamo e ottimizziamo le reti e i sistemi IT della tua azienda, garantendo una connettività affidabile e una sicurezza informatica robusta.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-hosting-service">
                    <i class="flaticon-network"></i>
                    <h3>Gestione a 360°</h3>
                    <p>Dalla progettazione alla realizzazione e alla manutenzione, ci prendiamo cura di ogni fase per assicurare il miglior supporto possibile alla tua azienda, inclusa un'assistenza tecnica completa su hardware e software.</p>
                </div>
            </div>
        </div>
    </div>
</section>