<div class="preloader">
    <div class="loader">
        <div class="sbl-puzzle">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</div>