<section class="main-banner-area main-banner-area-three">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="banner-text">
                            <!-- <h1>Un potente piano di hosting per il vostro sito web da sogno</h1>
                             <p>Fino al 25% di sconto sul tuo nuovo hosting, a partire da €7,49/mese. 
                                Certificato SSL gratuito incluso!
                            </p>
                            <p>Codice Sconto: <strong>I2KML8T5UR</strong></p> -->
                            <!-- <app-search-domain></app-search-domain> 
                            <p>Certificato SSL gratuito incluso!<br>(Let's Encrypt)</p> -->
                            <h1>
                                Benvenuti su WeeBo - Il tuo partner per servizi
                                IT
                            </h1>
                            <p>
                                Hosting, VPS, sviluppo web e assistenza IT per
                                aziende.
                            </p>
                            <p style="margin-top: -2.5em">
                                Offriamo velocità e semplicità al tuo servizio!
                            </p>
                            <p style="margin-top: -2.5em">
                                Assistenza hardware e software a 360 gradi.
                            </p>

                            <!-- <form action="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register" method="post">
                                Find your Domain: <input type="text" name="query" size="20" />
                                <input type="submit" value="Go" />
                            </form> -->

                            <app-domain-search></app-domain-search>

                            <!-- spostato nel componente sopra
                            <div class="search-domain">
                                <form
                                    class="domain-wrap"
                                    action="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                                    method="post"
                                >
                                    <input
                                        type="text"
                                        class="input-domain"
                                        placeholder="Trova il tuo nuovo dominio"
                                        name="query"
                                        size="20"
                                    />

                                    <button
                                        class="default-btn"
                                        type="submit"
                                        value="submit"
                                    >
                                        Cerca
                                    </button>
                                    <select class="select-domain-name">
                                        <option>.com</option>
                                        <option>.net</option>
                                        <option>.info</option>
                                        <option>.store</option>
                                        <option>.biz</option>
                                        <option>.xyz</option>
                                        <option>.rog</option>
                                    </select>
                                </form>
                            </div> -->

                            <!-- <div class="search-domain">
                                <form
                                    class="domain-wrap"
                                    (ngSubmit)="postData()"
                                    action="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                                    method="post"
                                >
                                    <input
                                        type="text"
                                        class="input-domain"
                                        placeholder="Trova il tuo nuovo dominio"
                                        [(ngModel)]="domainName"
                                        name="query"
                                        size="20"
                                    />
                                    <button class="default-btn" type="submit">
                                        Cerca
                                    </button>
                                    <select class="select-domain-name" [(ngModel)]="domainExtension">
                                        <option>.com</option>
                                        <option>.net</option>
                                        <option>.info</option>
                                        <option>.store</option>
                                        <option>.biz</option>
                                        <option>.xyz</option>
                                    </select>
                                </form>
                            </div> -->
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="banner-img">
                            <img
                                class="banner-main-img"
                                src="assets/img/banner-three/banner-main-img.png"
                                alt="Image"
                            />
                            <div class="banner-main-img-shape">
                                <img
                                    src="assets/img/banner-three/banner-main-img-shape.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="white-shape">
        <img src="assets/img/banner-three/white-shape-bottom.png" alt="Image" />
    </div>

    <div class="shape-line-one">
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image" />
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image" />
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image" />
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image" />
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image" />
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image" />
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image" />
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image" />
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image" />
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image" />
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image" />
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image" />
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image" />
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image" />
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image" />
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image" />
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image" />
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image" />
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image" />
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image" />
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image" />
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image" />
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image" />
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image" />
        <img src="assets/img/banner-shape/banner-shape-one.png" alt="Image" />
        <img src="assets/img/banner-shape/banner-shape-two.png" alt="Image" />
        <img src="assets/img/banner-shape/banner-shape-three.png" alt="Image" />
        <img src="assets/img/banner-shape/banner-shape-two.png" alt="Image" />
        <img src="assets/img/banner-shape/banner-shape-three.png" alt="Image" />
    </div>
</section>
