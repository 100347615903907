<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>News Left Sidebar</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>News</li>
                        <li>News Left Sidebar</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area left-widget-area" id="secondary">
                    <div class="widget widget_search mt-0">
                        <h3 class="widget-title">Search Now</h3>

                        <div class="post-wrap">
                            <form class="search-form">
                                <label>
                                    <span class="screen-reader-text">Search for:</span>
                                    <input type="search" class="search-field" placeholder="Search...">
                                </label>
                                <button type="submit"><i class='bx bx-search'></i></button>
                            </form>
                        </div>
                    </div>

                    <section class="widget widget-hosta-posts-thumb">
                        <h3 class="widget-title">Popular Posts</h3>

                        <div class="post-wrap">
                            <article class="item">
                                <a routerLink="/news-details" class="thumb">
                                    <span class="fullimage cover bg1" role="img"></span>
                                </a>
                                <div class="info">
                                    <time datetime="2020-06-30">February 20, 2020</time>
                                    <h4 class="title usmall">
                                        <a routerLink="/news-details">
                                            Appropriately productize fully
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a routerLink="/news-details" class="thumb">
                                    <span class="fullimage cover bg2" role="img"></span>
                                </a>
                                <div class="info">
                                    <time datetime="2020-06-30">February 21, 2020</time>
                                    <h4 class="title usmall">
                                        <a routerLink="/news-details">
                                            Appropriately re-engineer high
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a routerLink="/news-details" class="thumb">
                                    <span class="fullimage cover bg3" role="img"></span>
                                </a>
                                <div class="info">
                                    <time datetime="2020-06-30">February  22, 2020</time>
                                    <h4 class="title usmall">
                                        <a routerLink="/news-details">
                                            Progressively visualize enabled
                                        </a> 
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                            
                            <article class="item">
                                <a routerLink="/news-details" class="thumb">
                                    <span class="fullimage cover bg4" role="img"></span>
                                </a>
                                <div class="info">
                                    <time datetime="2020-06-30">February 23, 2020</time>
                                    <h4 class="title usmall">
                                        <a routerLink="/news-details">
                                            Credibly implement maximizing
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                        </div>
                    </section>

                    <section class="widget widget_categories">
                        <h3 class="widget-title">Archives</h3>

                        <div class="post-wrap">
                            <ul>
                                <li><a routerLink="/">January <span>2020</span></a></li>
                                <li><a routerLink="/">February <span>2020</span></a></li>
                                <li><a routerLink="/">March <span>2020</span></a></li>
                                <li><a routerLink="/">April <span>2020</span></a></li>
                                <li><a routerLink="/">May <span>2020</span></a></li>
                                <li><a routerLink="/">June <span>2020</span></a></li>
                            </ul>
                        </div>
                    </section>
                    
                    <section class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>

                        <div class="post-wrap">
                            <ul>
                                <li><a routerLink="/">Cloud Computing <span>(10)</span></a></li>
                                <li><a routerLink="/">Cloud Hosting <span>(20)</span></a></li>
                                <li><a routerLink="/">Reseller Hosting <span>(10)</span></a></li>
                                <li><a routerLink="/">Shared Hosting <span>(12)</span></a></li>
                                <li><a routerLink="/">Uncategorized <span>(16)</span></a></li>
                                <li><a routerLink="/">Domain Search <span>(17)</span></a></li>
                            </ul>
                        </div>
                    </section>

                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Tags</h3>

                        <div class="post-wrap">
                            <div class="tagcloud">
                                <a routerLink="/">Computing (3)</a>
                                <a routerLink="/">Hosting (3)</a>
                                <a routerLink="/">Shared (2)</a>
                                <a routerLink="/">Uncategorized (2)</a>
                                <a routerLink="/">Domain (1)</a>
                                <a routerLink="/">Reseller (1)</a>
                                <a routerLink="/">Cloud (1)</a>
                            </div>
                        </div>
                    </section>
                </aside>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <a routerLink="/news-details" class="d-block text-center"><img src="assets/img/blog/blog-img-one.png" alt="Image"></a>
        
                            <div class="blog-content-wrap">
                                <ul>
                                    <li><a routerLink="/"><i class="flaticon-male"></i> Admin</a></li>
                                    <li class="date"><i class="flaticon-appointment"></i> 20 Feb 2020</li>
                                </ul>
                                <h3><a routerLink="/news-details">Web domain hosting the best service work</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolorem.</p>
        
                                <a class="read-more" routerLink="/news-details">Read More<i class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
        
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <a routerLink="/news-details" class="d-block text-center"><img src="assets/img/blog/blog-img-two.png" alt="Image"></a>
        
                            <div class="blog-content-wrap">
                                <ul>
                                    <li><a routerLink="/"><i class="flaticon-male"></i> Admin</a></li>
                                    <li class="date"><i class="flaticon-appointment"></i> 21 Feb 2020</li>
                                </ul>
                                <h3><a routerLink="/news-details">Web development hosting the best service work</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolorem.</p>
        
                                <a class="read-more" routerLink="/news-details">Read More<i class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
        
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <a routerLink="/news-details" class="d-block text-center"><img src="assets/img/blog/blog-img-three.png" alt="Image"></a>
        
                            <div class="blog-content-wrap">
                                <ul>
                                    <li><a routerLink="/"><i class="flaticon-male"></i> Admin</a></li>
                                    <li class="date"><i class="flaticon-appointment"></i> 22 Feb 2020</li>
                                </ul>
                                <h3><a routerLink="/news-details">Web hosting the best service work future</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolorem.</p>
        
                                <a class="read-more" routerLink="/news-details">Read More<i class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
        
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <a routerLink="/news-details" class="d-block text-center"><img src="assets/img/blog/blog-img-four.png" alt="Image"></a>
        
                            <div class="blog-content-wrap">
                                <ul>
                                    <li><a routerLink="/"><i class="flaticon-male"></i> Admin</a></li>
                                    <li class="date"><i class="flaticon-appointment"></i> 23 Feb 2020</li>
                                </ul>
                                <h3><a routerLink="/news-details">Kindness and motivation tips challenge difference</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolorem.</p>
        
                                <a class="read-more" routerLink="/news-details">Read More<i class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
        
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <a routerLink="/news-details" class="d-block text-center"><img src="assets/img/blog/blog-img-five.png" alt="Image"></a>
        
                            <div class="blog-content-wrap">
                                <ul>
                                    <li><a routerLink="/"><i class="flaticon-male"></i> Admin</a></li>
                                    <li class="date"><i class="flaticon-appointment"></i> 24 Feb 2020</li>
                                </ul>
                                <h3><a routerLink="/news-details">Web hosting server very first the best works</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolorem.</p>
        
                                <a class="read-more" routerLink="/news-details">Read More<i class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
        
                    <div class="col-lg-6 col-md-6">
                        <div class="single-blog-post">
                            <a routerLink="/news-details" class="d-block text-center"><img src="assets/img/blog/blog-img-six.png" alt="Image"></a>
        
                            <div class="blog-content-wrap">
                                <ul>
                                    <li><a routerLink="/"><i class="flaticon-male"></i> Admin</a></li>
                                    <li class="date"><i class="flaticon-appointment"></i> 25 Feb 2020</li>
                                </ul>
                                <h3><a routerLink="/news-details">Generally lorem ipsum is best suited to from server</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolorem.</p>
        
                                <a class="read-more" routerLink="/news-details">Read More<i class="bx bx-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
        
                    <div class="col-lg-12">
                        <div class="page-navigation-area">
                            <nav>
                                <ul class="pagination">
                                    <li class="page-item"><a class="page-link page-links" routerLink="/news-grid"><i class='bx bx-chevrons-left'></i></a></li>
        
                                    <li class="page-item active"><a class="page-link" routerLink="/news-grid">1</a></li>
        
                                    <li class="page-item"><a class="page-link" routerLink="/news-grid">2</a></li>
        
                                    <li class="page-item"><a class="page-link" routerLink="/news-grid">3</a></li>
        
                                    <li class="page-item"><a class="page-link" routerLink="/news-grid"><i class='bx bx-chevrons-right'></i></a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>