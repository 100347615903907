<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Nuovo regolamento GDPR</h2>
                    <br />
                    <h4 style="color: white">
                        Il regolamento europeo per la protezione dei dati
                        personali
                    </h4>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/legal">Legal</a></li>
                        <li>GDPR</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="terms-conditions ptb-100">
    <div class="privacy-assurance">
        <p>
            Ti assicuriamo che abbiamo trattato e tratteremo sempre i tuoi dati
            con la massima cautela.
        </p>
    </div>
    <div class="container">
        <div class="single-privacy">
            <h1 style="text-align: center">Il nuovo regolamento GDPR</h1>
            <div class="regole" style="overflow: auto">
                <div style="float: left; width: 33.33%">
                    <img
                        src="./../../../../assets/img/gdpr/gdpr_icon.png"
                        alt="gdpr_icon.png"
                    />
                    <h3>GDPR: di cosa si tratta</h3>
                    <p>
                        Si tratta dell'acronimo inglese di General Data
                        Protection Regulation (GDPR), che in italiano possiamo
                        tradurre come Regolamento generale sulla protezione dei
                        dati. In termini ancora più tecnici si tratta del
                        Regolamento UE 2016/279. Lo scopo del GDPR è quello di
                        regolamentare la raccolta e il trattamento dei dati
                        personali, prevedendo controlli ed obblighi verso chi
                        raccoglie e tratta tali informazioni personali.
                    </p>
                </div>
                <div style="float: left; width: 33.33%">
                    <img
                        src="./../../../../assets/img/gdpr/gdpr_icons_data.png"
                        alt="gdpr_icons_data.png"
                    />
                    <h3>Quali sono i dati oggetto di interesse per il GDPR</h3>
                    <p>
                        Qualunque informazione relativa a un individuo,
                        collegata alla sua vita sia privata, sia professionale o
                        pubblica. A titolo esemplificativo possiamo quindi
                        citare: Nome e cognome, indirizzo postale o e-mail, data
                        di nascita, codice fiscale, ecc. Oppure informazioni
                        ancor più “personali” come quelle relative alla salute.
                        Citando il regolamento stesso, è dato personale
                        qualsiasi informazione riguardante una persona fisica
                        identificata o identificabile tramite un dato
                        caratteristico “della sua identità fisica, fisiologica,
                        genetica, psichica, economica, culturale o sociale”.
                    </p>
                </div>
                <div style="float: left; width: 33.33%">
                    <img
                        src="./../../../../assets/img/gdpr/gdpr_trattamento.png"
                        alt="gdpr_trattamento.png"
                    />
                    <h3>Cosa vuol dire "trattare i dati personali"</h3>
                    <p>
                        Trattare dati personali non significa obbligatoriamente
                        sfruttarli o utilizzarli. È molto probabile che i dati
                        personali siano raccolti senza essere utilizzati, ma ciò
                        non preclude il rispetto di quanto richiesto dal GDPR.
                        Anche un semplice “form” di raccolta informazioni come
                        e-mail o numero di telefono per poter ricontattare un
                        potenziale cliente è da considerarsi trattamento di dati
                        personali. Sono quindi da considerarsi trattamento
                        anche, a titolo esemplificativo: la conservazione,
                        l'organizzazione, la modifica, l'estrazione, la
                        consultazione, la cancellazione, ecc.
                    </p>
                </div>
            </div>
            <br />
            <br />
            <h1 style="text-align: center">
                Quali sono i principali ruoli definiti dal GDPR?
            </h1>
            <h4 style="text-align: center">
                Sono due le figure più importanti all'interno del GDPR , il
                “Titolare del Trattamento” e il “Responsabile del Trattamento”
            </h4>
            <br />
            <div class="regole" style="overflow: auto">
                <div style="float: left; width: 50%">
                    <img
                        src="./../../../../assets/img/gdpr/gdpr_titolare.png"
                        alt="gdpr_titolare.png"
                    />
                    <h3>Titolare del trattamento</h3>
                    <p>
                        Titolare del trattamento (Data Controller): È colui che
                        decide le finalità e le modalità del trattamento dei
                        dati personali, risultandone perciò il primo
                        responsabile con riguardo all'ottemperanza degli
                        obblighi previsti dalla normativa nazionale ed
                        internazionale. Fra i tanti obblighi del Titolare del
                        Trattamento va ricordato quello di mettere in pratica
                        tutte le misure adeguate a garantire la tutela dei dati
                        raccolti. Per fare un esempio, i clienti di WeeBo
                        per i servizi di Hosting, Server Dedicato e Server
                        Virtuale sono di norma titolari del trattamento visto il
                        loro ruolo nella gestione dei dati contenuti nei server
                        e nei loro siti. Il titolare del trattamento può
                        nominare, con contratto o atto giuridicamente valido,
                        uno o più Responsabili del Trattamento.
                    </p>
                </div>
                <div style="float: left; width: 50%">
                    <img
                        src="./../../../../assets/img/gdpr/gdpr_responsabile.png"
                        alt="gdpr_responsabile.png"
                    />
                    <h3>Responsabile del trattamento</h3>
                    <p>
                        Responsabile del trattamento (Data Processor): È colui
                        che tratta i dati per conto del Titolare del Trattamento
                        e che deve fornirgli necessarie garanzie, per aiutarlo
                        nel rispetto delle disposizioni in materia di
                        trattamento dei dati personali. Ad esempio, con riguardo
                        ai servizi di Hosting, Server Dedicato e Server
                        Virtuale, il cliente si serve di WeeBo come
                        responsabile del trattamento, in quanto WeeBo è un
                        fornitore di tecnologia. Il Responsabile del Trattamento
                        ha l'obbligo di rispettare le istruzioni ricevute dal
                        Titolare, di collaborare con quest'ultimo al fine di
                        consentirgli di adempiere agli obblighi normativi, ed ha
                        in generale a sua volta l'obbligo di rispettare la
                        normativa e di garantire, per quanto di sua esclusiva
                        competenza, la sicurezza dei dati personali che gli
                        vengono affidati.
                    </p>
                </div>
            </div>
            <br />
            <br />
            <h1 style="text-align: center">Come tuteliamo i nostri clienti</h1>
            <div class="regole" style="overflow: auto">
                <div style="float: left; width: 25%">
                    <img
                        src="./../../../../assets/img/gdpr/gdpr_info.png"
                        alt="gdpr_info.png"
                    />
                    <h3>Il tuo diritto ad essere informato</h3>
                    <p>
                        Abbiamo aggiornato la nostra Privacy Policy, che adesso
                        contiene maggiori informazioni su come processiamo i
                        tuoi dati personali, come li conserviamo e come possiamo
                        trattarli nell'erogazione dei servizi, con indicazioni
                        specifiche sulla durata di conservazione dei dati e sui
                        trasferimenti di dati fuori dall'UE che avvengono
                        nell'ambito dell'erogazione del servizio.
                    </p>
                </div>
                <div style="float: left; width: 25%">
                    <img
                        src="./../../../../assets/img/gdpr/gdpr_accesso_dati.png"
                        alt="gdpr_accesso_dati.png"
                    />
                    <h3>
                        Il tuo diritto di accesso e di correzione dei tuoi dati
                    </h3>
                    <p>
                        Sarai sempre in grado di accedere direttamente al tuo
                        pannello di controllo online e di accedere alla sezione
                        “Gestione account, fatture e pagamenti”, in cui puoi
                        aggiornare in qualsiasi momento i tuoi dati personali.
                        Puoi anche metterti in contatto con noi per apportare
                        modifiche ai tuoi dati personali, aggiornarli o
                        correggere eventuali inesattezze.
                    </p>
                </div>
                <div style="float: left; width: 25%">
                    <img
                        src="./../../../../assets/img/gdpr/gdpr_sicurezza_primo.png"
                        alt="gdpr_sicurezza_primo.png"
                    />
                    <h3>La tua sicurezza al primo posto</h3>
                    <p>
                        I tuoi dati personali sono conservati nei nostri
                        datacenter, dove disponiamo di severi controlli di
                        accesso agli edifici.
                        L'accesso fisico al server è strettamente limitato al
                        personale autorizzato in loco e l'accesso virtuale è
                        controllato tramite vari sistemi di autenticazione,
                        incluse le chiavi crittografiche.
                    </p>
                </div>
                <div style="float: left; width: 25%">
                    <img
                        src="./../../../../assets/img/gdpr/gdpr_portabilita.png"
                        alt="gdpr_portabilita.png"
                    />
                    <h3>I tuoi dati, sempre con te</h3>
                    <p>
                        WeeBo è il luogo più sicuro dove tenere i tuoi
                        dati. WeeBo rispetta e tutela
                        però la tua facoltà di cambiare gestore, e di portare
                        con te i tuoi dati personali, una delle novità più
                        importanti del nuovo regolamento europeo.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
