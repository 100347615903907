<footer class="footer-top-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo">
                        <img src="assets/img/white-logo.png" alt="Image" />
                    </a>
                    <p>WeeBo, il tuo partner IT di fiducia in Italia</p>
                    <!-- TrustBox widget - Review Collector -->
                    <div
                        class="trustpilot-widget mt-3"
                        data-locale="it-IT"
                        data-template-id="56278e9abfbbba0bdcd568bc"
                        data-businessunit-id="653a7a9cd1d99aacc857f361"
                        data-style-height="52px"
                        data-style-width="100%"
                    >
                        <a
                            href="https://it.trustpilot.com/review/weebo.shop"
                            target="_blank"
                            rel="noopener"
                            >Trustpilot</a
                        >
                    </div>
                    <!-- End TrustBox widget -->
                    <ul class="social-icon" style="margin-top: 15px">
                        <!-- <li>
                            <a href="#" target="_blank"
                                ><i class="bx bxl-facebook"></i
                            ></a>
                        </li> -->
                        
                        <li>
                            <a
                                href="https://www.instagram.com/weebo.cloud/"
                                target="_blank"
                                ><i class="bx bxl-instagram"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                href="https://www.linkedin.com/company/weebocloud/"
                                target="_blank"
                                ><i class="bx bxl-linkedin"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                href="https://links.weebo.cloud/@weebo"
                                target="_blank"
                                ><i class="bx bx-link"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget pl-5">
                    <h3>Hosting</h3>

                    <ul>
                        <li>
                            <a routerLink="/shared-hosting"
                                >Hosting Condiviso</a
                            >
                        </li>
                        <li>
                            <a routerLink="/shared-hosting"
                                >Hosting WordPress</a
                            >
                        </li>
                        <!-- <li>
                            <a routerLink="/coming-soon">Hosting Dedicato</a>
                        </li> -->
                        <!-- <li><a routerLink="/">Reseller Hosting</a></li>
                        <li><a routerLink="/">VPS Hosting</a></li> -->
                        <!-- <li><a routerLink="/">Dedicated Server</a></li> -->
                    </ul>
                </div>
            </div>

            <!-- <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Domains</h3>
                    
                    <ul>
                        <li><a routerLink="/">Domain Name Search</a></li>
                        <li><a routerLink="/">Transfer</a></li>
                        <li><a routerLink="/">Personal Domain</a></li>
                        <li><a routerLink="/">Free DNS</a></li>
                        <li><a routerLink="/">Premium DNS</a></li>
                    </ul>
                </div>
            </div> -->

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Supporto</h3>

                    <ul>
                        <li><a routerLink="/about">Chi siamo</a></li>
                        <li><a routerLink="/services">Informazioni</a></li>
                        <li><a routerLink="/contact">Contatti</a></li>
                        <li>
                            <a routerLink="/faq">Domande Frequenti (FAQ)</a>
                        </li>

                        <li>
                            <a
                                href="https://tutorial.weebostore.it"
                                target="_blank"
                                >Tutorial</a
                            >
                        </li>
                        <!-- <li><a routerLink="/">Premium DNS</a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Account</h3>
                    <ul>
                        <li>
                            <a href="https://weebo.cloud/area-clienti/index.php"
                                >Login</a
                            >
                        </li>
                        <li>
                            <a
                                href="https://weebo.cloud/area-clienti/register.php"
                                >Registrati</a
                            >
                        </li>
                    </ul>
                </div>

                <!-- <a
                    href="https://statuspage.freshping.io/64117-WeeBo"
                    target="_blank"
                >
                    <img
                        src="https://statuspage.freshping.io/badge/faf5e56a-ed61-476f-960c-497573d3768a?0.33606569229672223"
                    />
                </a> -->
                <h3 style="color: white">Stato dei Servizi</h3>
                <a
                    href="https://uptime.weebo.cloud/status/weebo-cloud"
                    target="_blank"
                    >Pagina di Stato</a
                >
                <div class="badge">
                    <img
                        src="https://uptime.weebo.cloud/api/badge/1/status?style=for-the-badge"
                        alt="Status Badge"
                        style="margin-bottom: 10px"
                    />

                    <img
                        src="https://uptime.weebo.cloud/api/badge/1/uptime?style=for-the-badge"
                        alt="Uptime Badge"
                    />
                </div>
            </div>
        </div>
    </div>
</footer>

<footer class="footer-bottom-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-3">
                <p>® WeeBo | P.IVA:02753650221</p>
            </div>

            <div class="col-lg-7 col-md-7">
                <ul>
                    <li>
                        <a routerLink="/legal/terms-conditions"
                            >Condizioni Generali</a
                        >
                    </li>
                    <li>
                        <a routerLink="/legal/privacy-policy"
                            >Informativa Privacy</a
                        >
                    </li>
                    <li>
                        <a routerLink="/legal/cookie">Cookie Policy</a>
                    </li>
                </ul>
            </div>

            <div class="col-lg-2 col-md-2">
                <div class="designed">
                    <p>
                        Designed By <i class="bx bx-heart bx-burst"></i>
                        <a href="https://www.weebo.cloud/"
                            >WeeBo</a
                        >
                    </p>
                </div>
            </div>
        </div>
    </div>
</footer>

<footer class="footer-bottom-area">
    <div class="container">
        <div class="row align-items-center">
            <p style="font-size: 12px">
                "WeeBo" è un marchio registrato. I contenuti del presente Sito -
                comprensivi di immagini, grafici, loghi e marchi - sono di
                proprietà di WeeBo, se non diversamente indicato, coperti da
                copyright. WeeBo si riserva di intraprendere ogni e più
                opportuna azione nei confronti di eventuali iniziative poste in
                essere da soggetti terzi e volte a utilizzare in ogni forma e
                modo non consentito segni distintivi identici o simili (in
                quanto confondibili) ai suddetti marchi e loghi. E' vietata la
                riproduzione del marchio e dei contenuti, immagini e qualsiasi
                altra forma di comunicazione soggetta a copyright. Consultare la
                sezione Termini e Condizioni per maggiori dettagli.
            </p>
        </div>
    </div>
</footer>

<div class="go-top">
    <i class="bx bx-chevrons-up bx-fade-up"></i>
    <i class="bx bx-chevrons-up bx-fade-up"></i>
</div>
