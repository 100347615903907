<section class="technology-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>La tecnologia più recente per il tuo hosting</h2>
            <p>Il tuo nuovo Hosting è composto da tutti gli strumenti necessari, raccolti in un unico pannello di controllo. Facile e intuitivo, adatto a qualsiasi tipo di utilizzo!</p>
        </div>
        
        <div class="technology-wrap owl-carousel owl-theme">

            <div class="single-technology">
                <div class="technology-icon-bg">
                    <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                    <i class="flaticon-settings"></i>
                </div>

                <h3>Facile da Configurare</h3>
                <p>Con pochi click avrai al tua pagina web online, con report mensili sullo stato dei tuoi server.</p>
                <a class="read-more" routerLink="/shared-hosting">Per saperne di più <i class="bx bx-chevron-right"></i></a>
            </div>

            <div class="single-technology">
                <div class="technology-icon-bg">
                    <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                    <i class="flaticon-password"></i>
                </div>
    
                <h3>Pannello di Controllo</h3>
                <p>Il pannello di controllo utilizzato da WeeBo semplifica la gestione e la configurazione. Zero pensieri.</p>
                <a class="read-more" routerLink="/shared-hosting">Per saperne di più <i class="bx bx-chevron-right"></i></a>
            </div>

            <div class="single-technology">
                <div class="technology-icon-bg">
                    <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                    <i class="flaticon-password"></i>
                </div>

                <h3>Protezione della Privacy</h3>
                <p>I tuoi dati con noi sono al sicuro! Tutto ciò che carichi è protetto da Crittografia all'interno dei nostri server.</p>
                <a class="read-more" routerLink="/shared-hosting">Per saperne di più <i class="bx bx-chevron-right"></i></a>
            </div>

            <div class="single-technology">
                <div class="technology-icon-bg">
                    <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                    <i class="flaticon-backup"></i>
                </div>

                <h3>Backup Giornaliero</h3>
                <p>Ogni giorno, in automatico, verrà effettuato un Backup completo del tuo hosting. Dì addio alla perdita dei dati.</p>
                <a class="read-more" routerLink="/shared-hosting">Per saperne di più <i class="bx bx-chevron-right"></i></a>
            </div>

            <div class="single-technology">
                <div class="technology-icon-bg">
                    <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                    <i class="flaticon-speedometer-1"></i>
                </div>

                <h3>Prestazioni Veloci</h3>
                <p>Basta rallantementi e attese interminabili. Ora il tuo Hosting è vicino a te, riduci i tempi di caricamento delle pagine.</p>
                <a class="read-more" routerLink="/shared-hosting">Per saperne di più <i class="bx bx-chevron-right"></i></a>
            </div>

        </div>
    </div>
</section>