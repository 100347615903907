import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-registrazione-domini',
  templateUrl: './registrazione-domini.component.html',
  styleUrls: ['./registrazione-domini.component.scss']
})
export class RegistrazioneDominiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
