<div class="search-domain">
    <form class="domain-wrap">
        <input type="text" class="input-domain" placeholder="Trova qui il tuo nuovo dominio" name="Domain">
        <button class="default-btn" type="submit" value="submit">Cerca</button>

        <select class="select-domain-name">
            <option>.com</option>
            <option>.it</option>
            <!-- <option>.info</option>
            <option>.store</option>
            <option>.biz</option>
            <option>.xyz</option>
            <option>.rog</option> -->
        </select>
    </form>
</div>