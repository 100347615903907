<section class="try-now-free pt-70 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <h3>Soluzioni di hosting complete a partire da €8,99 /mese*</h3>
                <hr />
                <h6 style="font-size: small;">*riferito al piano annuale</h6>
            </div>

            <div class="col-lg-6 col-md-12 text-end">
                <a class="default-btn" href="https://weebo.cloud/area-clienti/store/hosting-condiviso/weebo-start-up">Prova ora!</a>
            </div>
        </div>
    </div>
</section>