<section class="customer-support-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="customer-title">
                    <h2>Assistenza clienti online 24/7</h2>
                    <p>Dal proprio account sarà possibile richiedere assistenza a uno dei nostri tecnici in qualsiasi momento aprendo un ticket oppure consultando l'archivio delle domande dove potrai trovare risposta alle domande più comuni.</p>
                </div>

                <ul class="support-list">
                    <li>Dopo aver effettuato il Login al proprio account, in alto, fare clic su Apri Ticket</li>
                    <li>Selezionare il piano per il quale si desidera aprire una richiesta</li>
                    <li>Compilare il form con tutti i dettagli</li>
                    <li>Premere Invia</li>
                </ul>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="support-img">
                    <img src="assets/img/support-img.png" alt="Image">
                </div>
            </div>
        </div>
    </div>
</section>