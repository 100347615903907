import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
    selector: "app-section1",
    templateUrl: "./section1.component.html",
    styleUrls: ["./section1.component.scss"],
})

export class Section1Component implements OnInit {
    domainName: string = "";
    domainExtension: string = ".com";

    //constructor(private http: HttpClient) {} //MI INCASTRA LA PAGINA DIOCAN

    ngOnInit(): void {}

    postData() {
      const url = 'https://weebo.cloud/area-clienti/cart.php?a=add&domain=register';
      const body = new FormData();
      body.append('query', this.domainName + this.domainExtension);
      // console log formData
      body.forEach((value, key) => {
        console.log(key, value);
      });
      // setup headers
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        // Aggiungi altre intestazioni se necessario
      });
      //return this.http.post(url, body, { headers: headers });
    }
}
