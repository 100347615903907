<!-- <app-home-banner></app-home-banner> -->

<app-section1></app-section1>

<app-section6-shared-hosting></app-section6-shared-hosting>

<app-region-marketing></app-region-marketing>

<app-section13-sol-complete></app-section13-sol-complete>

<app-section2></app-section2>

<!-- <app-section3-assistenza></app-section3-assistenza> -->

<app-section4-host-sol></app-section4-host-sol>

<app-section5-compromessi></app-section5-compromessi>

<!-- <app-section7-team></app-section7-team> -->

<!-- <app-section8-map></app-section8-map> -->

<app-section9-ask></app-section9-ask>

<!-- <app-section11-news></app-section11-news> -->

<!-- <app-section12-contact-form></app-section12-contact-form> -->

<app-section10-recensioni></app-section10-recensioni>
