import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section7-team',
  templateUrl: './section7-team.component.html',
  styleUrls: ['./section7-team.component.scss']
})
export class Section3TeamComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
