<div class="brand-area pt-100 pb-70">
    <div class="container">
        <h1 style="text-align: center;">Chi ci ha scelto</h1>
        <div class="row">
            <div class="brand-wrap owl-carousel owl-theme">
                <div class="single-brand">
                    <a href="https://elgatraimpianti.com" target="_blank"><img src="assets/img/brand-logo/elgatraimpainti_logo.png" alt="Elgatraimpainti_logo"></a>
                </div>
                
                <div class="single-brand">
                    <a href="https://lvdere.com" target="_blank"><img src="assets/img/brand-logo/lvdere_logo.png" alt="Lvdere_logo"></a>
                </div>
                
                <div class="single-brand">
                    <img src="assets/img/brand-logo/brand-logo-three.png" alt="Image">
                </div>

                <div class="single-brand">
                    <img src="assets/img/brand-logo/brand-logo-four.png" alt="Image">
                </div>

                <div class="single-brand">
                    <img src="assets/img/brand-logo/brand-logo-five.png" alt="Image">
                </div>
            </div>
        </div>
    </div>
</div>