<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Hosting Condiviso</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Hosting</li>
                        <li>Hosting Condiviso</li>
                    </ul>
                </div>
            </div>

            <div class="special-intro-offer-wrap">
                <div class="row align-items-center">
                    <div class="col-lg-8 col-md-6">
                        <div class="special-intro-offer-list">
                            <h3 style="color:#ffffff">Wee Starter</h3>
                            <ul>
                                <li><i class="flaticon-tick"></i> 1 Sito Web</li>
                                <li><i class="flaticon-tick"></i> Bandwidth illimitata</li>
                                <li><i class="flaticon-tick"></i> Installazione istantanea di CMS come WordPress con un solo clic</li>
                                <li><i class="flaticon-tick"></i> Spazio di archiviazione super veloce</li>
                                <li><i class="flaticon-tick"></i> Pannello di controllo intuitivo e facile da utilizzare</li>
                                <li><i class="flaticon-tick"></i> Sottodomini illimitati</li>
                                <li><i class="flaticon-tick"></i> Certificato SSL Gratuito incluso fornito da Let's Encrypt</li>
                                <li><i class="flaticon-tick"></i> Email di dominio incluse e gratuite (Utilizzando lo spazio a disposizione del proprio piano di Hosting)</li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="special-intro-offer">
                            <h3>Promo Nuovi Clienti</h3>
                            <span>€8,99 <sub>Mese</sub><br /><sub>Prezzo riferito all'abbonamento annuale</sub></span>
                            <span></span>
                            <!-- <p>Prezzo normale <strong>€9,99/Mese</strong></p>
                            <p>Codice sconto: <strong>I2KML8T5UR</strong></p>
                            <p>(Lo sconto verrà applicato inserendo il codice in fase di acquisto)</p> -->
                            <a class="default-btn" href="https://weebo.cloud/area-clienti/store/hosting-condiviso/weebo-start-up">Inizia</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-section6-shared-hosting></app-section6-shared-hosting>

<app-technology></app-technology>

<!-- <app-section5-compromessi></app-section5-compromessi> -->

<app-section9-ask></app-section9-ask>

<app-section10-recensioni></app-section10-recensioni>

<app-contact-form-white></app-contact-form-white>