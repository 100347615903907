<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Informazioni</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Home </a></li>
                        <li>Supporto</li>
                        <li>Informazioni</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="pt-100">
    <app-weebo-services></app-weebo-services>
</div>

<app-technology></app-technology>

<!-- <app-section6-shared-hosting></app-section6-shared-hosting>
 -->
<!-- <app-power-hosting></app-power-hosting> -->

<!-- <app-section2></app-section2> -->

<app-contact-form-white></app-contact-form-white>