<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Contatti</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLinlk="/">Home</a></li>
                        <li>Supporto</li>
                        <li>Contatti</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-sm-6 col-md-6">
                        <div class="single-contact-info">
                            <i class="bx bx-envelope"></i>
                            <h3>Email:</h3>
                            <!-- <a href="mailto:stefano@stefanotomasi.com">stefano@stefanotomasi.com</a> -->
                            <a href="mailto:supporto@weebo.cloud">supporto@weebo.cloud</a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6 col-md-6">
                        <div class="single-contact-info">
                            <i class="bx bx-phone-call"></i>
                            <h3>Telefono</h3>
                            <a href="tel:+(39)3517994419">+ (39) 351 7994 419</a>
                            <!-- <a href="tel:+(124)1523-567-9874">Tel. + (124) 1523-567-9874</a> -->
                        </div>
                    </div>

                    <!-- <div class="col-lg-6 col-sm-6 col-md-6">
                        <div class="single-contact-info">
                            <i class="bx bx-location-plus"></i>
                            <h3>London</h3>
                            <a href="#">82 Bernie Greens Apt. 210, Henson Way, London, UK</a>
                        </div>
                    </div> -->

                    <!-- <div class="col-lg-6 col-sm-6 col-md-6">
                        <div class="single-contact-info">
                            <i class="bx bx-support"></i>
                            <h3>Live Chat</h3>
                            <a href="#">live chat all the time with our company 24/7</a>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <!-- <div id="map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d158857.72810813924!2d-0.24168018863117885!3d51.528771840455114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a00baf21de75%3A0x52963a5addd52a99!2sLondon%2C%20UK!5e0!3m2!1sen!2sbd!4v1589107276023!5m2!1sen!2sbd"></iframe>
                </div> -->
                <app-contact-form-white></app-contact-form-white>
            </div>
        </div>
    </div>
</section>