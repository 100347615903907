<section class="our-team-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Incontra il nostro Team</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <img src="assets/img/team/team-bg.png" alt="Image">

                    <div class="team-man">
                        <img src="assets/img/team/team-img-one.png" alt="Image">
                    </div>

                    <h3>Tizio Caio</h3>
                    <span>UI/UX Designer</span>

                    <ul class="social-icon">
                        <li><a href="#"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#"><i class="bx bxl-instagram"></i></a></li>
                        <li><a href="#"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="#"><i class="bx bxl-youtube"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <img src="assets/img/team/team-bg.png" alt="Image">

                    <div class="team-man">
                        <img src="assets/img/team/team-img-two.png" alt="Image">
                    </div>

                    <h3>Stefano Tomasi</h3>
                    <span>WeeBo</span>

                    <ul class="social-icon">
                        <li><a href="#"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#"><i class="bx bxl-instagram"></i></a></li>
                        <li><a href="#"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="#"><i class="bx bxl-youtube"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-team">
                    
                    <img src="assets/img/team/team-bg.png" alt="Image">
                    <div class="team-man">
                        <img src="assets/img/team/team-img-three.png" alt="Image">
                    </div>

                    <h3>Manuel Tardivo</h3>
                    <span>Full Stack Dev</span>

                    <ul class="social-icon">
                        <li><a href="#"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#"><i class="bx bxl-instagram"></i></a></li>
                        <li><a href="#"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="#"><i class="bx bxl-youtube"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>