import { Component } from '@angular/core';
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';

@Component({
  selector: 'app-contact-form-white',
  templateUrl: './contact-form-white.component.html',
  styleUrls: ['./contact-form-white.component.scss']
})
export class ContactFormWhiteComponent {

  showBanner = false;
  showError = false;

  public sendEmail(e: Event) {
    this.showBanner = true;
    e.preventDefault();
    emailjs.sendForm('service_0yeflyd', 'template_fbrn35g', e.target as HTMLFormElement, 'user_GD2ajDS9BoKDJLSU3CXMB')
      .then((result: EmailJSResponseStatus) => {
        // console.log(result.text);
      }, (error) => {
        this.showError = true;
      });
  }

}