<section class="client-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Cosa Dicono i Nostri Clienti</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>
        
        <div class="row">
            <div class="client-wrap owl-carousel owl-theme">
                <div class="single-client">
                    <img style="max-width: 70px;" src="assets/img/client/gianmarco_sanchez.png" alt="Image">
                    <h3>Gianmarco Sanchez</h3>
                    <span>Imprenditore</span>
                    <p>Utilizzo i servizi di Weebo da qualche anno.
                        Penso che sia il miglior servizio di Hosting presente sul mercato, 
                        assistenza clienti impeccabile, rispondono immediatamente e sono in grado di 
                        risolvere qualsiasi problema.</p>
                    <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                    </ul>
                </div>

                <div class="single-client">
                    <img style="max-width: 70px;" src="assets/img/client/eneo_meci.jpeg" alt="Image">
                    <h3>Eneo Meci</h3>
                    <span>Imprenditore</span>
                    <p>Mi sono trovato molto bene fino a questo momento. Molto precisi, informazioni ulteriori oltre a quello che ho richiesto con particolari a cui non avevo pensato.</p>
                    <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                    </ul>
                </div>

                <div class="single-client">
                    <img style="max-width: 70px;" src="assets/img/client/riccardo_minella.png" alt="Image">
                    <h3>Riccardo Minella</h3>
                    <span>Sviluppatore Web</span>
                    <p>Cercavo un servizio comodo da gestire, veloce, ottimizzato, con una assistenza rapida e paziente 
                        e devo dire che Weebo ha tutto ciò che serve!
                        Sicuramente lo utilizzerò per altri progetti e lo consiglio vivamente anche a chi non ci capisce 
                        nulla di informatica perchè grazie all'assistenza sono 
                        riuscito a fare cose che mai credevo di poter fare. Sono davvero molto soddisfatto!</p>
                    <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                    </ul>
                </div>
                
                <div class="single-client">
                    <img style="max-width: 70px;" src="assets/img/client/lilia_vega.png" alt="Image">
                    <h3>Lilia Vega</h3>
                    <span>Imprenditrice</span>
                    <p>Posso anche spendere 400 euro per 40gb di fondo, ma il supporto è qualcosa di unico. 
                        Nel nostro lavoro l'importante è il risolve celermente i problemi, non risparmiamo 
                        qualche centinaia di euro annui. Il vantaggio del supporto è unico che riesce in 
                        pochissimo tempo a risolvere grossi problemi con i clienti.</p>
                    <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="shape shape-ome">
        <img src="assets/img/shape/client.png" alt="Image">
    </div>
</section>