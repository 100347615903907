<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Sign Up</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Pages</li>
                        <li>User</li>
                        <li>Sign Up</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="user-area-all-style sign-up-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="contact-form-action">
                    <div class="form-heading text-center">
                        <h3 class="form-title">Create an account!</h3>
                    </div>

                    <form method="post">
                        <div class="row">
                            <div class="col-md-12 col-sm-12">
                                <div class="form-group">
                                    <input class="form-control" type="text" name="name" placeholder="First Name">
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-12">
                                <div class="form-group">
                                    <input class="form-control" type="text" name="name" placeholder="Last Name">
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-12">
                                <div class="form-group">
                                    <input class="form-control" type="text" name="name" placeholder="Enter your username">
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-12">
                                <div class="form-group">
                                    <input class="form-control" type="email" name="email" placeholder="Email Address">
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-12">
                                <div class="form-group">
                                    <input class="form-control" type="text" name="password" placeholder="Password">
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-12">
                                <div class="form-group">
                                    <input class="form-control" type="text" name="password" placeholder="Confirm Password">
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-12 col-xs-12 form-condition">
                                <div class="agree-label">
                                    <input type="checkbox" id="chb1">
                                    <label for="chb1">
                                        I agree with Haipe's 
                                        <a routerLink="/">Privacy Policy</a>
                                    </label>
                                </div>

                                <div class="agree-label">
                                    <input type="checkbox" id="chb2">
                                    <label for="chb2">
                                        I agree with Haipe's 
                                        <a routerLink="/">Terms of Services</a>
                                    </label>
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-12">
                                <button class="default-btn btn-two" type="submit">
                                    Register Account
                                </button>
                            </div>

                            <div class="col-md-12 col-sm-12">
                                <p class="account-desc">
                                    Already have an account?
                                    <a routerLink="/log-in"> Login</a>
                                </p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>