<section class="pricing-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Scegli il piano di hosting più adatto alle tue esigenze</h2>
            <p>I prezzi dei piani Hosting sono fissi, nessuna brutta sopresa. Se non sai quale piano sia il più adeguato alle tue esigenze contattaci per saperne di più.
                Certificato SSL fornito da Let's Encrypt incluso.
                <br>(*Il prezzo fa riferito al piano annuale, il piano mensile può subire delle maggiorazioni)</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-pricing" style="border-radius: 1.5em;">
                    <div class="pricing-top-heading">
                        <h3>Wee Starter</h3>
                        <p>L'ideale per cominciare</p>
                    </div>

                    <div class="price-discount">
                        <span>€8,99 <sub>/ Mese*</sub></span>
                        <!-- <p class="strike">€19,99</p> -->
                    </div>

                    <ul>
                        <li><i class="flaticon-tick"></i> 1 Dominio ospitabile</li>
                        <li><i class="flaticon-tick"></i> Sottodomini illimitati</li>
                        <li><i class="flaticon-tick"></i> 5GB Storage SSD</li>
                        <li><i class="flaticon-tick"></i> Bandwidth illimitata</li>
                        <li><i class="flaticon-tick"></i> Installazione in 1 click</li>
                        <li><i class="flaticon-tick"></i> Certificato SSL gratuito*</li>
                    </ul>
                    <!-- <a class="default-btn" routerLink="/">Iniziamo</a> -->
                    <a class="default-btn" href="https://weebo.cloud/area-clienti/store/hosting-condiviso/weebo-start-up">Iniziamo</a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <span class="popular">Popolare</span>
                <div class="single-pricing" style="border-radius: 1.5em;">
                    
                    <div class="pricing-top-heading">
                        <h3>Wee Start-Up</h3>
                        <p>Perfetto per la tua Startup</p>
                    </div>
                    <!-- <span>€14,99 <sub>/Mese</sub></span> -->
                    <div class="price-discount">
                        <span>€14,99 <sub>/ Mese*</sub></span>
                        <!-- <p class="strike">€29,99</p> -->
                    </div>

                    <ul>
                        <li><i class="flaticon-tick"></i> 3 Domini ospitabili</li>
                        <li><i class="flaticon-tick"></i> Sottodomini illimitati</li>
                        <li><i class="flaticon-tick"></i> 15GB Storage SSD</li>
                        <li><i class="flaticon-tick"></i> Bandwidth illimitata</li>
                        <li><i class="flaticon-tick"></i> Installazione in 1 click</li>
                        <li><i class="flaticon-tick"></i> Certificato SSL gratuito*</li>
                    </ul>
                    <!-- <a class="default-btn" routerLink="/">Iniziamo</a> -->
                    <a class="default-btn" href="https://weebo.cloud/area-clienti/store/hosting-condiviso/weebo-business">Iniziamo</a>
                </div>
                
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-pricing" style="border-radius: 1.5em;">
                    <div class="pricing-top-heading">
                        <h3>Wee Pro</h3>
                        <p>Per non farti mancare nulla</p>
                    </div>
                    <!-- <span>€19,99 <sub>/Mese</sub></span> -->
                    <div class="price-discount">
                        <span>€19,99 <sub>/ Mese*</sub></span>
                        <!-- <p class="strike">€64,99</p> -->
                    </div>

                    <ul>
                        <li><i class="flaticon-tick"></i> 5 Domini ospitabili</li>
                        <li><i class="flaticon-tick"></i> Sottodomini illimitati</li>
                        <li><i class="flaticon-tick"></i> 25GB Storage SSD</li>
                        <li><i class="flaticon-tick"></i> Bandwidth illimitata</li>
                        <li><i class="flaticon-tick"></i> Installazione in 1 click</li>
                        <li><i class="flaticon-tick"></i> Certificato SSL gratuito*</li>
                    </ul>
                    <!-- <a class="default-btn" routerLink="/">Iniziamo</a> -->
                    <a class="default-btn" href="https://weebo.cloud/area-clienti/index.php/store/hosting-condiviso/weebo-pro">Iniziamo</a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-pricing" style="border-radius: 1.5em;">
                    <div class="pricing-top-heading">
                        <h3>Wee Business</h3>
                        <p>Ideale per la tua azienda</p>
                    </div>
                    <!-- <span>€19,99 <sub>/Mese</sub></span> -->
                    <div class="price-discount">
                        <span>€39,99 <sub>/ Mese*</sub></span>
                        <!-- <p class="strike">€64,99</p> -->
                    </div>

                    <ul>
                        <li><i class="flaticon-tick"></i> Domini illimitati</li>
                        <li><i class="flaticon-tick"></i> Sottodomini illimitati</li>
                        <li><i class="flaticon-tick"></i> 75GB Storage SSD</li>
                        <li><i class="flaticon-tick"></i> Bandwidth illimitata</li>
                        <li><i class="flaticon-tick"></i> Installazione in 1 click</li>
                        <li><i class="flaticon-tick"></i> Certificato SSL gratuito*</li>
                    </ul>
                    <!-- <a class="default-btn" routerLink="/">Iniziamo</a> -->
                    <a class="default-btn" href="https://weebo.cloud/area-clienti/store/hosting-condiviso/weebo-enterprise">Iniziamo</a>
                </div>
            </div>
        </div>
    </div>
</section>