import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section13-sol-complete',
  templateUrl: './section13-sol-complete.component.html',
  styleUrls: ['./section13-sol-complete.component.scss']
})
export class SectionSolCompleteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
