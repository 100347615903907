import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./pages/home/home.component";
import { AboutComponent } from "./pages/about/about.component";
import { ServicesComponent } from "./pages/services/services.component";
/* import { AffiliateComponent } from "./pages/affiliate/affiliate.component"; */
import { AffiliateComponent } from "./pages/legal/affiliate/affiliate.component";
import { TeamComponent } from "./pages/team/team.component";
import { FaqComponent } from "./pages/faq/faq.component";
import { LogInComponent } from "./pages/log-in/log-in.component";
import { SignUpComponent } from "./pages/sign-up/sign-up.component";
import { RecoverPasswordComponent } from "./pages/recover-password/recover-password.component";
import { TermsConditionsComponent } from "./pages/legal/terms-conditions/terms-conditions.component";
import { PrivacyPolicyComponent } from "./pages/legal/privacy-policy/privacy-policy.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { PricingComponent } from "./pages/pricing/pricing.component";
import { NewsGridComponent } from "./pages/news-grid/news-grid.component";
import { NewsDetailsComponent } from "./pages/news-details/news-details.component";
import { NewsRightSidebarComponent } from "./pages/news-right-sidebar/news-right-sidebar.component";
import { NewsLeftSidebarComponent } from "./pages/news-left-sidebar/news-left-sidebar.component";
import { DomainComponent } from "./pages/domain/domain.component";
import { SharedHostingComponent } from "./pages/shared-hosting/shared-hosting.component";
import { ResellerHostingComponent } from "./pages/reseller-hosting/reseller-hosting.component";
import { DedicatedHostingComponent } from "./pages/dedicated-hosting/dedicated-hosting.component";
import { ComingSoonComponent } from "./pages/coming-soon/coming-soon.component";
import { ErrorComponent } from "./pages/error/error.component";
import { WordpressHostingComponent } from "./pages/wordpress-hosting/wordpress-hosting.component";
import { NataleComponent } from "./eventi/natale/natale.component";
import { CookieComponent } from "./pages/legal/cookie/cookie.component";
import { LegalComponent } from "./pages/legal/legal.component";
import { PaymentComponent } from "./pages/legal/payment/payment.component";
import { ListinoPrezziComponent } from "./pages/legal/listino-prezzi/listino-prezzi.component";
import { OfferteComponent } from "./pages/legal/offerte/offerte.component";
import { InfoProdottoDominiComponent } from "./pages/legal/info-prodotto-domini/info-prodotto-domini.component";
import { InfoProdottoHostingComponent } from "./pages/legal/info-prodotto-hosting/info-prodotto-hosting.component";
import { InfoProdottoServerComponent } from "./pages/legal/info-prodotto-server/info-prodotto-server.component";
import { InfoProdottoSitiWebComponent } from "./pages/legal/info-prodotto-siti-web/info-prodotto-siti-web.component";
import { CreditoComponent } from "./pages/legal/credito/credito.component";
import { GdprComponent } from "./pages/legal/gdpr/gdpr.component";
import { registerLocaleData } from "@angular/common";
import { RegistrazioneDominiComponent } from "./pages/legal/registrazione-domini/registrazione-domini.component";
import { AccetazioneClausoleComponent } from "./pages/legal/accetazione-clausole/accetazione-clausole.component";

const routes: Routes = [
    { path: "", component: HomeComponent },
    { path: "about", component: AboutComponent },
    { path: "services", component: ServicesComponent },
    // {path: 'affiliate', component: AffiliateComponent},
    { path: 'team', component: TeamComponent },
    { path: "faq", component: FaqComponent },
    // {path: 'log-in', component: LogInComponent},
    // {path: 'sign-up', component: SignUpComponent},
    // {path: 'recover-password', component: RecoverPasswordComponent},
    { path: "pricing", component: PricingComponent },
    {path: 'domain', component: DomainComponent},
    { path: "shared-hosting", component: SharedHostingComponent },
    // {path: 'reseller-hosting', component: ResellerHostingComponent},
    // {path: 'dedicated-hosting', component: DedicatedHostingComponent},
    // { path: "news-grid", component: NewsGridComponent },
    // { path: "news-left-sidebar", component: NewsLeftSidebarComponent },
    // { path: "news-right-sidebar", component: NewsRightSidebarComponent },
    // { path: "news-details", component: NewsDetailsComponent },
    { path: "coming-soon", component: ComingSoonComponent },
    { path: "error", component: ErrorComponent },
    { path: "contact", component: ContactComponent },
    {
        path: "legal",
        children: [
            {
                path: "terms-conditions",
                component: TermsConditionsComponent,
            },
            {
                path: "privacy-policy",
                component: PrivacyPolicyComponent,
            },
            {
                path: "cookie",
                component: CookieComponent,
            },
            {
                path: 'payment',
                component: PaymentComponent,
            },
            {
                path: 'listino-prezzi',
                component: ListinoPrezziComponent,
            },
            {
                path: 'offerte',
                component: OfferteComponent,
            },
            {
                path: 'info-prodotto-domini',
                component: InfoProdottoDominiComponent,
            },
            {
                path: 'info-prodotto-hosting',
                component: InfoProdottoHostingComponent,
            },
            {
                path: 'info-prodotto-server',
                component: InfoProdottoServerComponent,
            },
            {
                path: 'info-prodotto-siti-web',
                component: InfoProdottoSitiWebComponent,
            },
            {
                path: 'credito',
                component: CreditoComponent,
            },
            {
                path: 'gdpr',
                component: GdprComponent,
            },
            {
                path: 'registrazione-domini',
                component: RegistrazioneDominiComponent,
            },
            {
                path: 'accettazione-clausole',
                component: AccetazioneClausoleComponent,
            },
            {
                path: 'affiliate',
                component: AffiliateComponent,
            },
            {
                path: "**",
                component: LegalComponent,
            },
        ],
    },
    { path: "wordpress-hosting", component: WordpressHostingComponent },
    // {path: 'xmas', component: NataleComponent},
    { path: "**", component: ErrorComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
