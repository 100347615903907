import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section8-map',
  templateUrl: './section8-map.component.html',
  styleUrls: ['./section8-map.component.scss']
})
export class Section4MapComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
