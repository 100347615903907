<section class="hosting-solution-area ptb-100">
    <div class="container">
        <div class="hosting-solution-wrap">
            <h2>Non scendiamo a compromessi con le nostre soluzioni!</h2>
            <a class="default-btn" href="https://weebo.cloud/area-clienti/index.php/store/hosting-condiviso">Iniziamo</a>
            <a class="default-btn active" routerLink="/contact">Contattaci</a>
        </div>
    </div>

    <div class="shape shape-one"><img src="assets/img/shape/hosting-solution-one.png" alt=""></div>
    <div class="shape shape-two"><img src="assets/img/shape/hosting-solution-two.png" alt=""></div>
</section>