<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Cookie</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/legal">Legal</a></li>
                        <li>Cookie</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="terms-conditions ptb-100">
    <div class="container">
        <div class="single-privacy">

            <h1 style="margin-top: 10px;">Cookie Policy</h1>
            <p>Updated at 2023-04-16</p>
            <p class="p6"><br></p>
            <h1>Definitions and key terms</h1>
            <p class="p8"><span class="s1">To help explain things as clearly as possible in this Cookie Policy, every
                    time
                    any of these terms are referenced, are strictly defined as:</span></p>
            <p class="p9"><br></p>
            <ul class="ul1">
                <li class="li8"><span class="s1"><strong>Cookie:</strong> small amount of data generated by a website
                        and
                        saved by your web browser. It is used to identify your browser, provide analytics, remember
                        information about you such as your language preference or login information.<br></span></li>
                <li class="li8"><span class="s1"><strong>Company:</strong> when this policy mentions “Company,” “we,”
                        “us,”
                        or “our,” it refers to <a>WeeBo</a>, <a>Trento</a> that is responsible for your information
                        under
                        this Cookie Policy.<br></span></li> <!---->
                <li class="li8"><span class="s1"><strong>Device:</strong> any internet connected device such as a phone,
                        tablet, computer or any other device that can be used to visit <a>WeeBo</a> and use the
                        services.<br></span></li>
                <li class="li8"><span class="s1"><strong>Personal Data:</strong> any information that directly,
                        indirectly,
                        or in connection with other information — including a personal identification number — allows
                        for
                        the identification or identifiability of a natural person.<br></span></li>
                <li class="li8"><span class="s1"><strong>Service:</strong> refers to the service provided by
                        <a>WeeBo</a> as
                        described in the relative terms (if available) and on this platform.<br></span></li>
                <li class="li8"><span class="s1"><strong>Third-party service:</strong> refers to advertisers, contest
                        sponsors, promotional and marketing partners, and others who provide our content or whose
                        products
                        or services we think may interest you.<br></span></li>
                <li class="li8"><span class="s1"><strong>Website:</strong> <a>WeeBo</a>’s site, which can be accessed
                        via
                        this URL: <a>weebo.cloud.</a><br></span></li>
                <li class="li8"><span class="s1"><strong>You:</strong> a person or entity that is registered with
                        <a>WeeBo</a> to use the Services.<br></span></li>
            </ul>
            <p class="p3"><br></p>
            <h1>Introduction</h1>
            <p class="p10"><span class="s1">
                    This Cookie Policy explains how <a>WeeBo</a> and its affiliates (collectively "<a>WeeBo</a>", "we",
                    "us", and "ours"), use cookies and similar technologies to recognize you when you visit our
                    platform,
                    including without limitation weebo.cloud and any related URLs, mobile or localized versions and
                    related
                    domains / sub-domains ("Websites"). It explains what these technologies are and why we use them, as
                    well
                    as the choices for how to control them.</span></p>
            <p class="p3"><br></p>
            <h1>What is a cookie?</h1>
            <p class="p10"><span class="s1">A cookie is a small text file that is stored on your computer or other
                    internet
                    connected device in order to identify your browser, provide analytics, remember information about
                    you
                    such as your language preference or login information. They're completely safe and can't be used to
                    run
                    programs or deliver viruses to your device.</span></p>
            <p class="p3"><br></p>
            <h1>Why do we use cookies?</h1>
            <p class="p10"><span class="s1">We use first party and/or third party cookies on our platform for various
                    purposes such as:
                </span></p>
            <ul class="ul1">
                <li><span class="s3">To facilitate the operation and functionality of our platform;</span></li>
                <li><span class="s3">To improve your experience of our platform and make navigating around them quicker
                        and
                        easier;</span></li>
                <li><span class="s3">To allow us to make a bespoke user experience for you and for us to understand what
                        is
                        useful or of interest to you;</span></li>
                <li><span class="s3">To analyze how our platform is used and how best we can customize it;</span></li>
                <li><span class="s3">To identify future prospects and personalize marketing and sales interactions with
                        it;</span></li>
                <li><span class="s3">To facilitate the tailoring of online advertising to your interests.</span></li>
            </ul>
            <p class="p3"><br></p>
            <h1>What type of cookies does <a>WeeBo</a> use?</h1>
            <p class="p10"><span class="s1">Cookies can either be session cookies or persistent cookies. A session
                    cookie
                    expires automatically when you close your browser. A persistent cookie will remain until it expires
                    or
                    you delete your cookies. Expiration dates are set in the cookies themselves; some may expire after a
                    few
                    minutes while others may expire after multiple years. Cookies placed by the website you’re visiting
                    are
                    called “first party cookies”.</span></p>
            <p class="p10"><span class="s1">Strictly Necessary cookies are necessary for our platform to function and
                    cannot
                    be switched off in our systems. They are essential in order to enable you to navigate around the
                    platform and use its features. If you remove or disable these cookies, we cannot guarantee that you
                    will
                    be able to use our platform.</span></p>
            <p class="p10"><span class="s1">We use the following types of cookies in our platform:</span></p>
            <p class="p3"><br></p>
            <h1>Essential Cookies</h1>
            <p class="p10"><span class="s1">We use essential cookies to make our platform work. These cookies are
                    strictly
                    necessary to enable core functionality such as security, network management, your cookie preferences
                    and
                    accessibility. Without them you wouldn't be able to use basic services. You may disable these by
                    changing your browser settings, but this may affect how the Websites function.
                    <p class="p3"><br></p>
                    <h1>Performance and Functionality Cookies</h1>
                    <p class="p10"><span class="s1">These cookies are used to enhance the performance and functionality
                            of
                            our platform but are non-essential to their use. However, without these cookies, certain
                            functionality like videos may become unavailable or you would be required to enter your
                            login
                            details every time you visit the platform as we would not be able to remember that you had
                            logged in previously.
                        </span></p>
                    <p class="p3"><br></p>
                    <h1>Marketing Cookies</h1>
                    <p class="p10"><span class="s1">These account-based marketing cookies enable us to identify future
                            prospects and personalize sales and marketing interactions with them.
                        </span></p>
                    <p class="p3"><br></p>
                    <h1>Analytics and Customization Cookies</h1>
                    <p class="p10"><span class="s1">These cookies collect information that is used to help us understand
                            how
                            our platform is being used or how effective our marketing campaigns are, or to help us
                            customize
                            our platform for you.
                            <br><br>
                            We use cookies served by Google Analytics to collect limited data directly from end-user
                            browsers to enable us to better understand your use of our platform. Further information on
                            how
                            Google collects and uses this data can be found
                            at:&nbsp;https://www.google.com/policies/privacy/partners/. You can opt-out of all Google
                            supported analytics on our Websites by
                            visiting:&nbsp;https://tools.google.com/dlpage/gaoptout.
                        </span></p>
                    <p class="p3"><br></p> <!----> <!----> <!---->
                    <h1>Social Media Cookies</h1>
                    <p class="p10"><span class="s1">These cookies are used when you share information using a social
                            media
                            sharing button or “like” button on our Site or you link your account or engage with our
                            content
                            on or through a social networking website such as Facebook, Twitter or Google+. The social
                            network will record that you have done this. These cookies may also include certain code
                            that
                            has been placed on the platform to help track conversions from ads, optimize ads based on
                            collected data, build targeted audiences for future ads, and remarket to qualified users who
                            have already taken certain action on the platform.
                        </span></p>
                    <p class="p3"><br></p>
                    <h1>Third Party Cookies</h1>
                    <p class="p10"><span class="s1">Some cookies that have been set on our platform are not set on a
                            first
                            party basis by <a>WeeBo</a>. The Websites can be embedded with content from third parties to
                            serve advertising. These third party service providers may set their own cookies on your web
                            browser. Third party service providers control many of the performance and functionality,
                            advertising, marketing and analytics cookies described above. We do not control the use of
                            these
                            third party cookies as cookies can only be accessed by the third party that originally set
                            them.
                        </span></p>
                    <p class="p3"><br></p>
                    <h1>How you can manage cookies?</h1>
                    <p class="p10"><span class="s1">Most browsers allow you to control cookies through their 'settings'
                            preferences. However, if you limit the ability of websites to set cookies, you may worsen
                            your
                            overall user experience, since it will no longer be personalized to you. It may also stop
                            you
                            from saving customized settings like login information. Browser manufacturers provide help
                            pages
                            relating to cookie management in their products.</span></p> <br><br>
                    <p class="p10"><span class="s1">Browser manufacturers provide help pages relating to cookie
                            management
                            in their products. Please see below for more information.</span></p>
                    <ul class="ul1">
                        <li><span class="s3">Google Chrome</span></li>
                        <li><span class="s3">Internet Explorer</span></li>
                        <li><span class="s3">Mozilla Firefox</span></li>
                        <li><span class="s3">Safari (Desktop)</span></li>
                        <li><span class="s3">Safari (Mobile)</span></li>
                        <li><span class="s3">Android Browser</span></li>
                        <li><span class="s3">Opera</span></li>
                        <li><span class="s3">Opera Mobile</span></li>
                    </ul>
                    <p class="p3"><br></p>
                    <h1>Blocking and disabling cookies and similar technologies</h1>
                    <p class="p10"><span class="s1">Wherever you're located you may also set your browser to block
                            cookies
                            and similar technologies, but this action may block our essential cookies and prevent our
                            website from functioning properly, and you may not be able to fully utilize all of its
                            features
                            and services. You should also be aware that you may also lose some saved information (e.g.
                            saved
                            login details, site preferences) if you block cookies on your browser. Different browsers
                            make
                            different controls available to you. Disabling a cookie or category of cookie does not
                            delete
                            the cookie from your browser, you will need to do this yourself from within your browser,
                            you
                            should visit your browser's help menu for more information.</span></p>
                    <p class="p3"><br></p>
                    <h1>Changes To Our Cookie Policy</h1>
                    <p class="p10"><span class="s1">We may change our Service and policies, and we may need to make
                            changes
                            to this Cookie Policy so that they accurately reflect our Service and policies. Unless
                            otherwise
                            required by law, we will notify you (for example, through our Service) before we make
                            changes to
                            this Cookie Policy and give you an opportunity to review them before they go into effect.
                            Then,
                            if you continue to use the Service, you will be bound by the updated Cookie Policy. If you
                            do
                            not want to agree to this or any updated Cookie Policy, you can delete your account.</span>
                    </p>
                    <p class="p3"><br></p>
                    <h1>Your Consent</h1>
                    <p class="p10"><span class="s1">By using our platform, registering an account, or making a purchase,
                            you
                            hereby consent to our Cookie Policy and agree to its terms.</span></p>
                    <p class="p3"><br></p>
                    <h1>Contact Us</h1>
                    <p class="p10"><span class="s1">Don't hesitate to contact us if you have any questions regarding our
                            Cookie Policy.</span></p>
                    <ul class="ul1">
                        <li class="li16"><span class="s3">Via Email: <a>supporto@weebo.cloud</a></span></li>
                        <li class="li16"><span class="s3">Via Phone Number: <a>+393517994419</a></span></li>
                        <li class="li16"><span class="s3">Via this Link: <a>https://weebo.cloud/#/contact</a></span></li>
                        <!---->
                    </ul>
                </span></p>
        </div>
    </div>
</section>