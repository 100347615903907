<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Credito</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/legal">Legal</a></li>
                        <li>Credito</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="terms-conditions ptb-100">
    <div class="container">
        <div class="single-privacy">
            <h2>Premesse</h2>
            <p>
                Il presente Ordine di Servizio ("OdS") è parte integrante delle
                Condizioni Generali di Servizio ("CGS"). Le CGS e il presente
                OdS stabiliscono i termini e condizioni per la fornitura del
                servizio di Credito (di seguito anche “Credito”) da parte di
                WeeBo (“weebo.cloud”) ai Clienti (“Clienti" o “Cliente”). I
                termini con iniziale maiuscola utilizzati nel presente OdS hanno
                il medesimo significato definito nelle CGS.
            </p>

            <h3>Art. 1 Oggetto dell'OdS e utilizzo del Credito</h3>
            <ol>
                <li>
                    <p>
                        Attraverso il Credito, WeeBo offre al Cliente la
                        possibilità di alimentare un saldo disponibile per
                        l'acquisto dei servizi offerti sul portale
                        <a href="https://www.weebo.cloud">www.weebo.cloud</a>
                        (di seguito “Servizi WeeBo”). Il Cliente non potrà
                        utilizzare il saldo del Credito per acquistare ulteriori
                        Ricariche. Il saldo del Credito potrà essere alimentato
                        mediante (i) ricariche acquistate dal Cliente secondo le
                        modalità descritte alla pagina
                        <a href="https://www.weebo.cloud/legal/pagamenti"
                            >https://www.weebo.cloud/legal/pagamenti</a
                        >
                        (di seguito anche “Ricarica” o “Ricariche”), e (ii)
                        eventuali bonus accordati al Cliente a titolo gratuito,
                        di offerta o promozione (di seguito detti anche
                        “Bonus”).
                    </p>
                </li>
                <li>
                    <p>
                        Il servizio di Credito resterà valido per un periodo di
                        diciotto mesi dall'ultima Ricarica o Bonus. Al momento
                        dell'incremento del saldo del Credito, mediante nuova
                        Ricarica o Bonus, il predetto termine di validità del
                        servizio di Credito ricomincerà a decorrere per uguale
                        periodo, come specificato ai successivi articoli.
                    </p>
                </li>
                <li>
                    <p>
                        Effettuato l'acquisto di una Ricarica, essa sarà
                        disponibile, indicativamente, entro 1 giorno lavorativo
                        dall'avvenuto accredito del relativo importo in favore
                        di WeeBo. Il saldo del Credito sarà visualizzato nel
                        pannello di controllo del Cliente e, da tale momento,
                        potrà essere utilizzato come mezzo di pagamento per
                        eseguire gli acquisti dei Servizi WeeBo.
                    </p>
                </li>
                <li>
                    <p>
                        Il saldo del Credito deve essere pari o superiore al
                        prezzo totale, incluse le imposte, dei Servizi WeeBo che
                        il Cliente intende acquistare utilizzando il saldo del
                        Credito. È onere del Cliente verificare la capienza del
                        saldo del proprio Credito, in quanto non è consentito
                        effettuare il pagamento dei Servizi WeeBo utilizzando
                        più mezzi di pagamento.
                    </p>
                </li>
                <li>
                    <p>
                        Il Cliente è informato che il Credito non costituisce in
                        alcun modo un conto bancario. Pertanto, nessun interesse
                        di alcun tipo sarà mai calcolato sul Credito e nessun
                        servizio e attività finanziaria saranno eseguiti sul
                        Credito.
                    </p>
                </li>
                <li>
                    <p>
                        Il Cliente non può trasferire, in tutto o in parte, il
                        Credito ad altro soggetto.
                    </p>
                </li>
                <li>
                    <p>
                        A fronte della ricezione delle somme per le Ricariche
                        del Credito non sarà emessa fattura da parte di WeeBo.
                        Il Cliente è informato che WeeBo emetterà fattura al
                        momento dell'acquisto dei Servizi WeeBo per il relativo
                        importo.
                    </p>
                </li>
                <li>
                    <p>
                        I Bonus potranno indicare la data di validità o scadenza
                        entro cui devono essere utilizzati, altrimenti essi
                        dovranno essere usati per l'acquisto dei servizi WeeBo
                        entro la data di validità del servizio di Credito
                        prevista dall'art. 1.1.
                    </p>
                </li>
            </ol>

            <h3>
                Art. 2 Diritto di recesso, risoluzione dal presente OdS e
                cessazione del Credito
            </h3>
            <ol>
                <li>
                    <p>
                        Il Cliente che sia qualificabile come Consumatore ha
                        facoltà di esercitare il diritto di recesso dal presente
                        OdS ai sensi del D.Lgs. n. 206/2005, secondo quanto
                        previsto nelle Condizioni Generali di Servizio di WeeBo.
                    </p>
                </li>
                <li>
                    <p>
                        Fermo quanto sopra, sia il Cliente che WeeBo hanno il
                        diritto di recedere dal presente OdS, dandone
                        comunicazione scritta con preavviso di 30 giorni.
                    </p>
                </li>
                <li>
                    <p>
                        Il Cliente potrà esercitare il recesso a mezzo mezzo PEC
                        all'indirizzo
                        <a href="mailto:stetomasi@pec.it">stetomasi@pec.it</a>,
                        allegando fotocopia di un proprio documento di
                        riconoscimento e indicando le coordinate bancarie (IBAN)
                        su cui intende ricevere il rimborso dell'eventuale saldo
                        residuo del proprio Credito.
                    </p>
                </li>
                <li>
                    <p>
                        Il Cliente è consapevole ed accetta che, qualora non
                        alimenti il servizio di Credito entro il termine di
                        diciotto mesi di validità, WeeBo avrà facoltà di cessare
                        il servizio di Credito previa comunicazione inviata al
                        Cliente.
                    </p>
                </li>
                <li>
                    <p>
                        In caso di recesso o di cessazione del servizio di
                        Credito, WeeBo rimborserà al Cliente le somme
                        disponibili del saldo del Credito relative a Ricariche
                        in precedenza effettuate dal Cliente, fermo quanto
                        disposto al successivo art. 2.5. Non saranno rimborsati
                        gli importi presenti nel saldo del Credito a titolo di
                        Bonus (o credito derivante dall'attività di affiliazione).
                    </p>
                </li>
                <li>
                    <p>
                        In caso di recesso ad opera del Cliente, Egli dovrà
                        trasmettere a WeeBo, entro il termine di 3 mesi
                        dall'invio della comunicazione, le proprie coordinate
                        bancarie (IBAN) su cui intendere ricevere il rimborso
                        del saldo residuo.
                    </p>
                </li>
            </ol>

            <h3>Art. 3 Obblighi specifici del Cliente</h3>
            <ol>
                <li>
                    <p>
                        Il Cliente può verificare il saldo del proprio Credito
                        dal pannello di controllo associato al proprio Account
                        WeeBo.
                    </p>
                </li>
                <li>
                    <p>
                        Il Cliente è responsabile dei movimenti e dell'utilizzo
                        degli importi presenti nel Credito del proprio Account.
                    </p>
                </li>
                <li>
                    <p>
                        Il Cliente è l'esclusivo responsabile delle credenziali
                        fornite da WeeBo per l'accesso ai servizi e si impegna
                        ad utilizzare le medesime personalmente ed in maniera
                        tale da non creare danni o turbative alla rete o a terzi
                        utenti e a non violare leggi o regolamenti.
                    </p>
                </li>
                <li>
                    <p>
                        Il Cliente si impegna ad alimentare il proprio Credito
                        mediante Ricariche periodiche eseguite a distanza non
                        superiore a diciotto mesi l'una dell'altra.
                    </p>
                </li>
                <li>
                    <p>
                        Il Cliente dovrà riscontrare tempestivamente le
                        comunicazioni inviate da WeeBo e si impegnerà ad
                        aggiornare in caso di variazioni i contatti forniti a
                        WeeBo e a controllarli su base quotidiana.
                    </p>
                </li>
            </ol>
        </div>
    </div>
</section>
