<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Il nostro Team</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Team</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="our-team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Il nostro team di esperti</h2>
            <p>
                Il nostro team esperto è composto da professionisti altamente
                qualificati nel settore, con anni di esperienza e competenze
                specializzate. Siamo dedicati a fornire soluzioni innovative e
                di alta qualità per soddisfare le esigenze dei nostri clienti.
                Con una combinazione di creatività, competenza tecnica e impegno
                verso l'eccellenza, lavoriamo insieme per raggiungere risultati
                straordinari in ogni progetto.
            </p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <img src="assets/img/team/team-bg.png" alt="Image" />

                    <div class="team-man">
                        <img
                            style="max-width: 40%"
                            src="assets/img/team/stefano_tomasi.png"
                            alt="Image"
                        />
                        <!-- //team-img-six.png -->
                    </div>

                    <h3>Stefano Tomasi</h3>
                    <span>Founder & IT Director</span>

                    <ul class="social-icon">
                        <li>
                            <a
                                href="https://github.com/stetommy"
                                target="_blank"
                                ><i class="bx bxl-github"></i
                            ></a>
                        </li>

                        <li>
                            <a
                                href="https://www.instagram.com/stetommy_/"
                                target="_blank"
                                ><i class="bx bxl-instagram"></i
                            ></a>
                        </li>

                        <li>
                            <a
                                href="https://www.linkedin.com/in/stefano-tomasi/"
                                target="_blank"
                                ><i class="bx bxl-linkedin"></i
                            ></a>
                        </li>

                        <li>
                            <a
                                href="https://links.weebo.cloud/@stetommy"
                                target="_blank"
                                ><i class="bx bx-link"></i
                            ></a>
                        </li>

                        <!-- <li><a href="#" target="_blank"><i class="bx bxl-youtube"></i></a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <img src="assets/img/team/team-bg.png" alt="Image" />

                    <div class="team-man">
                        <img
                            style="max-width: 40%"
                            src="assets/img/team/manuel_tardivo.png"
                            alt="Image"
                        />
                        <!-- //team-img-four.png -->
                    </div>
                    <h3>Manuel Tardivo</h3>
                    <span>Full Stack Dev</span>

                    <ul class="social-icon">
                        <li>
                            <a
                                href="https://github.com/sbardijs"
                                target="_blank"
                                ><i class="bx bxl-github"></i
                            ></a>
                        </li>

                        <li>
                            <a
                                href="https://www.instagram.com/sbardijs/"
                                target="_blank"
                                ><i class="bx bxl-instagram"></i
                            ></a>
                        </li>

                        <li>
                            <a
                                href="https://www.linkedin.com/in/manueltardivo/"
                                target="_blank"
                                ><i class="bx bxl-linkedin"></i
                            ></a>
                        </li>

                        <li>
                            <a
                                href="https://links.weebo.cloud/@sbardi"
                                target="_blank"
                                ><i class="bx bx-link"></i
                            ></a>
                        </li>

                        <!-- <li><a href="#" target="_blank"><i class="bx bxl-youtube"></i></a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <img src="assets/img/team/team-bg.png" alt="Image" />

                    <div class="team-man">
                        <img
                            style="max-width: 40%"
                            src="assets/img/team/karin_zeni.png"
                            alt="Image"
                        />
                        <!-- //team-img-three.png -->
                    </div>
                    <h3>Karin Zeni</h3>
                    <span>UI/UX Designer</span>

                    <ul class="social-icon">
                        <li>
                            <a
                                href="https://www.behance.net/karinzeni1"
                                target="_blank"
                                ><i class="bx bx-folder"></i
                            ></a>
                        </li>

                        <li>
                            <a
                                href="https://www.instagram.com/line.spacing/"
                                target="_blank"
                                ><i class="bx bxl-instagram"></i
                            ></a>
                        </li>

                        <li>
                            <a
                                href="https://www.linkedin.com/in/karin-zeni-18758b152/"
                                target="_blank"
                                ><i class="bx bxl-linkedin"></i
                            ></a>
                        </li>

                        <li>
                            <a
                                href="https://links.weebo.cloud/@karinzeni"
                                target="_blank"
                                ><i class="bx bx-link"></i
                            ></a>
                        </li>

                        <!-- <li><a href="#" target="_blank"><i class="bx bxl-youtube"></i></a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-team">
                    <img src="assets/img/team/team-bg.png" alt="Image" />

                    <div class="team-man">
                        <img
                            style="max-width: 40%"
                            src="assets/img/team/riccardo_minella.png"
                            alt="Image"
                        />
                        <!-- //team-img-eight.png -->
                    </div>

                    <h3>Riccardo Minella</h3>
                    <span>Technical Lead</span>

                    <ul class="social-icon">
                        <li>
                            <a
                                href="https://github.com/RiccardoMinella"
                                target="_blank"
                                ><i class="bx bxl-github"></i
                            ></a>
                        </li>

                        <li>
                            <a
                                href="https://www.instagram.com/riccardo_minella/"
                                target="_blank"
                                ><i class="bx bxl-instagram"></i
                            ></a>
                        </li>

                        <li>
                            <a
                                href="https://www.linkedin.com/in/riccardo-minella/"
                                target="_blank"
                                ><i class="bx bxl-linkedin"></i
                            ></a>
                        </li>

                        <li>
                            <a
                                href="https://links.weebo.cloud/@jojoba"
                                target="_blank"
                                ><i class="bx bx-link"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <img src="assets/img/team/team-bg.png" alt="Image" />

                    <div class="team-man">
                        <img
                            style="max-width: 40%"
                            src="assets/img/team/gianmarco_sanchez.png"
                            alt="Image"
                        />
                    </div>

                    <h3>Gianmarco Sanchez</h3>
                    <span>Swiss Representative</span>

                    <ul class="social-icon">
                        <li>
                            <a href="#" target="_blank"
                                ><i class="bx bx-folder"></i
                            ></a>
                        </li>

                        <li>
                            <a
                                href="https://www.instagram.com/alexandersanchez.ch/"
                                target="_blank"
                                ><i class="bx bxl-instagram"></i
                            ></a>
                        </li>

                        <li>
                            <a
                                href="https://www.linkedin.com/in/alexandersanchezch/"
                                target="_blank"
                                ><i class="bx bxl-linkedin"></i
                            ></a>
                        </li>

                        <li>
                            <a
                                href="https://links.weebo.cloud/@sancito"
                                target="_blank"
                                ><i class="bx bx-link"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <img src="assets/img/team/team-bg.png" alt="Image" />

                    <div class="team-man">
                        <img
                            style="max-width: 40%"
                            src="assets/img/team/eneo_meci.png"
                            alt="Image"
                        />
                    </div>

                    <h3>Eneo Meci</h3>
                    <span>Foreign Operations Coordinator</span>

                    <ul class="social-icon">
                        <li>
                            <a href="#" target="_blank"
                                ><i class="bx bx-folder"></i
                            ></a>
                        </li>

                        <li>
                            <a
                                href="https://www.instagram.com/eneo_meci/"
                                target="_blank"
                                ><i class="bx bxl-instagram"></i
                            ></a>
                        </li>

                        <li>
                            <a
                                href="https://www.linkedin.com/in/eneo-meci-826999159/"
                                target="_blank"
                                ><i class="bx bxl-linkedin"></i
                            ></a>
                        </li>

                        <li>
                            <a
                                href="https://links.weebo.cloud/@mecito"
                                target="_blank"
                                ><i class="bx bx-link"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="expert-team-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Expert Team</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-team">
                    <img src="assets/img/team/team-img-nine.jpg" alt="Image">

                    <div class="team-content">
                        <h3>Jonkin Jullinor</h3>
                        <span>Product supplier</span>
                    </div>

                    <ul>
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-team">
                    <img src="assets/img/team/team-img-ten.jpg" alt="Image">

                    <div class="team-content">
                        <h3>Naimuk Waninolin</h3>
                        <span>Office executive support</span>
                    </div>

                    <ul>
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-team">
                    <img src="assets/img/team/team-img-eleven.jpg" alt="Image">

                    <div class="team-content">
                        <h3>Moris Julfikar</h3>
                        <span>Regional supplier</span>
                    </div>

                    <ul>
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-team">
                    <img src="assets/img/team/team-img-twelve.jpg" alt="Image">

                    <div class="team-content">
                        <h3>Waxin Alexgander</h3>
                        <span>International supplier</span>
                    </div>

                    <ul>
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-team">
                    <img src="assets/img/team/team-img-thirteen.jpg" alt="Image">

                    <div class="team-content">
                        <h3>Waxin Alexgander</h3>
                        <span>International supplier</span>
                    </div>

                    <ul>
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-team">
                    <img src="assets/img/team/team-img-fourteen.jpg" alt="Image">

                    <div class="team-content">
                        <h3>Waxin Alexgander</h3>
                        <span>International supplier</span>
                    </div>

                    <ul>
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>

                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section> -->
