<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Offerte</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/legal">Legal</a></li>
                        <li>Offerte</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="terms-conditions ptb-100">
    <div class="container">
        <div class="single-privacy">
        </div>
    </div>
</section>