<section class="faq-contact-area pb-100" *ngIf="!showBanner">
    <div class="container">
        <div class="ask-question">
            <div class="section-title">
                <h2>Contattaci!</h2>
            </div>

            <form id="contactForm" (submit)="sendEmail($event)"> 
                <div class="row">
                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="user_name" class="form-control" placeholder="Nome">
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="email" name="user_email" class="form-control" placeholder="Email">
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="tel" name="user_phone" class="form-control" placeholder="Cellulare">
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="user_subject" class="form-control" placeholder="Oggetto">
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea name="message" class="form-control" cols="30" rows="5" placeholder="Messaggio"></textarea>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <button type="submit" value="Send" class="default-btn btn-two"><span class="label">Invia Messaggio</span></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>

<app-send-msg-ok *ngIf="showBanner"></app-send-msg-ok>
<app-send-msg-error *ngIf="showError"></app-send-msg-error>