<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Documentazione legale</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li>Legal</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="terms-conditions ptb-100">
    <div class="container">
        <div class="single-privacy" style="text-align: center">
            <h1>Informativa generale</h1>
            <br />
            <div style="overflow: auto">
                <div style="float: left; width: 33.33%">
                    <a routerLink="/legal/terms-conditions"
                        >Condizioni generali di servizio</a
                    >
                </div>
                <div style="float: left; width: 33.33%">
                    <a routerLink="/legal/privacy-policy"
                        >Informativa Privacy</a
                    >
                </div>
                <div style="float: left; width: 33.33%">
                    <a routerLink="/legal/credito">Ordini di Servizio: Credito</a>
                </div>
            </div>
            <br>
            <div style="overflow: auto">
                <div style="float: left; width: 33.33%">
                    <a routerLink="/legal/gdpr"
                        >Informazioni sul GDPR</a
                    >
                </div>
                <div style="float: left; width: 33.33%">
                    <a routerLink="/legal/registrazione-domini"
                        >Informativa Privacy - Registrazione Nomi a Dominio</a
                    >
                </div>
                <div style="float: left; width: 33.33%">
                    <a routerLink="/legal/accettazione-clausole"
                        >Accettazione specifica delle clausole</a
                    >
                </div>
            </div>
            <br>
            <div style="overflow: auto">
                <div style="float: left; width: 33.33%">
                    <a routerLink="/legal/affiliate"
                        >Affiliati</a
                    >
                </div>
            </div>
            <br />
            <br />
            <h1>Informativa di prodotto</h1>
            <br />
            <div style="overflow: auto">
                <div style="float: left; width: 25%">
                    <a routerLink="/legal/info-prodotto-domini">Domini</a>
                </div>
                <div style="float: left; width: 25%">
                    <a routerLink="/legal/info-prodotto-hosting">Shared Hosting</a>
                </div>
                <div style="float: left; width: 25%">
                    <a routerLink="/legal/info-prodotto-server">Server VPS</a>
                </div>
                <div style="float: left; width: 25%">
                    <a routerLink="/legal/info-prodotto-siti-web">Siti Web</a>
                </div>
            </div>
        </div>
    </div>
</section>
