<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Chi siamo</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Home </a></li>
                        <li>Supporto</li>
                        <li>Chi siamo</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="pt-100">
    <app-weebo-services></app-weebo-services>
</div>

<app-brand-collaboration></app-brand-collaboration>

<!-- <app-section4-host-sol></app-section4-host-sol> -->

<!-- <app-section8-map></app-section8-map> -->

<section class="dedicated-server-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="server-img">
                    <img src="assets/img/server-img.png" alt="Image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="server-content-wrap">
                    <h2>Caratteristiche del server dedicato</h2>
                    <p>I nostri server dedicati offrono una soluzione potente e affidabile per le esigenze di hosting avanzate. Con risorse dedicate e prestazioni garantite, potrai gestire facilmente applicazioni complesse e carichi di lavoro intensi. Scegliendo un server dedicato con noi, avrai accesso a un ambiente sicuro e personalizzabile, con opzioni di gestione flessibili e un'assistenza tecnica dedicata per garantire la massima performance e sicurezza per il tuo business online.</p>

                    <ul>
                        <li>
                            <i class="flaticon-migrating"></i>
                            <span>Migrazione gratuita</span>
                            <p>Trasferisci i tuoi dati senza costi aggiuntivi. Il nostro team esperto gestirà la migrazione per garantire una transizione senza problemi.</p>
                        </li>
                        <li>
                            <i class="flaticon-migrating"></i>
                            <span>Opzione di gestione del server</span>
                            <p>Personalizza la gestione del tuo server in base alle tue esigenze. Scegli tra opzioni di gestione completa o parziale per massimizzare la tua flessibilità.</p>
                        </li>
                        <li>
                            <i class="flaticon-verified"></i>
                            <span>Sicurezza</span>
                            <p>La tua sicurezza è la nostra priorità. Implementiamo le migliori pratiche di sicurezza per proteggere i tuoi dati e garantire la continuità del servizio.</p>
                        </li>
                        <li>
                            <i class="flaticon-network-1"></i>
                            <span>Rete ottimizzata</span>
                            <p>Massimizza le prestazioni del tuo server con la nostra rete ottimizzata. Garantiamo una connettività affidabile e veloce per soddisfare le tue esigenze.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <app-section10-recensioni></app-section10-recensioni> -->