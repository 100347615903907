<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Informativa Affiliati</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/legal">Legal</a></li>
                        <li>Affiliati</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="terms-conditions ptb-100">
    <div class="container">
        <div class="single-privacy">
            <h2 style="margin-top: 10px">Disclaimer</h2>
            <p><b>Aggiornato il 10/03/24</b></p>
            <h1>Informativa legale per il Programma di Affiliazione</h1>
            <p>
                Benvenuto nel Programma di Affiliazione di WeeBo ("Noi", "ci",
                "nostro"), dedicato a offrire opportunità di guadagno attraverso
                la promozione dei nostri servizi.
            </p>
            <p style="font-weight: bold">
                Accedendo al nostro Programma di Affiliazione e utilizzando i
                servizi correlati, confermi di aver letto, compreso e accettato
                integralmente i Termini e Condizioni descritti in questa
                informativa. L'accettazione di questi Termini e Condizioni
                avviene implicitamente tramite l'utilizzo dei servizi offerti da
                WeeBo. Qualsiasi uso dei nostri servizi di affiliazione dopo la
                registrazione al Programma di Affiliazione costituisce
                implicitamente l'accettazione dei Termini e Condizioni.
            </p>
            <ol>
                <li>
                    La partecipazione al nostro Programma di Affiliazione è
                    soggetta all'accettazione e al rispetto dei presenti Termini
                    e Condizioni. L'iscrizione al programma implica
                    l'accettazione dei suddetti termini.
                </li>
                <li>
                    Gli affiliati guadagnano una commissione del 10% una tantum
                    su tutti gli ordini di prodotti (ove applicabile, ad es.
                    piani di hosting, VPS, ecc.). Questa commissione viene
                    erogata per gli ordini effettuati da account diversi dal
                    proprio account affiliato, completati attraverso il link di
                    referral fornito dall'affiliato.
                </li>
                <li>
                    Le commissioni verranno accreditate nel tuo account solo
                    dopo che l'ordine effettuato dal cliente finale sarà
                    considerato completato e finalizzato.
                </li>
                <li>
                    Forniamo agli affiliati la possibilità di monitorare le loro
                    attività attraverso statistiche che includono il numero di
                    clic ricevuti sui propri link di referral e il numero di
                    utenti che effettuano acquisti tramite tali link.
                </li>
                <li>
                    Le commissioni accumulate possono essere riscattate una
                    volta raggiunta la soglia minima di 25€. Tuttavia, le
                    commissioni non possono essere riscattate come denaro
                    contante o trasferite come bonifici bancari. Possono essere
                    utilizzate come credito per l'acquisto o il rinnovo dei
                    servizi offerti da WeeBo, oppure su richiesta, possono
                    essere convertite in buoni sconto per ulteriori acquisti.
                </li>
                <li>
                    Ci riserviamo il diritto di modificare i Termini e
                    Condizioni del Programma di Affiliazione in qualsiasi
                    momento senza preavviso. Tuttavia, ti informeremo
                    tempestivamente di qualsiasi cambiamento significativo
                    tramite il nostro sito web o via email.
                </li>
                <li>
                    Gli affiliati sono tenuti a rispettare tutte le leggi e i
                    regolamenti applicabili relativi alla promozione dei nostri
                    servizi e al Programma di Affiliazione.
                </li>
            </ol>
            <p>
                Gli affiliati guadagnano una commissione per prodotti e servizi
                soltanto.
            </p>
            <p><b>Non possono guadagnare commissioni su:</b></p>

            <ul>
                <li>Nome di dominio</li>
                <li>Componenti aggiuntivi per prodotti</li>
                <li>Ordini di aggiornamento</li>
                <li>
                    Fatturazione in base all'utilizzo, fatturata a posteriori.
                </li>
            </ul>
            <h3>
                All'attivazione, verrà aggiunta una nuova sezione nel tuo
                account, come mostrato nell'immagine, dedicata al Programma di
                Affiliazione.
            </h3>
            <img
                src="assets/img/affiliate/account-button.png"
                alt="pulsante affiliati account personale"
            />
            <h3>Anteprima della Pagina Account Affiliato</h3>
            <img
                src="assets/img/affiliate/affiliate-account-preview.png"
                alt="affiliate-account-preview"
            />
            <br />
            <br />
            <p>
                Accedendo e partecipando al nostro Programma di Affiliazione,
                confermi di aver letto, compreso e accettato i presenti Termini
                e Condizioni.
            </p>
            <p>
                Per partecipare al nostro Programma di Affiliazione, è
                necessario contattarci tramite i canali di contatto per
                richiedere l'attivazione sul proprio account del programma
                affiliati. L'attivazione verrà completata una volta che avremo
                ricevuto e processato la tua richiesta. L'uso dei servizi
                offerti dal Programma di Affiliazione implica l'accettazione dei
                Termini e Condizioni descritti in questa informativa.
            </p>
            <p style="font-weight: bold">
                Qualsiasi uso improprio dei servizi offerti dal Programma di
                Affiliazione sarà considerato una violazione dei presenti
                Termini e Condizioni. Weebo si riserva il diritto di adottare
                tutte le misure necessarie, comprese azioni legali, per
                affrontare eventuali violazioni della legge vigente. Ci
                riserviamo inoltre il diritto di annullare e contestare
                qualsiasi attività sospetta di uso irregolare del servizio,
                comprese ma non limitate a pratiche fraudolente, manipolazioni o
                abusi del sistema, a nostra discrezione.
            </p>
            <p>
                Per qualsiasi domanda o chiarimento, ti preghiamo di contattarci
                all'indirizzo email:
                <a href="mailto:supporto@weebo.cloud">supporto@weebo.cloud</a>.
            </p>
            <p>Grazie per la tua partecipazione.</p>
            <p>Team WeeBo</p>
        </div>
    </div>
</section>
