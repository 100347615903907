<section class="solutions-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="solutions-wrap">
                    <div class="solutions-title">
                        <h2>Plesk Hosting in Italia</h2>
                        <p>
                            I nostri server sono in Italia, precisamente in
                            Trentino, utilizziamo IP italiani e abbiamo una
                            connessione in fibra ottica dedicata con Aruba
                            S.p.a. Ti offriamo un hosting al 100% italiano.
                        </p>
                        <ul
                            class="solutions-list"
                            style="margin-top: -1em; margin-bottom: 1em"
                        >
                            <li>
                                <i class="flaticon-right"></i> Tempi di accesso
                                più veloci
                            </li>
                            <li>
                                <i class="flaticon-right"></i>Supporto locale in
                                lingua italiana
                            </li>
                            <li>
                                <i class="flaticon-right"></i>Conformità
                                normativa locale
                            </li>
                            <li>
                                <i class="flaticon-right"></i>Minore latenza
                                internazionale
                            </li>
                        </ul>
                        <h3>
                            Perché è importante avere il mio sito web ospitato
                            in Italia?
                        </h3>
                        <p>
                            Perché i tuoi clienti e utenti sono in Italia.
                            Inoltre, più l'hosting del tuo sito web è vicino ai
                            tuoi clienti, più veloce sarà l'esperienza di
                            navigazione. Questo significa tempi di caricamento
                            più rapidi e una migliore esperienza per i tuoi clienti.
                        </p>
                    </div>
                    <a class="default-btn" href="https://weebo.cloud/area-clienti/index.php/store/hosting-condiviso"
                        >Ottieni il tuo hosting</a
                    >
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="solutions-img">
                    <img style="max-width: 70%;" src="assets/img/italian-provider.png" alt="Image">
                </div>
            </div>
        </div>
    </div>
</section>
