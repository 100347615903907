<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Informativa Privacy</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/legal">Legal</a></li>
                        <li>Informativa Privacy</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="terms-conditions ptb-100">
    <div class="container">
        <div class="single-privacy">
            <h2 style="margin-top: 10px">Disclaimer</h2>
            <p><b>Aggiornato il 10/03/24</b></p>

            <!-- MAIN CONTENUTO -->
            <h1>Informativa sulla Privacy</h1>
            <h2 style="color: gray;">
                La tutela della vostra Privacy è uno dei nostri principali
                obiettivi.
            </h2>
            <p>
                WeeBo
                <!-- con sede legale in viale della Giovine Italia
                17, Firenze, CAP 50122 (di seguito “Titolare” o “Società” o
                “WeeBo”) -->
                si impegna costantemente per tutelare la privacy on-line dei
                suoi utenti. Questo documento le permetterà di conoscere la
                nostra politica sulla privacy per capire come le sue
                informazioni personali vengono gestite quando utilizza i nostri
                servizi e per consentirle, se del caso, di prestare un consenso
                al trattamento dei suoi dati personali espresso e consapevole
                nelle sezioni del sito ove viene richiesto di fornire i dati
                personali. Le ricordiamo che nelle varie sezioni dei siti web
                WeeBo (di seguito "Sito") ove raccogliamo i suoi dati personali
                potrebbero essere pubblicate specifiche informative ai sensi
                dell'art. 13 del Regolamento EU 2016/679 (di seguito:
                "Regolamento") per sua necessaria presa visione prima della
                fornitura dei dati richiesti. Le informazioni ed i dati da lei
                forniti o altrimenti acquisiti nell'ambito della registrazione
                ai vari servizi di WeeBo (come ad esempio: registrazione di nomi
                a dominio, fornitura account email, fornitura account di Posta
                Elettronica Certificata (PEC), fornitura spazio web, fornitura
                servizi di hosting, fornitura di altri servizi accessori, di
                seguito, complessivamente, "Servizi") saranno oggetto di
                trattamento nel rispetto delle disposizioni del Regolamento e
                degli obblighi di riservatezza che ispirano l'attività di WeeBo.
                Secondo le norme del Regolamento e del D. Lgs. 196/2003 e
                successive modifiche (“Codice Privacy”), i trattamenti
                effettuati da WeeBo saranno improntati ai principi di liceità,
                correttezza, trasparenza, limitazione delle finalità e della
                conservazione, minimizzazione dei dati, esattezza, integrità e
                riservatezza.
            </p>
            <!-- FINE MAIN CONTENUTO -->

            <!-- CONTENUTO -->
            <h3>I dati personali oggetto del trattamento</h3>
            <p>
                Per trattamento di dati personali intendiamo qualsiasi
                operazione o insieme di operazioni, compiute con o senza
                l'ausilio di processi automatizzati e applicate a dati personali
                o insiemi di dati personali, come la raccolta, la registrazione,
                l'organizzazione, la strutturazione, la conservazione,
                l'adattamento o la modifica, l'estrazione, la consultazione,
                l'uso, la comunicazione mediante trasmissione, diffusione o
                qualsiasi altra forma di messa a disposizione, il raffronto o
                l'interconnessione, la limitazione, la cancellazione o la
                distruzione. La informiamo che i dati personali oggetto di
                trattamento saranno costituiti - anche a seconda delle sue
                decisioni su come utilizzare i Servizi - da un identificativo
                come il nome, l'indirizzo e-mail, un numero di identificazione,
                dati relativi all'ubicazione, un identificativo online, gli
                acquisti effettuati, e altri dati idonei a renderla
                identificato/a o identificabile, a seconda del tipo di Servizi
                richiesti (di seguito solo “Dati Personali”). In particolare, i
                Dati Personali trattati attraverso il Sito sono i seguenti:
            </p>
            <ol type="a">
                <li>
                    <b>Dati di navigazione:</b> I sistemi informatici e le
                    procedure software preposte al funzionamento del Sito
                    acquisiscono, nel corso del loro normale esercizio, alcuni
                    Dati Personali la cui trasmissione è implicita nell'uso dei
                    protocolli di comunicazione di Internet. Si tratta di
                    informazioni che non sono raccolte per essere associate a
                    interessati identificati, ma che per loro stessa natura
                    potrebbero, attraverso elaborazioni ed associazioni con dati
                    detenuti da terzi, permettere di identificare gli utenti. In
                    questa categoria di dati rientrano gli indirizzi IP o i nomi
                    a dominio dei computer utilizzati dagli utenti che si
                    connettono al Sito, gli indirizzi in notazione URI (Uniform
                    Resource Identifier) delle risorse richieste, l'orario della
                    richiesta, il metodo utilizzato nel sottoporre la richiesta
                    al server, la dimensione del file ottenuto in risposta, il
                    codice numerico indicante lo stato della risposta data dal
                    server (buon fine, errore, etc.) ed altri parametri relativi
                    al sistema operativo e all'ambiente informatico dell'utente.
                    Questi dati vengono utilizzati al solo fine di ricavare
                    informazioni statistiche anonime sull'uso del Sito e dei
                    siti dei nostri clienti e per controllarne il corretto
                    funzionamento, per identificare anomalie e/o abusi, e
                    vengono cancellati immediatamente dopo l'elaborazione. I
                    dati potrebbero essere utilizzati per l'accertamento di
                    responsabilità in caso di ipotetici reati informatici ai
                    danni del Sito o di terzi: salva questa eventualità, allo
                    stato i dati sui contatti web non persistono per più di
                    quattordici giorni, a meno di eventuali richieste
                    dell'utente (es: accesso alle pagine personali dell'utente
                    all'interno di WeeBo.cloud che riepilogano i servizi fruiti,
                    le informazioni pubblicate, etc.).
                </li>
                <li>
                    <b
                        >Dati trattati nell'ambito dell'interazione con i social
                        media:</b
                    >
                    Oltre che compilando l'apposito form di registrazione, lei
                    può registrarsi ai Servizi, se ha un profilo Google, anche
                    cliccando semplicemente sul bottone "Login con Google". In
                    questo caso, Google invierà automaticamente a WeeBo alcuni
                    suoi Dati Personali, specificati nell'apposita finestra
                    "pop-up" che viene visualizzata al momento della richiesta,
                    e non vi sarà necessità di compilare altri form da parte
                    sua. Se invece lei è già un utente registrato di WeeBo, ed
                    ha anche un profilo Google, può scegliere di associare il
                    suo account WeeBo al suo account Google cliccando su "Login
                    con Google" e successivamente su "Associa account": in tal
                    modo, il suo codice identificativo WeeBo verrà associato al
                    suo codice utente di Google, e successivamente lei potrà con
                    un semplice click su "Login con Google" autenticarsi
                    direttamente al suo pannello di controllo WeeBo senza
                    inserire le credenziali. Con le stesse modalità, WeeBo le dà
                    la possibilità di associare il suo account WeeBo anche ai
                    suoi eventuali account Google, Twitter e Linkedin. Anche in
                    questi casi i siti dei social network interessati invieranno
                    alcuni suoi Dati Personali a WeeBo, specificati
                    nell'apposita finestra "pop-up" che viene visualizzata al
                    momento della richiesta.
                </li>
                <li>
                    <b>Dati forniti volontariamente dall'interessato:</b>
                    Nell'utilizzo di particolari Servizi (ad esempio nell'ambito
                    dello scambio di e-mail o messaggi con gli operatori del
                    nostro Customer Care) potrebbe verificarsi un trattamento
                    dei Dati Personali di terzi soggetti da lei inviati al
                    gestore del Servizio. Rispetto a tali ipotesi, lei si pone
                    come autonomo titolare del trattamento, assumendosi tutti
                    gli obblighi e le responsabilità di legge. In tal senso, lei
                    conferisce sul punto la più ampia manleva rispetto ad ogni
                    contestazione, pretesa, richiesta di risarcimento del danno
                    da trattamento, etc. che dovesse pervenire a WeeBo da terzi
                    soggetti i cui Dati Personali siano stati trattati
                    attraverso il suo utilizzo dei Servizi in violazione delle
                    norme sulla tutela dei dati personali applicabili. In ogni
                    caso, qualora lei fornisca o in altro modo tratti Dati
                    Personali di terzi nell'utilizzo del Servizio, garantisce
                    fin da ora - assumendosene ogni connessa responsabilità -
                    che tale particolare ipotesi di trattamento si fonda su
                    un'idonea base giuridica (ad esempio, il consenso
                    dell'interessato) ai sensi dell'art. 6 del Regolamento che
                    legittima il trattamento delle informazioni in questione.
                </li>
                <li>
                    <b>Dati di registrazione di nomi a dominio:</b> Per
                    informazioni specifiche sulle attività di trattamento di
                    dati personali effettuate da WeeBo in qualità di titolare
                    del trattamento in ambito registrazione di nomi a dominio,
                    si rinvia alla “Informativa Privacy - Registrazione Nomi a
                    Dominio”, disponibile al link:
                    <a style="color: red"
                        >https://www.weebo.cloud/legal/informativa-privacy-registrazione-nomi-a-dominio/</a
                    >.
                </li>
                <li>
                    <b>Dati di traffico:</b> Nell'ambito del servizio di posta
                    elettronica, WeeBo tratta alcuni dati ai fini della
                    trasmissione delle comunicazioni sulla rete di comunicazione
                    elettronica. Tali dati sono quelli elencati nel Decreto
                    Legislativo 30 maggio 2008, n. 109, e segnatamente sono:
                    <ol type="i">
                        <li>
                            indirizzo IP utilizzato e indirizzo di posta
                            elettronica ed eventuale ulteriore identificativo
                            del mittente;
                        </li>
                        <li>
                            indirizzo IP e nome a dominio pienamente qualificato
                            del mail exchanger host, nel caso della tecnologia
                            SMTP ovvero di qualsiasi tipologia di host relativo
                            ad una diversa tecnologia utilizzata per la
                            trasmissione della comunicazione;
                        </li>
                        <li>
                            indirizzo di posta elettronica, ed eventuale
                            ulteriore identificativo, del destinatario della
                            comunicazione;
                        </li>
                        <li>
                            indirizzo IP e nome a dominio pienamente qualificato
                            del mail exchanger host (nel caso della tecnologia
                            SMTP), ovvero di qualsiasi tipologia di host
                            (relativamente ad una diversa tecnologia
                            utilizzata), che ha provveduto alla consegna del
                            messaggio;
                        </li>
                        <li>
                            indirizzo IP utilizzato per la ricezione ovvero la
                            consultazione dei messaggi di posta elettronica da
                            parte del destinatario indipendentemente dalla
                            tecnologia o dal protocollo utilizzato;
                        </li>
                        <li>
                            data e ora (GMT) della connessione e della
                            disconnessione dell'utente del servizio di posta
                            elettronica su internet ed indirizzo IP utilizzato,
                            indipendentemente dalla tecnologia e dal protocollo
                            impiegato;
                        </li>
                        <li>il servizio internet utilizzato.</li>
                    </ol>
                    Tali dati sono trattati e conservati da WeeBo per fornire il
                    servizio e per obbligo di legge - segnatamente, per finalità
                    di accertamento e repressione dei reati e con stringenti
                    misure di sicurezza che li rendono accessibili solo ad
                    incaricati appositamente autorizzati per iscritto, i quali
                    vi accedono solo a seguito di richiesta dell'autorità
                    giudiziaria corredata da un decreto motivato di un pubblico
                    ministero o richiesta del difensore dell'imputato e comunque
                    con tecniche di autenticazione particolarmente sofisticate
                    previste dalla normativa. Per obbligo di legge, i dati sono
                    conservati da WeeBo per finalità di accertamento e
                    repressione dei reati per sei anni dalla loro generazione. I
                    dati sono inoltre trattati da WeeBo per ordinarie
                    elaborazioni aziendali legate alla fornitura del servizio
                    (es.: a fini di documentazione in caso di contestazione
                    della fattura o pretesa di pagamento, per accertamento di
                    frodi, per eseguire analisi per conto dei clienti), in virtù
                    di quanto previsto dalla normativa. In questo caso, i dati
                    sono conservati, con stringenti misure di sicurezza
                    applicate a norma di legge, per sei mesi dalla loro
                    generazione, e successivamente cancellati.
                </li>
                <li>
                    <b>Cookie:</b> Informazioni dettagliate sulla
                    <a href="https://weebo.cloud/#/legal/cookie"
                        >cookie policy</a
                    >
                    sono disponibili nella pagina corrispondente.
                </li>
                <li>
                    <b>Dati trattati nell'ambito del servizio PEC:</b> Per
                    informazioni specifiche sulle attività di trattamento di
                    dati personali effettuate da WeeBo in qualità di titolare
                    del trattamento in ambito PEC, si rinvia alla “Informativa
                    sul trattamento dei dati personali resa ai sensi dell'art.
                    13 del Regolamento UE 2016/679 (“GDPR”), con riferimento al
                    servizio PEC di WeeBo”, disponibile al link:
                    <a style="color: red"
                        >https://weebo.cloud/legal/informativa-privacy-pec/</a
                    >
                </li>
                <li>
                    <b>Dati trattati nell'ambito del servizio SPID:</b> Per
                    informazioni specifiche sulle attività di trattamento di
                    dati personali effettuate da WeeBo in qualità di titolare
                    del trattamento in ambito SPID, si rinvia alla “Informativa
                    privacy SpidItalia”, disponibile al link:
                    https://wweebo.cloud/assistenza/manuali-spid/.
                </li>
            </ol>

            <h3>
                Finalità del trattamento, base legale e natura obbligatoria o
                facoltativa del trattamento
            </h3>
            <p>
                Il trattamento che intendiamo effettuare, dietro suo specifico
                consenso ove necessario, ha le seguenti finalità:
            </p>
            <ol type="a">
                <li>
                    Consentire l'erogazione dei Servizi da lei richiesti e la
                    successiva ed autonoma gestione del suo pannello di
                    controllo, cui accederà mediante la registrazione e la
                    creazione del suo profilo utente all'atto della fornitura
                    dei Servizi, inclusa la raccolta, la conservazione e la
                    elaborazione dei dati ai fini dell'instaurazione e della
                    successiva gestione operativa, tecnica ed amministrativa del
                    rapporto connesso all'erogazione dei Servizi e
                    l'effettuazione di comunicazioni relative allo svolgimento
                    del rapporto instaurato;
                </li>
                <li>
                    consentire la navigazione e la consultazione dei siti web di
                    WeeBo;
                </li>
                <li>
                    rispondere a richieste di assistenza o di informazioni, che
                    riceveremo via e-mail, telefono o chat attraverso la pagina
                    "Contattaci" del nostro Sito, o tramite l'apposito form
                    "Segnalazioni privacy" raggiungibile dalla pagina
                    "Contattaci" del Sito, oppure tramite clic sul pulsante
                    “CONTATTACI SU WHATSAPP” presente alla pagina
                    <a style="color: red"
                        >"https://WeeBo.cloud/siti-web/realizzazione-siti/sito-evoluto/"</a
                    >, finalizzato a consentire agli utenti che richiedano la
                    realizzazione del proprio sito web di interagire con
                    operatori di WeeBo. Con particolare riferimento alle
                    risposte a richieste di assistenza che pervengano a WeeBo
                    tramite il telefono, la informiamo che le chiamate saranno
                    registrate affinché WeeBo possa dimostrare di avere
                    correttamente evaso le richieste. Con riferimento alle
                    richieste che pervengano a WeeBo tramite posta elettronica,
                    anch'esse, unitamente ai relativi riscontri, saranno
                    conservate per il tempo necessario a garantire la corretta
                    evasione delle richieste, nonché, successivamente, per
                    consentire a WeeBo di difendersi in giudizio, ove
                    necessario. Le richieste pervenute tramite clic sul pulsante
                    “CONTATTACI SU WHATSAPP” presente alla pagina
                    <a style="color: red"
                        >"https://WeeBo.cloud/siti-web/realizzazione-siti/sito-evoluto/"</a
                    >
                    saranno gestite mediante l'utilizzo dell'applicativo
                    WhatsApp, la cui privacy policy è disponibile a questo
                    indirizzo:
                    <a href="https://www.whatsapp.com/legal/privacy-policy-eea"
                        >https://www.whatsapp.com/legal/privacy-policy-eea</a
                    >. La base legale del trattamento di Dati Personali per le
                    finalità di cui alle lettere (a), (b) e (c) che precedono è
                    l'art. 6(1)(b) del Regolamento in quanto i trattamenti sono
                    necessari all'erogazione dei servizi contrattualizzati. Il
                    conferimento dei Dati Personali per queste finalità è
                    facoltativo ma l'eventuale mancato conferimento
                    comporterebbe l'impossibilità di attivare i Servizi
                    richiesti. Con specifico riferimento alla registrazione
                    delle telefonate invece, la base legale del trattamento
                    risiede nell'art. 6 (1)(f) del Regolamento, in quanto WeeBo
                    ha un legittimo interesse a voler garantire un servizio
                    efficace e coerente, e a perseguire il miglioramento del
                    servizio stesso, e secondo un bilanciamento effettuato dal
                    Titolare, tale interesse non lede i diritti e le libertà
                    fondamentali degli interessati anche in considerazione delle
                    misure di sicurezza applicate alle registrazioni. Qualora
                    lei non desideri che questo trattamento abbia luogo, la
                    invitiamo a rivolgere solo richieste scritte al nostro
                    servizio clienti, evitando l'uso del telefono. La
                    conservazione dei dati necessari a provare di avere
                    correttamente erogato i servizi e risposto alle richieste,
                    si basa sul legittimo interesse della Società a poter
                    esercitare il proprio diritto di difesa in giudizio, ove
                    necessario.
                </li>
                <li>
                    assolvere obblighi di legge, contabili e fiscali: questo
                    trattamento risulta legittimo ai sensi dell'art. 6(1)(c) del
                    Regolamento. Una volta conferiti i Dati Personali, il
                    trattamento può essere invero necessario per adempiere ad
                    obblighi di legge a cui WeeBo è soggetta; non è possibile
                    opporsi a questo trattamento, trattandosi di un trattamento
                    derivante da obblighi di legge;
                </li>
                <li>
                    svolgere marketing diretto via e-mail per servizi analoghi a
                    quelli da lei sottoscritti, a meno che lei non si sia
                    opposto a tale trattamento inizialmente o in occasione di
                    successive comunicazioni, per il perseguimento del legittimo
                    interesse di WeeBo a promuovere prodotti o servizi a cui lei
                    può ragionevolmente essere interessato; questo trattamento
                    trova il proprio presupposto di liceità nell'art. 130 co. 4
                    del Codice Privacy: “se il titolare del trattamento
                    utilizza, a fini di vendita diretta di propri prodotti o
                    servizi, le coordinate di posta elettronica fornite
                    dall'interessato nel contesto della vendita di un prodotto o
                    di un servizio, può non richiedere il consenso
                    dell'interessato, sempre che si tratti di servizi analoghi a
                    quelli oggetto della vendita e l'interessato, adeguatamente
                    informato, non rifiuti tale uso, inizialmente o in occasione
                    di successive comunicazioni. L'interessato, al momento della
                    raccolta e in occasione dell'invio di ogni comunicazione
                    effettuata per le finalità di cui al presente comma, è
                    informato della possibilità di opporsi in ogni momento al
                    trattamento, in maniera agevole e gratuitamente”;
                    l'opposizione a questo trattamento non ha alcuna conseguenza
                    sulla fruizione dei servizi;
                </li>
                <li>
                    elaborare studi, ricerche, statistiche di mercato; inviarle
                    materiale pubblicitario, informativo, informazioni
                    commerciali o sondaggi per migliorare il servizio ("customer
                    satisfaction") via e-mail o via sms, e/o attraverso l'uso
                    del telefono con operatore e/o attraverso le pagine
                    ufficiali di WeeBo sui social network o ancora attraverso il
                    suo pannello di controllo, qualora lei sia un cliente di
                    WeeBo; inoltre, se lei è un utente di Google potrebbe
                    visualizzare banner pubblicitari di WeeBo sul suo profilo
                    Google(di seguito si farà riferimento alle attività qui
                    elencate come alle attività di “Marketing”): il trattamento
                    dei suoi dati per finalità di Marketing si basa sul rilascio
                    del suo consenso ai sensi dell'art. 6(1)(a) del Regolamento.
                    Lei può opporsi al trattamento dei suoi dati per finalità di
                    Marketing tramite il suo pannello di controllo, oppure
                    inviando una richiesta da qui o ancora attraverso il
                    meccanismo proposto nel footer delle e-mail commerciali, o
                    ancora scrivendo a
                    <a href="mailto:supporto@weebo.cloud">supporto@weebo.cloud</a
                    >. L'opposizione a questo trattamento non ha alcuna
                    conseguenza sulla fruizione dei servizi. Di tanto in tanto,
                    WeeBo pone in essere specifiche attività di Marketing
                    congiunto assieme ad altre società del Gruppo, in qualità di
                    contitolari del trattamento con WeeBo; in particolare:
                </li>
                <ol type="i">
                    <!-- <li>
                        invio di materiale pubblicitario personalizzato via
                        e-mail, congiuntamente con la società Iubenda S.r.l.,
                        con sede legale in via San Raffaele, 1 – 20121 Milano
                        (“iubenda”). L’invio di materiale pubblicitario riguarda
                        i servizi commercializzati da iubenda. In particolare,
                        l’attività consiste nel caricamento di una lista di
                        domini relativi a clienti di WeeBo, associati al
                        relativo indirizzo e-mail, in una piattaforma gestita da
                        iubenda, sulla base del legittimo interesse di WeeBo
                        all’effettuazione di attività di marketing congiunto con
                        le altre società del Gruppo team.blue. Successivamente,
                        iubenda esegue una scansione dei domini al fine di
                        rilevare eventuali non-conformità del sito web rispetto
                        alla normativa privacy e, nel caso in cui vengano
                        rilevate una o più non-conformità, procede ad associare
                        il risultato della scansione con gli indirizzi e-mail
                        caricati sulla piattaforma, sulla base del legittimo
                        interesse di iubenda all’effettuazione di attività di
                        marketing congiunto con le altre società del Gruppo. La
                        presente attività verrà svolta soltanto laddove lei
                        abbia fornito in precedenza il proprio consenso
                        all’effettuazione di attività di Marketing nei confronti
                        di WeeBo; pertanto, la base giuridica del trattamento
                        è anche in questo caso il suo consenso, ai sensi
                        dell’art. 6(1)(a) del Regolamento. WeeBo ha
                        concordato con iubenda di ricoprire il ruolo di punto di
                        contatto principale per l’esercizio dei suoi diritti:
                        pertanto, lei può opporsi a questo trattamento nelle
                        medesime modalità sopra indicate in relazione alle
                        attività di Marketing e può in ogni caso esercitare i
                        diritti indicati al paragrafo 7 che segue scrivendo
                        all’indirizzo dpo(chiocciola)WeeBo.it.
                    </li> -->
                    <li>
                        solo con riferimento a determinati servizi, i dati
                        potranno essere trattati per finalità di comunicazione a
                        terze parti per loro finalità di marketing, vale a dire
                        per fornirle informazioni e/o formulare offerte su
                        prodotti, servizi o iniziative offerti o promossi da
                        altre società facenti parte del Gruppo e/o da sue
                        società affiliate e/o controllate, e/o da altri partner
                        commerciali e outsourcer che agiscono come autonomi
                        titolari del trattamento: anche questo trattamento si
                        basa sul rilascio del suo consenso ai sensi dell'art.
                        6(1)(a) del Regolamento, consenso che le verrà
                        eventualmente richiesto appositamente ed il cui rifiuto
                        non ha alcuna conseguenza sulla fruizione dei servizi;
                    </li>
                    <li>
                        farle proposte commerciali personalizzate sulla base dei
                        prodotti o servizi da lei acquistati, o di cui si è
                        interessato navigando sul nostro Sito dopo esservisi
                        registrato, o sulla base del gruppo di clienti cui lei
                        appartiene (“Profilazione”). Ciò significa, ad esempio,
                        che se lei è un rivenditore riceverà offerte commerciali
                        destinate ai rivenditori; se lei ha navigato sul nostro
                        Sito per cercare informazioni su determinati servizi,
                        riceverà offerte relative a tali servizi; infine, se lei
                        sta usufruendo di un servizio, in base al suo uso del
                        servizio riceverà comunicazioni pertinenti da parte di
                        WeeBo (ad esempio, nel caso in cui la capacità del
                        servizio stia raggiungendo il limite, verrà avvisato e
                        invitato ad aumentare la capacità del servizio medesimo;
                        oppure, le verranno proposti servizi complementari e/o
                        compatibili con quello da lei acquistato). Questo tipo
                        di analisi viene tipicamente svolta, senza l'uso di
                        cookie, su dati di acquisto afferenti sia a persone
                        fisiche che a persone giuridiche, e le relative
                        decisioni non sono basate su un trattamento unicamente
                        automatizzato. Tale trattamento è necessario per il
                        perseguimento del legittimo interesse del titolare del
                        trattamento a personalizzare le proprie proposte
                        commerciali, ai sensi dell'art. 6(1)(f) del Regolamento,
                        e sulla base di un bilanciamento di interessi eseguito
                        dal Titolare a seguito del quale riteniamo che
                        l'interesse di WeeBo ad eseguire questo trattamento non
                        sia lesivo dei suoi diritti e libertà fondamentali. Lei
                        può sempre opporsi a questo trattamento tramite il form
                        "Segnalazioni privacy" o scrivendo a
                        <a href="mailto:supporto@weebo.cloud"
                            >supporto@weebo.cloud</a
                        >, adducendo motivazioni legate ad una sua situazione
                        particolare; inoltre, se lei è un utente di Google potrà
                        ricevere sulla sua pagina Googlemessaggi promozionali
                        personalizzati (ad esempio, se lei ha un'azienda e una
                        pagina aziendale su Google, potrebbe visualizzare
                        messaggi promozionali di WeeBo sulla sua pagina adeguati
                        a quelle che potrebbero essere le sue esigenze), sulla
                        base dell'utilizzo da parte di WeeBo delle funzionalità
                        di targeting messe a disposizione da Google; ad esempio,
                        WeeBo utilizza tali funzionalità al fine di
                        sponsorizzare i propri post e contenuti promozionali al
                        fine di portarli all'attenzione di determinati segmenti
                        di utenti Google (ad es., persone tra i 30 e i 40 anni
                        interessate ai prodotti informatici e residenti in una
                        determinata area geografica); si precisa che l'attività
                        di profilazione è effettuata da Google e che WeeBo non
                        ha accesso ai dati in chiaro. Per maggiori informazioni,
                        è possibile consultare la privacy policy di Google al
                        seguente link:
                        <a
                            href="https://www.
                        Google.com/policy.php"
                            >https://www. Google.com/policy.php</a
                        >
                        Nell'ambito di queste attività, WeeBo è contitolare del
                        trattamento assieme a Google Inc.: il relativo accordo
                        di contitolarità è disponibile alla pagina
                        <a
                            href="https://www.
                        Google.com/legal/terms/page_controller_addendum"
                            >https://www.
                            Google.com/legal/terms/page_controller_addendum</a
                        >
                    </li>
                    <li>
                        per esclusive finalità di sicurezza e prevenzione di
                        condotte fraudolente, sulla base di un legittimo
                        interesse di WeeBo a prevenire frodi e truffe poste in
                        essere a suo danno o a danno dei propri clienti, ai
                        sensi dell'art. 6(1)(f) del Regolamento e sulla base sia
                        del Considerando 47 del Regolamento, che prevede
                        espressamente che costituisce [...] legittimo interesse
                        del titolare del trattamento interessato trattare dati
                        personali strettamente necessari a fini di prevenzione
                        delle frodi, nonché sulla base di diversi bilanciamenti
                        di interessi svolti dal titolare da cui non risulta che
                        i trattamenti in questione siano lesivi dei suoi diritti
                        e libertà fondamentali. In particolare, le attività di
                        questo tipo comprendono:
                    </li>
                    <ol type="i">
                        <li>
                            un sistema di controllo automatico, posto in essere
                            anche tramite l'utilizzo di cookie e altri strumenti
                            di tracciamento similari (come meglio specificato
                            nella sezione 2.f che precede), che comporta il
                            rilevamento e l'analisi di alcuni comportamenti
                            degli utenti sul Sito, associati ai loro indirizzi
                            IP e ad altri Dati Personali abbinati alla
                            navigazione. Le conseguenze di tale trattamento sono
                            che qualora un soggetto tenti di porre in essere
                            condotte fraudolente sul Sito, ad esempio per
                            beneficiare più volte della medesima promozione
                            senza averne diritto, WeeBo si riserva il diritto di
                            escludere tale soggetto dalla promozione oppure di
                            adottare ogni altra opportuna misura a propria
                            tutela;
                        </li>
                        <li>
                            antispam: WeeBo si riserva di non attivare o
                            bloccare gli account e-mail o gli hosting dei propri
                            clienti da cui risultano originare invii di spam o
                            phishing. Ove possibile, WeeBo procederà a
                            verificare l'identità del cliente al fine di
                            effettuare gli accertamenti del caso e, soltanto in
                            caso di mancata risposta o di invio di documenti
                            falsi, porrà in essere il blocco. Questa attività
                            comporta il trattamento di dati personali quali
                            indirizzi e-mail, copia del documento di identità,
                            foto, e dati di traffico, trovando la sua base
                            giuridica nei Considerando 47 e 49 del Regolamento,
                            che richiamano il legittimo interesse del Titolare;
                        </li>
                        <li>
                            verifiche sull'uso dell'hosting: se viene rilevato
                            un incremento eccezionale dell'uso dello spazio
                            disco utilizzato dal cliente, la Società,
                            limitandosi a verifiche esteriori che non hanno
                            impatto sui Dati Personali del cliente né
                            individuano quali contenuti siano stati caricati,
                            tenta di individuare se vi siano usi illeciti del
                            servizio (o da un punto di vista contrattuale o da
                            un punto di vista normativo), al fine di avvertire
                            il cliente, tutelare i propri diritti e garantire la
                            sicurezza dei propri sistemi;
                        </li>
                        <li style="color: red">
                            un sistema di verifica antifrode in tempo reale dei
                            pagamenti, nel momento in cui viene effettuato un
                            acquisto sul Sito: questo Trattamento consiste nella
                            comunicazione di alcuni Dati Personali (nello
                            specifico nome, cognome, indirizzo postale,
                            indirizzo IP, numero di telefono, indirizzo e-mail,
                            identificativo dell'istituto bancario, contenuto del
                            carrello e informazioni “user agent”, ossia browser
                            e lingua impostata) alla società specializzata in
                            servizi antifrode MaxMind Inc., la quale attribuisce
                            alla transazione un punteggio di fraudolenza sulla
                            base dell'elaborazione automatizzata dei Dati
                            Personali comunicati dalla Società. L'attribuzione
                            del punteggio viene effettuata mediante un algoritmo
                            di machine learning che formula una valutazione del
                            rischio di fraudolenza di ciascuna transazione;
                            ulteriori dettagli sul funzionamento dell'algoritmo
                            sono disponibili qui. In base al valore del
                            punteggio attribuito, qualora questo superi una
                            prima soglia, la transazione viene accettata, ma
                            segnalata internamente come “sospetta” e sottoposta
                            a un controllo interno da parte di personale
                            autorizzato, il quale procederà se opportuno ad
                            attivare manualmente i servizi richiesti. Nei casi
                            in cui il punteggio superi una seconda e più elevata
                            soglia, la transazione viene automaticamente
                            rifiutata. Entrambe le soglie sono impostate
                            manualmente dalla Società in base a un campione di
                            casi accertati come sospetti o fraudolenti. In ogni
                            caso, la Società riesamina periodicamente il calcolo
                            della soglia al fine di evitare falsi positivi. Nel
                            caso in cui una transazione venisse rifiutata,
                            questa viene successivamente verificata dagli
                            operatori della Società. Inoltre, in tale caso le
                            saranno indicati i mezzi per contattare
                            l'assistenza, così che lei possa contestare la
                            decisione. La informativa privacy di MaxMind Inc. è
                            disponibile cliccando qui.
                        </li>
                        <li>
                            un'attività di attività di profilazione che WeeBo
                            svolge al fine di oscurare le proprie offerte ai
                            clienti che si colleghino da e/o fatturino in paesi
                            extra UE, e più in particolare da paesi previsti in
                            una “Black List” stilata da WeeBo sulla base di
                            criteri dedotti da analisi antifrode. Qualora lei
                            non possa accedere alle offerte per le ragioni di
                            cui sopra, è sempre possibile rivolgersi al supporto
                            di WeeBo al fine di superare tale blocco.
                        </li>
                        <li>
                            un'attività di verifica sull'identità dei propri
                            clienti mediante richiesta di foto o altro documento
                            idoneo a tale accertamento. Tale attività viene
                            svolta nei seguenti casi: i) ove lei acquisti per la
                            prima volta uno o più server (dedicato o virtuale),
                            ii) nel caso in cui la Società riceva segnalazioni
                            relative a violazione di diritti di proprietà
                            industriale o intellettuale da parte di terzi, o
                            laddove tali violazioni siano riscontrate
                            autonomamente da WeeBo, iii) il cliente viene
                            identificato come fraudolento dal sistema di
                            controllo automatico di cui alla sezione 3(i)(i) che
                            precede.
                        </li>
                        <li>
                            visualizzazione di contenuti personalizzati e
                            presentazione di offerte personalizzate sul Sito
                            tramite l'utilizzo di cookie di profilazione e altri
                            strumenti di tracciamento (“Marketing Automation”).
                            Ciò significa, ad esempio, che se lei naviga sul
                            nostro Sito per cercare informazioni su determinati
                            servizi offerti dalla Società, le verranno
                            presentate sul Sito offerte relative a tali servizi.
                            Tale attività viene svolta tramite l'utilizzo di
                            cookie proprietari di prima parte sviluppati e
                            serviti dalla Società e si basa sul rilascio del suo
                            consenso manifestato cliccando su “Accetta”
                            all'interno del banner cookie presentato al momento
                            del suo approdo sul Sito. Lei può revocare il suo
                            consenso all'utilizzo di tali cookie in qualsiasi
                            momento seguendo le istruzioni fornite all'interno
                            della cookie policy, oppure gestendo le impostazioni
                            sui cookie presenti nel suo browser di navigazione.
                        </li>
                        <li>
                            attività di analisi mediante tracciamento tramite
                            l'utilizzo di cookie e altri strumenti similari,
                            finalizzate a verificare e misurare la qualità e
                            l'efficacia delle campagne pubblicitarie online di
                            WeeBo (ad es., quanti utenti che sono approdati sul
                            sito di WeeBo e hanno acquistato Servizi provenivano
                            effettivamente da campagne di marketing effettuate
                            tramite Google, Google e così via), al fine di
                            migliorare le prestazioni di tali campagne, nonché
                            dei Servizi offerti da WeeBo. La base giuridica di
                            tale trattamento è il legittimo interesse della
                            Società a migliorare le proprie campagne e Servizi,
                            ai sensi dell'art. 6 (1) (f) del Regolamento.
                        </li>
                    </ol>
                </ol>
            </ol>
            <h3>Destinatari dei dati personali</h3>
            <p>
                I suoi Dati Personali potranno essere condivisi, per le finalità
                di cui alla sezione 3 più sopra, con i seguenti soggetti
                (collettivamente, “Destinatari”):
            </p>
            <ol type="a">
                <li>
                    soggetti che agiscono tipicamente in qualità di responsabili
                    del trattamento per conto di WeeBo, quali:
                    <ol type="i">
                        <li>
                            persone, società o studi professionali che prestano
                            attività di assistenza e consulenza a WeeBo in
                            materia contabile, amministrativa, legale,
                            tributaria, finanziaria e di recupero crediti
                            relativamente alla erogazione dei Servizi,
                        </li>
                        <li>
                            soggetti con i quali sia necessario interagire per
                            l'erogazione dei Servizi e che agiscono tipicamente
                            in qualità di autonomi titolari ad esempio i
                            soggetti che forniscono il servizio di pagamento
                            tramite carta di credito (Stripe, etc.),
                        </li>
                        <li>
                            ovvero soggetti delegati a svolgere attività di
                            manutenzione tecnica (inclusa la manutenzione degli
                            apparati di rete e delle reti di comunicazione
                            elettronica) che agiscono tipicamente in qualità di
                            responsabili del trattamento,
                        </li>
                        <!-- <li>MaxMind Inc., società specializzata in servizi antifrode che
                                        agisce in parte come autonomo titolare e in parte come
                                        responsabile del trattamento per conto della Società;</li> -->
                    </ol>
                </li>
                <li>
                    soggetti, enti od autorità, che agiscono tipicamente in
                    qualità di autonomi titolari del trattamento, a cui sia
                    obbligatorio comunicare i suoi dati personali in forza di
                    disposizioni di legge o di ordini delle autorità (ad
                    esempio, nel corso di indagini di natura penale WeeBo può
                    ricevere richieste da parte dell'autorità giudiziaria di
                    fornire log di traffico telematico);
                </li>
                <li>
                    persone autorizzate da WeeBo al trattamento di Dati
                    Personali necessario a svolgere attività strettamente
                    correlate all'erogazione dei Servizi, che si siano impegnate
                    alla riservatezza o abbiano un adeguato obbligo legale di
                    riservatezza, quali i dipendenti di WeeBo;
                </li>
                <li>
                    partner commerciali, che agiscono tipicamente in qualità di
                    autonomi titolari del trattamento per loro finalità autonome
                    e distinte, solo in caso lei abbia prestato uno specifico
                    consenso;
                </li>
                <li>
                    società di revisione contabile, che agiscono tipicamente in
                    qualità di autonomi titolari del trattamento;
                </li>
                <li>
                    altre società del Gruppo, di cui WeeBo fa parte. Il Gruppo,
                    composto da diversi brand e società controllate, può
                    migliorare il coordinamento e l'allocazione delle risorse
                    condividendo i dati internamente. Ciò consente una
                    collaborazione più efficiente sui miglioramenti dei
                    prodotti, delle campagne e del servizio clienti. I Dati
                    Personali possono essere condivisi tra società appartenenti
                    al Gruppo per la produzione di statistiche di marketing, per
                    finalità di amministrazione interna e per finalità di
                    reporting, ma solo nella quantità necessaria per la finalità
                    prevista e sulla base di adeguate misure di sicurezza per
                    impedire l'accesso o la divulgazione non autorizzati.
                </li>
            </ol>

            <h3>Trasferimenti dei dati personali</h3>
            <p>
                Alcuni dei suoi Dati Personali sono condivisi con Destinatari
                che si potrebbero trovare al di fuori dello Spazio Economico
                Europeo. WeeBo assicura che il trattamento suoi Dati Personali
                da parte di questi Destinatari avviene nel rispetto del
                Regolamento. Invero, i trasferimenti si possono basare su una
                decisione di adeguatezza o sulle Standard Contractual Clauses
                approvate dalla Commissione Europea.
            </p>

            <h3>Conservazione dei dati</h3>
            <p>
                I Dati Personali trattati per le finalità di cui alla sezione
                3(a-b-c) saranno conservati per il tempo strettamente necessario
                a raggiungere le indicate finalità. Inoltre, trattandosi di
                trattamenti svolti per la fornitura di Servizi, WeeBo tratterà i
                Dati Personali fino al tempo permesso dalla normativa Italiana a
                tutela dei propri interessi (Art. 2946 c.c. e ss.), in
                particolare conserverà i dati necessari a dimostrare di avere
                correttamente adempiuto ai propri obblighi contrattuali per la
                durata che la normativa prevede, in generale, per la
                prescrizione dell'azione per inadempimento contrattuale. I Dati
                Personali trattati per le finalità di cui alla sezione 3(d)
                saranno invece conservati fino al tempo previsto dallo specifico
                obbligo o norma di legge applicabile. A titolo esemplificativo,
                come già specificato, i dati di traffico saranno conservati per
                finalità di giustizia per sei anni dalla loro generazione;
                altrimenti, saranno conservati per sei mesi; le fatture saranno
                conservate per dieci anni. Per le finalità di cui alla sezione 3
                (f) (Marketing), i suoi Dati Personali saranno invece trattati
                fino alla revoca del suo consenso oppure fino a tre anni dopo
                che lei abbia cessato di essere cliente di WeeBo, o si sia
                semplicemente registrato sul Sito, e non abbia fatto alcun
                acquisto di prodotti o servizi. Anche in questo caso è fatta
                salva in ogni caso la possibilità per WeeBo di conservare i suoi
                Dati Personali fino al tempo permesso dalla legge Italiana a
                tutela dei propri interessi (Art. 2947(1)(3) c.c.). Con
                specifico riguardo all'attività di Marketing indicata alla
                sezione 3(f)(i), i suoi dati saranno cancellati dalla
                piattaforma una volta che avrà avuto luogo l'attività di invio
                di e-mail. Per le finalità di cui alla sezione 3 (h)
                (Profilazione), i tempi di conservazione dei suoi dati sono
                equiparati ai tempi di conservazione dei dati trattati per
                finalità di Marketing. Per le finalità di cui alla sezione
                3(i)(i) (antifrode), i dati sono conservati per il tempo
                necessario alla Società a poter prevenire e combattere condotte
                fraudolente poste in essere tramite il Sito, quali ad esempio
                beneficiare più volte della medesima promozione senza averne
                diritto. Per le finalità di cui alla sezione 3(i)(ii)
                (antispam), i dati sono conservati per tutta la durata del
                rapporto contrattuale. Con riferimento ai dati di traffico, come
                già specificato questi ultimi sono conservati per sei mesi dalla
                loro generazione per finalità di erogazione del servizio e per
                sei anni per finalità di accertamento e repressione dei reati,
                in conformità con quanto previsto dalla normativa italiana; per
                le finalità di cui alla sezione 3(i)(iii) (verifiche sull'uso
                dell'hosting), i log di navigazione sono conservati per sette
                giorni dalla loro generazione. Per le finalità di cui alla
                sezione 3(i)(vi) (verifica dell'identità) WeeBo tratterà i Dati
                Personali fino al tempo permesso dalla normativa italiana a
                tutela dei propri interessi (Art. 2946 c.c. e ss). Per le
                finalità di cui alla sezione 3(l) (Marketing Automation), i suoi
                Dati Personali saranno trattati fino alla revoca del suo
                consenso oppure in ogni caso, qualora lei sia un utente non
                registrato sul Sito, per 14 giorni o, qualora lei sia un utente
                registrato, per 2 mesi. Per le finalità di cui alla sezione
                3(m), i suoi Dati Personali saranno trattati per 25 mesi dalla
                raccolta.
            </p>

            <h3>Diritti degli interessati</h3>
            <p>
                Fermi restando gli obblighi o le facoltà di conservazione dei
                Dati descritti al paragrafo 6, lei ha il diritto di chiedere a
                WeeBo, in qualunque momento, l'accesso ai suoi Dati Personali,
                la rettifica o la cancellazione degli stessi o di opporsi al
                loro trattamento nei casi previsti dall'art. 20 del Regolamento,
                ha diritto di richiedere la limitazione del trattamento nei casi
                previsti dall'art. 18 del Regolamento, nonché di ottenere in un
                formato strutturato, di uso comune e leggibile da dispositivo
                automatico i dati che la riguardano (portabilità), nei casi
                previsti dall'art. 20 del Regolamento. Le richieste vanno
                rivolte per iscritto da qui oppure scrivendo a
                <a href="mailto:supporto@weebo.cloud">supporto@weebo.cloud</a> Per
                esercitare il diritto alla portabilità ed ottenere maggiori
                informazioni sul suo contenuto, acceda a questo link:
                <a
                    style="color: red"
                    href="https://WeeBo.cloud/portabilita-dei-dati/"
                    >https://WeeBo.cloud/portabilita-dei-dati/</a
                >
                Si precisa che, in caso di richiesta di cancellazione dei suoi
                Dati Personali, qualora il consenso eventualmente rilasciato per
                le finalità di cui al paragrafo 3 lettera g) non sia stato
                revocato, nel periodo di tempo intercorrente tra la
                cancellazione dei Dati Personali e i tempi tecnici di alcune ore
                necessari all'aggiornamento dei sistemi per l'invio di e-mail
                promozionali, potrebbe continuare a ricevere eventuali email
                promozionali precedentemente programmate per l'invio. Inoltre,
                in presenza di richieste da parte di soggetti interessati
                relative alla segnalazione di abusi nell'utilizzo dei servizi o
                attività di spamming - attività già vietate per contratto come
                precisato al par. 8 delle Condizioni Generali di Servizio -
                effettuati da un Cliente di WeeBo (si precisa che tale cliente
                agisce tipicamente come titolare del trattamento ai sensi del
                Regolamento), nonché in presenza di qualsiasi ulteriore
                richiesta di esercizio dei diritti ex art.15 e ss. del
                Regolamento, WeeBo, senza entrare nel merito della richiesta, da
                un lato informerà tempestivamente il cliente/titolare, e
                dall'altro fornirà ai soggetti interessati gli estremi del
                cliente/titolare. In ogni caso lei ha sempre diritto di proporre
                reclamo all'autorità di controllo competente (Garante per la
                Protezione dei Dati Personali), ai sensi dell'art. 77 del
                Regolamento, qualora ritenga che il trattamento dei suoi dati
                sia contrario alla normativa in vigore, o di adire le opportune
                sedi giudiziarie (art. 79 del Regolamento).
            </p>

            <h3>Modifiche</h3>
            <p>
                La presente privacy policy è in vigore dal 10/03/2024. WeeBo si
                riserva di modificarne o semplicemente aggiornarne il contenuto,
                in parte o completamente, anche a causa di variazioni della
                normativa applicabile. Qualora le modifiche alla presente policy
                riguardino cambiamenti sostanziali nei trattamenti oppure
                possano avere comunque un impatto rilevante sugli interessati,
                WeeBo avrà cura di notificarle opportunamente agli interessati.
            </p>
            <!-- FINE CONTENUTO -->

            <h1>Contatti</h1>
            <p class="p10">
                <span class="s1"
                    >Non esitate a contattarci se avete domande.</span
                >
            </p>
            <ul class="ul1">
                <li class="li16">
                    <span class="s3"
                        >Via Email:
                        <a href="mailto:supporto@weebo.cloud"
                            >supporto@weebo.cloud</a
                        ></span
                    >
                </li>
                <li class="li16">
                    <span class="s3"
                        >Via Phone Number:
                        <a href="tel:+393517994419">(+39) 351 7994 419</a></span
                    >
                </li>
                <li class="li16">
                    <span class="s3"
                        >Via this Link:<a href="https://weebo.cloud/#/contact"
                            >weebo.cloud/#/contact</a
                        ></span
                    >
                </li>
                <!---->
            </ul>
        </div>
    </div>
</section>
