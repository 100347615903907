import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-domain-search',
  templateUrl: './domain-search.component.html',
  styleUrls: ['./domain-search.component.scss']
})
export class DomainSearchComponent implements OnInit {
  domainQuery: string = '';
  selectedExtension: string = '.com';
  serverResponse: string = ''; // Contiene il contenuto HTML restituito dal server

  constructor(private http: HttpClient) {
  }

  ngOnInit(): void {
  }

  submitForm(): void {
    this.domainQuery += this.selectedExtension;
    //console.log("Form submitted with domain query:", this.domainQuery);
    
    this.http.post('https://weebo.cloud/area-clienti/cart.php?a=add&domain=register', { query: this.domainQuery }, { responseType: 'text' })
    .subscribe(
      (responseHtml) => {
        console.log("Response from server:", responseHtml);
        // Apri il link ritornato nel browser
        this.serverResponse = responseHtml;
      },
      (error) => {
        console.error("Error:", error);
        // Gestisci gli errori qui
      }
    );
  }
}
