<section class="location-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Sedi dei Data Center</h2>
            <p>Il nostro Data Center si trova nel nord Italia. Precisemnete la nostra sede è situata in Trentino.</p>
        </div>

        <div class="map-img">
            <img src="assets/img/map-img.png" alt="Image">

            <div class="single-location video-btn">
                <i class="bx bx-location-plus"></i>
                <span>Italia</span>
            </div>

            <!-- <div class="single-location location-two video-btn">
                <i class="bx bx-location-plus"></i>
                <span>London</span>
            </div>

            <div class="single-location location-three video-btn">
                <i class="bx bx-location-plus"></i>
                <span>Canada</span>
            </div>

            <div class="single-location location-four video-btn">
                <i class="bx bx-location-plus"></i>
                <span>Brazil</span>
            </div>

            <div class="single-location location-five video-btn">
                <i class="bx bx-location-plus"></i>
                <span>Australia</span>
            </div>

            <div class="single-location location-six video-btn">
                <i class="bx bx-location-plus"></i>
                <span>Washington</span>
            </div> -->
        </div>

        <ul class="by-domain">
            <li><span>Italia</span></li>
            <!-- <li><span>London</span> (90)</li>
            <li><span>Canada</span> (80)</li>
            <li><span>Brazil</span> (40)</li>
            <li><span>Australia</span> (60)</li>
            <li><span>Washington</span> (70)</li> -->
        </ul>
    </div>
</section>