<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Log In</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Home </a></li>
                        <li>Pages</li>
                        <li>User</li>
                        <li>Log In</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="user-area-all-style log-in-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="contact-form-action">
                    <div class="form-heading text-center">
                        <h3 class="form-title">Login to your account!</h3>
                        <p class="form-desc">with your social network.</p>
                    </div>

                    <form>
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input class="form-control" type="text" name="name" placeholder="Username or Email">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input class="form-control" type="password" name="password" placeholder="Password">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 form-condition">
                                <div class="agree-label">
                                    <input type="checkbox" id="chb1">
                                    <label for="chb1">Remember Me</label>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <a class="forget" routerLink="/recover-password">Forgot my password?</a>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button class="default-btn btn-two" type="submit">Log In</button>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <p class="account-desc">Not a member?<a routerLink="/sign-up">Register</a></p>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>