import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section10-recensioni',
  templateUrl: './section10-recensioni.component.html',
  styleUrls: ['./section10-recensioni.component.scss']
})
export class Section6RecensioniComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
