import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section5-compromessi',
  templateUrl: './section5-compromessi.component.html',
  styleUrls: ['./section5-compromessi.component.scss']
})
export class SectionCompromessiComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
