<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Condizioni generali</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/legal">Legal</a></li>
                        <li>Condizioni generali</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="terms-conditions ptb-100">
    <div class="container">
        <div class="single-privacy">
            <h2 style="margin-top: 10px">Disclaimer</h2>
            <p><b>Aggiornato il 27/02/24</b></p>

            <!-- MAIN CONTENUTO -->

            <!-- Articolo 1 -->
            <h1>Condizioni Generali di Servizio di WeeBo.cloud</h1>
            <p>
                Le presenti Condizioni Generali di Servizio di WeeBo, unitamente
                ai documenti di seguito indicati, regolano l'erogazione dei
                Servizi (di seguito anche "Servizio/i") offerti da WeeBo,<!-- ,
                con sede legale in Firenze (FI), Viale della Giovine Italia n.
                17, C. F. e P. IVA 04628270482 (di seguito anche "WeeBo" o la
                "Società") -->
                ai Clienti (di seguito anche "Cliente/i" o "Billing"). WeeBo ed
                il Cliente denominate congiuntamente le "Parti" e, singolarmente
                ciascuna di esse, la "Parte".
            </p>

            <h3>Art. 1. Documenti contrattuali</h3>
            <p>
                <b>1.1</b> Costituiscono parte integrante e sostanziale del
                Contratto (di seguito "Contratto") tra WeeBo e il Cliente:
            </p>
            <ol type="a">
                <li>
                    il Modulo d'ordine o la Richiesta di attivazione (di seguito
                    anche "Ordine");
                </li>
                <!-- <li>
                    b) Ordine di Servizio (di seguito anche "OdS"), contenente i
                    termini e le condizioni specifiche del singolo servizio
                    richiesto dal Cliente (complessivamente pubblicati alla
                    pagina
                    <a href="https://www.WeeBo.it/company/legal/"
                        >https://www.WeeBo.it/company/legal/</a
                    >);
                </li> -->
                <li>
                    le presenti Condizioni Generali di Servizio (di seguito
                    anche "CGS") (pubblicate alla pagina
                    <a href="https://weebo.cloud/#/legal/terms-conditions"
                        >https://weebo.cloud/#/legal/terms-conditions</a
                    >);
                </li>
                <li>
                    l'Informativa Privacy di WeeBo S.p.A. (pubblicata alla
                    pagina
                    <a href="https://weebo.cloud/#/legal/privacy-policy"
                        >https://weebo.cloud/#/legal/privacy-policy</a
                    >);
                </li>
                <li>
                    le Schede Prodotto, le pagine web del sito
                    <a href="https://weebo.cloud">WeeBo</a> o il materiale
                    informativo portato a conoscenza del Cliente che descrive le
                    funzionalità, i costi e le caratteristiche dei beni e dei
                    servizi offerti ai Clienti da WeeBo;
                </li>
                <li>
                    il Contratto per il trattamento dei dati personali
                    (pubblicato alla pagina
                    <a href="https://weebo.cloud/#/legal"
                        >https://weebo.cloud/#/legal</a
                    >);
                </li>
                <!-- <li>
                    g) il Regolamento e condizioni delle offerte promozionali di
                    WeeBo (di seguito anche "Regolamento Offerte"), (pubblicato
                    alla pagina
                    <a href="https://www.WeeBo.it/assistenza/offerte/"
                        >https://www.WeeBo.it/assistenza/offerte/</a
                    >)
                </li> -->
            </ol>
            <p>
                <b>1.2</b> In caso di contrasto tra le presenti Condizioni
                Generali di Servizio ed il singolo OdS, quest'ultimo dovrà
                ritenersi prevalente.
            </p>
            <p>
                <b>1.3</b> Laddove il Cliente richieda l'attivazione di Servizi
                per conto terzi o di Servizi in Prova, troveranno applicazione,
                rispettivamente, le disposizioni contenute nella Sezione I
                ("Disposizioni per la richiesta di servizi da parte del Cliente
                per conto terzi") e/o nella Sezione II ("Disposizioni per i
                Servizi in Prova") delle presenti CGS, di cui costituiscono
                parte integrante e sostanziale.
            </p>
            <p>
                <b>1.4</b> Laddove il Cliente richieda l'attivazione di Servizi
                della linea Online Brand Protection o laddove WeeBo accetti
                l'adesione del Cliente al programma Business Partner di WeeBo,
                troveranno applicazione, rispettivamente, le "Condizioni di
                Servizio Online Brand Protection" e "Contratto Business Partner
                di WeeBo". In caso di contrasto tra le presenti CGS e le
                "Condizioni di Servizio Online Brand Protection" queste ultime
                dovranno ritenersi prevalenti; nel caso di contrasto tra le
                presenti CGS ed il "Contratto Business Partner di WeeBo"
                quest'ultimo dovrà ritenersi prevalente.
            </p>
            <p>
                <b>1.5</b> Le lingue a disposizione per concludere il Contatto
                sono l'italiano e l'inglese. In caso di contrasto, il testo in
                lingua italiana avrà la prevalenza sul testo in lingua inglese.
            </p>
            <p>
                <b>1.6</b> Fermo quanto sopra, le versioni successive dei
                documenti indicati all'art. 1.1 dovranno ritenersi prevalenti
                sulle versioni precedenti.
            </p>

            <!-- Articolo 2 -->
            <h3>Art. 2. Conclusione del Contratto</h3>
            <p>
                <b>2.1</b> Il Contratto con il Cliente si perfeziona al
                ricevimento dell'Ordine correttamente compilato e inviato dal
                Cliente a WeeBo, unitamente al pagamento del Corrispettivo per i
                Servizi richiesti, salva diversa previsione nelle presenti CGS o
                nell'OdS relativo ai Servizi medesimi.
            </p>
            <p>
                <b>2.2</b> Il Cliente, una volta registrato sul sito ai sensi
                del successivo art. 3 (Procedura di registrazione - User ID e
                password), potrà effettuare l'acquisto dei beni e servizi di
                WeeBo descritti all'interno delle Schede Prodotto, aggiungendo i
                beni e i servizi di proprio interesse nel carrello, compilando
                l'Ordine in formato elettronico ed inviandolo a WeeBo secondo
                quanto di seguito descritto. All'interno del carrello il Cliente
                potrà visualizzare i beni e i servizi selezionati, il costo di
                ciascuno, il costo totale dell'Ordine e le condizioni
                contrattuali applicate. Prima di inoltrare l'Ordine a WeeBo, il
                Cliente potrà aggiungere od eliminare prodotti nel carrello,
                correggere eventuali errori di inserimento dei dati, tornare
                alla pagina precedente o proseguire con l'acquisto.
            </p>
            <p>
                <b>2.3</b> Il Cliente dovrà confermare di aver preso visione ed
                accettare la documentazione contrattuale, nonché indicare o
                confermare un indirizzo di fatturazione e scegliere una modalità
                di pagamento tra quelle messe a disposizione da WeeBo. Il
                Cliente prende atto e accetta che i file di log di WeeBo
                conservati a norma di legge costituiscono piena prova del
                consenso del Cliente stesso alla conclusione del Contratto.
            </p>
            <p>
                <b>2.4</b> Ricevuto l'ordine, WeeBo invierà al Cliente una
                conferma d'ordine, contenente un riepilogo di tutte le
                informazioni relative ai beni e servizi acquistati. WeeBo
                emetterà idonea fattura per i beni e servizi acquistati.
            </p>
            <p>
                <b>2.5</b> Nella misura in cui siano espressamente richiamate
                nelle presenti CGS o siano condizioni inderogabili, si applicano
                le disposizioni del Codice del consumo e del Decreto legislativo
                n. 70/2003 in materia di commercio elettronico.
            </p>

            <!-- Articolo 3 -->
            <h3>
                Art. 3. Procedure di registrazione e acquisto - User ID e
                password.
            </h3>
            <p>
                <b>3.1</b> Prima di effettuare un acquisto, il Cliente dovrà
                registrarsi sul sito creando un account personale con WeeBo,
                ossia una coppia di User ID e Password (di seguito anche
                "Credenziali di Autenticazione"), seguendo le indicazioni
                riportate alla pagina
                <a href="https://weebo.cloud/area-clienti/register.php"
                    >https://weebo.cloud/area-clienti/register.php</a
                >. In alternativa, il Cliente potrà eseguire la registrazione
                sul sito
                <a href="https://www.weebo.cloud">https://www.weebo.cloud</a>
                direttamente durante il processo di acquisto. Nel completare le
                procedure di registrazione e di acquisto dei Servizi, il Cliente
                si obbliga a fornire i dati richiesti in maniera corretta e
                veritiera.
            </p>
            <p>
                <b>3.2</b> La procedura di registrazione ai Servizi si conclude
                per via telematica. Soltanto nei casi ammessi da WeeBo, il
                Cliente potrà scegliere di procedere alla comunicazione dei
                propri dati per via telefonica ad un operatore WeeBo, anziché
                per via telematica. In quest'ultimo caso il Cliente si impegna a
                seguire le indicazioni dell'operatore oltre alla procedura
                indicata sul sito di WeeBo. Il Cliente, dopo aver comunicato
                telefonicamente i dati all'operatore, avrà quindi accesso ad una
                pagina web residente su server sicuro dove troverà pubblicati i
                dati comunicati ed inseriti dall'operatore che sarà cura del
                Cliente verificare prima di confermare la registrazione. La
                conferma dovrà essere comunicata a WeeBo entro un termine
                massimo di 10 (dieci) giorni solari, trascorsi i quali, in
                difetto di conferma, WeeBo sarà liberata da ogni impegno nei
                confronti del Cliente. La conferma esonererà in ogni caso WeeBo
                da ogni responsabilità circa i dati forniti dal Cliente.
            </p>
            <p>
                <b>3.3</b> In tutti i casi - sia di comunicazione telematica che
                di comunicazione telefonica - il Cliente si obbliga a informare
                tempestivamente WeeBo di qualsiasi variazione dei propri dati in
                ogni tempo comunicati. Qualora il Cliente comunichi dati non
                esatti o incompleti, WeeBo avrà facoltà di non attivare e/o
                sospendere il servizio fino a che il Cliente non provveda a
                comunicare i dati esatti e/o ad integrare i dati incompleti.
                WeeBo si riserva tali facoltà anche nel caso in cui gli organi
                competenti (ad es. banche o titolari di carte di credito)
                contestino i pagamenti effettuati dal Cliente.
            </p>
            <p>
                <b>3.4</b> In occasione dell'attivazione del Servizio da parte
                del Cliente, WeeBo attribuirà allo stesso uno User ID ed una
                Password. Il Cliente riconosce che tali User ID e Password
                costituiscono il sistema di validazione degli accessi del
                Cliente ai Servizi. Le Parti riconoscono e convengono che tali
                User ID e Password costituiscono i soli mezzi idonei ad
                identificare il Cliente al momento dell'accesso ai Servizi. Il
                Cliente, pertanto, conviene e concorda che tutti gli atti
                compiuti mediante utilizzo delle menzionate User ID e Password
                saranno a lui attribuiti e avranno efficacia vincolante nei suoi
                confronti. Il Cliente riconosce di essere il solo ed esclusivo
                responsabile degli atti compiuti tramite le proprie User ID e
                Password e si obbliga a mantenerne la massima segretezza, a
                custodirle con la dovuta cura e diligenza e a non cederli -
                anche temporaneamente - a terzi.
            </p>
            <p>
                <b>3.5</b> In ogni caso, il Cliente accetta che le registrazioni
                informatiche e/o telematiche effettuate da WeeBo e/o dai propri
                fornitori, possano essere opposte e dedotte avanti qualsiasi
                Autorità competente ad ogni fine probatorio ai sensi e per gli
                effetti delle presenti CGS e che, in particolare, su di esse le
                parti possano fondare idonea prova civile circa la sussistenza
                delle relazioni e/o degli atti oggetto di eventuale
                contestazione.
            </p>

            <!-- Articolo 4 -->
            <h3>Art. 4. Natura dei servizi, corrispettivi e pagamenti</h3>
            <p>
                <b>4.1</b> La natura e il tipo dei Servizi prestati da WeeBo al
                Cliente è descritta nei singoli OdS relativi ai Servizi e nelle
                pagine di descrizione del Servizio. I Corrispettivi per i
                Servizi nonché i termini e le modalità per il pagamento dei
                Corrispettivi sono quelli stabiliti negli OdS o nelle offerte
                commerciali disponibili sul Sito web o portate a conoscenza del
                Cliente. Tutti i Corrispettivi, se non espressamente
                specificato, sono da intendersi I.V.A. esclusa.
            </p>
            <p>
                <b>4.2</b> Il Cliente ha la facoltà di scegliere tra i diversi
                strumenti di pagamento autorizzati da WeeBo e indicati nella
                relativa pagina informativa sui metodi di pagamento accettati,
                accessibile tramite il seguente link:
                <a href="https://weebo.cloud/#/legal/payment"
                    >https://weebo.cloud/#/legal/payment</a
                >. Il Cliente potrà in qualsiasi momento associare nuovi
                strumenti di pagamento e modificare i propri dati. Per
                cancellare l'accordo di fatturazione con Stripe, il Cliente
                dovrà procedere entrando nel Profilo del proprio conto Stripe e
                seguire la procedura di cancellazione dalla apposita pagina sul
                proprio account Stripe.
            </p>
            <p>
                <b>4.3</b> Tutti gli oneri fiscali derivanti dall'utilizzo dei
                Servizi da parte del Cliente saranno a carico esclusivo di
                quest'ultimo.
            </p>
            <p>
                <b>4.4</b> In caso di ritardo nell'effettuazione dei pagamenti,
                WeeBo avrà facoltà di sospendere la prestazione dei Servizi, ai
                sensi e per gli effetti dell'art. 1460 c. c., e il Cliente,
                senza necessità di costituzione in mora, sarà tenuto al
                pagamento di interessi moratori in misura pari al tasso legale
                di interesse aumentato di 5 punti percentuali, fatto salvo il
                diritto di WeeBo al risarcimento dell'eventuale maggior danno.
            </p>
            <p>
                <b>4.5</b> Qualora il Servizio sia impostato con modalità di
                rinnovo automatico conformemente a quanto previsto al successivo
                art. 7 ("Durata, rinnovi e recesso") e il Cliente abbia
                associato al proprio account una carta di credito o un account
                Stripe, il Cliente prende atto che i dati della carta di credito
                o dell'account Stripe saranno associati all'account del Cliente
                per la gestione dei futuri pagamenti, salvo espressa richiesta
                di modifica effettuata direttamente dal Cliente sul proprio
                pannello di controllo; i dati relativi alla carta di credito
                saranno memorizzati per la gestione dei successivi pagamenti da
                società regolarmente certificata PCI-DSS che svolgerà il
                servizio per conto di WeeBo. Il Cliente prende altresì atto che
                in caso di rinnovo verrà applicato il prezzo di listino
                raggiungibile al seguente link:
                <a href="https://weebo.cloud/#/legal/listino-prezzi"
                    >https://weebo.cloud/#/legal/listino-prezzi</a
                >
                ed in vigore alla data del rinnovo.
            </p>
            <p>
                <b>4.6</b> In caso di rinnovo automatico dei servizi, il Cliente
                autorizza espressamente WeeBo ad addebitare i Corrispettivi per
                i Servizi con cadenza periodica, sulla base delle tempistiche di
                rinnovo previste per i singoli servizi e/o in base alle
                richieste dei Clienti. Il Cliente garantisce sin d'ora la
                disponibilità delle somme necessarie per effettuare i pagamenti.
                Resta inteso che, in caso di mancata disdetta nei termini, il
                Contratto si intenderà rinnovato e non si potrà procedere al
                rimborso della somma pagata in sede di rinnovo.
            </p>

            <!-- Articolo 5 -->
            <h3>Art. 5. Utilizzo dei Servizi e responsabilità del Cliente</h3>
            <p>
                <b>5.1</b> Il Cliente si impegna ad utilizzare i Servizi con la
                massima diligenza, rispettando le regole di utilizzo indicate
                nei singoli OdS ed in modo tale da non compromettere, la
                stabilità, la sicurezza e la qualità dei Servizi. WeeBo si
                riserva il diritto di sospendere o interrompere l'accesso del
                Cliente ai Servizi qualora:
            </p>

            <ol style="list-style-type: none">
                <li>
                    (i) il Cliente violi in modo sostanziale o ripetuto il
                    presente Contratto o utilizzi i Servizi per finalità
                    illecite;
                </li>
                <li>
                    (ii) WeeBo sia tenuta ad agire in tal modo per rispettare
                    una norma di legge o un ordine dell'Autorità;
                </li>
                <li>
                    (iii) WeeBo ritenga, in maniera ragionevole e fondata, che
                    la condotta del Cliente comporti danni o responsabilità per
                    un altro Cliente, una terza parte o la stessa WeeBo;
                </li>
                <li>
                    (iv) il Cliente immetta attraverso i Servizi contenuti
                    indiscutibilmente illeciti.
                </li>
            </ol>
            <p>
                In tal caso, al fine di chiarire al Cliente le motivazioni
                sottostanti la propria decisione e assicurare il contraddittorio
                con il Cliente, WeeBo fornirà allo stesso un preavviso di 7
                (sette) giorni tramite comunicazione via e-mail, dando al
                Cliente la possibilità di opporsi alla decisione entro lo stesso
                termine per email ovvero attraverso gli ulteriori canali
                indicati da WeeBo; WeeBo valuterà gli eventuali chiarimenti
                forniti dal Cliente ai fini della propria decisione finale in
                merito alla sospensione del Servizio, che sarà in ogni caso
                comunicata al Cliente tramite email. Resta inteso che il
                preavviso non sarà dovuto quando lo stesso sia incompatibile con
                il rispetto della norma di legge o dell'ordine dell'Autorità
                alla base della sospensione o laddove WeeBo abbia il fondato
                motivo di ritenere che il preavviso possa aggravare danni o
                responsabilità per un altro Cliente, una terza parte o la stessa
                WeeBo oppure possa compromettere la stabilità, sicurezza e
                qualità dei Servizi offerti in condivisione.
            </p>
            <p>
                <b>5.2</b> Il Cliente si impegna a non utilizzare i Servizi per
                finalità illecite ed a non violare in alcun modo le norme
                nazionali, comunitarie ed internazionali, anche regolamentari,
                applicabili. In particolare, il Cliente si impegna a non
                immettere, e a non fare immettere a terzi, link a contenuti:
            </p>
            <ol style="list-style-type: none">
                <li>
                    (i) dannosi (a titolo meramente esemplificativo e non
                    esaustivo, malware, virus, software potenzialmente nocivi,
                    ecc.);
                </li>
                <li>
                    (ii) a qualsiasi titolo violativi, o anche solo
                    potenzialmente lesivi, della privacy, dei diritti d'autore e
                    della proprietà intellettuale ed industriale, né contenuti
                    diffamatori, pornografici, blasfemi o offensivi, che
                    incitino all'odio razziale, o che possano in qualsiasi altro
                    modo ledere o inficiare l'attività di WeeBo e/o ledere o
                    mettere in pericolo l'immagine di terzi o di WeeBo;
                </li>
                <li>
                    (iii) contenenti pubblicità, materiale promozionale, spam
                    non richiesti o non autorizzati. Il Cliente, inoltre, si
                    impegna a non compiere attraverso i Servizi o il proprio
                    accesso ad Internet atti di pirateria informatica.
                </li>
            </ol>
            <p>
                Il Cliente garantisce che non effettuerà spamming, ovvero
                l'invio, via e-mail, di comunicazioni non autorizzate e/o non
                richieste dai destinatari. WeeBo considererà il Cliente
                responsabile anche qualora l'attività illegale di spam venga
                effettuata attraverso indirizzi di posta diversi da quello
                acquistato da WeeBo e coinvolga anche indirettamente un Servizio
                di WeeBo o direttamente la struttura tecnica di WeeBo (a titolo
                d'esempio, promozione non autorizzata di sito internet in
                hosting presso WeeBo). Il Cliente prende atto che le attività
                sopra descritte costituiscono una violazione delle presenti CGS.
            </p>
            <p>
                <b>5.3</b> Fermo restando quanto sopra indicato e fatti salvi i
                casi di risoluzione espressa previsti nel presente Contratto,
                laddove - anche su segnalazione di terzi - WeeBo abbia fondato
                motivo di ritenere che il Cliente compia attività in violazione
                degli obblighi previsti nel presente Contratto o comunque
                utilizzi i Servizi in violazione di diritti di terze parti,
                WeeBo potrà assegnare al Cliente, anche per email, un congruo
                termine entro il quale rimediare all'inadempimento contestato
                ovvero fornire la documentazione e le informazioni comprovanti
                il rispetto del Contratto e della normativa vigente che
                disciplina il corretto svolgimento delle attività poste in
                essere attraverso i Servizi; in caso di mancato riscontro,
                ovvero laddove il Cliente non dimostri la corretta esecuzione
                del Contratto e il rispetto degli obblighi di legge, WeeBo avrà
                il diritto di risolvere immediatamente il Contratto fermo
                restando il diritto all'integrale pagamento del corrispettivo
                maturato ed il diritto di WeeBo ad agire per l'integrale
                risarcimento dei danni eventualmente subiti.
            </p>
            <p>
                <b>5.4</b> Il Cliente prende atto e accetta quanto previsto nel
                "Regolamento e condizioni delle offerte promozionali di WeeBo"
                (pubblicato alla pagina
                <a href="https://weebo.cloud/#/legal/offerte"
                    >https://weebo.cloud/#/legal/offerte</a
                >). In particolare, il Cliente prende atto ed accetta che le
                offerte promozionali sono riservate a un numero limitato di
                prodotti e servizi per ciascun Cliente; nel caso in cui per un
                prodotto o servizio vengano effettuati più acquisti con account
                diversi riconducibili a un unico soggetto, la promozione non
                avrà più alcun valore, ed il Cliente sarà tenuto al pagamento
                integrale dei prodotti e Servizi acquistati al prezzo di
                listino, raggiungibile al seguente link:
                <a href="https://weebo.cloud/#/legal/listino-prezzi"
                    >https://weebo.cloud/#/legal/listino-prezzi</a
                >
                si riserva in ogni caso il diritto di procedere alla sospensione
                o alla cancellazione immediata dei prodotti o Servizi acquistati
                dal Cliente, oltre ad agire per l'integrale risarcimento dei
                danni eventualmente subiti.
            </p>
            <p>
                <b>5.5</b> Il Cliente riconosce di essere il solo ed esclusivo
                responsabile per le attività svolte attraverso i Servizi a lui
                direttamente o indirettamente riferibili, e ciò anche laddove il
                Cliente abbia sottoscritto un OdS per conto di terzi soggetti da
                lui autorizzati all'utilizzo del Servizio, ed in particolare di
                essere responsabile dei contenuti e delle comunicazioni
                inserite, pubblicate, diffuse e trasmesse su o tramite i
                Servizi. Pertanto, WeeBo non potrà essere ritenuta in alcun modo
                responsabile per eventuali illeciti, civili, penali ed
                amministrativi commessi dal Cliente per mezzo del Servizio. Il
                Cliente si obbliga a manlevare e, comunque, a tenere indenne
                WeeBo da qualsiasi azione, istanza, pretesa, costo o spesa,
                incluse le spese legali, eventualmente derivanti alla stessa a
                causa del mancato rispetto da parte del Cliente delle
                obbligazioni assunte e delle garanzie prestate con
                l'accettazione delle presenti CGS o con un OdS e comunque
                connesse all'utilizzo dei Servizi da parte del Cliente.
            </p>
            <p>
                <b>5.6</b> Se non diversamente specificato, i Servizi non
                includono il backup dei dati. Pertanto, il Cliente è tenuto ad
                effettuare frequenti e sicuri backup dei propri file e dati,
                della propria rete interna e di tutte le apparecchiature
                connesse, in piena autonomia e sotto la propria esclusiva
                responsabilità. È inoltre responsabilità del Cliente assicurarsi
                che i propri firewall e la protezione antivirus siano aggiornati
                e sufficienti per le proprie esigenze.
            </p>

            <!-- Articolo 6 -->
            <h3>Art. 6. Limitazioni della responsabilità di WeeBo</h3>
            <p>
                <b>6.1</b> WeeBo si impegna ad impiegare la migliore tecnologia
                di cui è a conoscenza e le migliori risorse a sua disposizione
                per fornire i Servizi offerti ai Clienti.
            </p>
            <p>
                <b>6.2</b> Entro i limiti imposti o derivanti da norme
                imperative di legge, WeeBo non assume alcuna responsabilità nei
                confronti del Cliente o di soggetti terzi per danni o pregiudizi
                da questi subiti (inclusi, a titolo meramente esemplificativo e
                non esaustivo, ogni danno emergente o lucro cessante, perdita di
                profitto, perdita di business, o diminuzione del valore e/o
                perdite di analogo tenore, o perdita di beni/valore, o perdita
                di nomi a dominio, o perdita di contratti, o perdita d'uso, o
                perdita o compromissione di dati e informazioni, o danni a
                hardware, software o ai database, o ogni tipo di danno speciale,
                indiretto o consequenziale o perdita economica tout court) che
                siano causati o connessi all'erogazione dei Servizi o
                all'esecuzione del presente Contratto, salvi i casi di dolo o
                colpa grave direttamente imputabili a WeeBo. Fatti salvi i casi
                di dolo o colpa grave di WeeBo e/o dei suoi dipendenti e/o dei
                suoi fornitori/sub-fornitori che arrechino danni alle persone
                e/o a cose, la responsabilità contrattuale di WeeBo sarà in ogni
                caso limitata ai danni prevedibili e direttamente imputabili a
                WeeBo e derivanti da violazioni del presente Contratto o da
                negligenza e non potrà in nessun modo essere quantitativamente
                superiore al Corrispettivo pagato dal Cliente negli ultimi 12
                (dodici) mesi per i Servizi interessati dall'evento dannoso.
                Inoltre, laddove l'azione od omissione di WeeBo abbia causato,
                ai sensi della presente previsione, più di un evento a danno del
                Cliente, tutti gli eventi causati da una singola azione e/o
                omissione di WeeBo verranno considerati come un unico evento.
                Resta espressamente inteso che la limitazione di responsabilità
                di cui al presente articolo 6.2 non si applica nei confronti dei
                Clienti che siano qualificabili come "Consumatori", cioè persone
                fisiche che acquistano o utilizzano i Servizi per finalità
                estranee all'attività imprenditoriale, artigianale, commerciale
                o professionale eventualmente svolta.
            </p>
            <p>
                <b>6.3</b> Il Cliente conviene e concorda che WeeBo non potrà
                essere ritenuta responsabile dei ritardi o malfunzionamenti
                nella prestazione dei Servizi dipendenti da eventi fuori dal
                ragionevole controllo di WeeBo quali, a titolo esemplificativo:
            </p>
            <ol style="list-style-type: none">
                <li>(i) eventi di Forza Maggiore;</li>
                <li>
                    (ii) eventi dipendenti da fatto di terzi quali, a titolo
                    meramente esemplificativo, l'interruzione o il
                    malfunzionamento dei servizi degli operatori di
                    telecomunicazioni e/o delle linee elettriche ovvero atti od
                    omissioni delle competenti Registration Authority;
                </li>
                <li>
                    (iii) malfunzionamento dei terminali o degli altri sistemi
                    di comunicazione utilizzati dal Cliente. In caso di
                    interruzione del Servizio, WeeBo si impegna a ripristinare
                    lo stesso nel minor tempo possibile. Il Cliente conviene e
                    concorda che WeeBo non potrà essere ritenuta responsabile
                    per atti od omissioni compiuti dal Cliente e in contrasto
                    con le obbligazioni da questi assunte ai sensi delle
                    presenti CGS o di uno specifico OdS; del pari, WeeBo non
                    potrà essere ritenuta responsabile per malfunzionamenti
                    dovuti a vizi dei mezzi indispensabili all'accesso, ad un
                    uso improprio degli stessi e/o delle errate modalità di
                    accesso al Servizio da parte del Cliente.
                </li>
            </ol>
            <p>
                Nei casi sopraindicati WeeBo non potrà essere ritenuta
                responsabile nei confronti del Cliente o di terzi per perdita di
                profitto, mancato guadagno, né per qualsiasi altra forma di
                lucro cessante o danno indiretto e consequenziale connesso
                all'esecuzione delle presenti CGS o di ogni singolo OdS. Il
                Cliente è informato che i Servizi potranno essere sospesi,
                cancellati o trasferiti su richiesta delle Autorità cui i
                Servizi sono soggetti.
            </p>
            <p>
                <b>6.4</b> Fermo quanto sopra, WeeBo si riserva la facoltà di
                sospendere o interrompere l'erogazione dei Servizi per eseguire
                interventi tecnici di manutenzione ordinaria e straordinaria,
                impegnandosi a ripristinare i Servizi interessati nel minor
                tempo possibile. In caso di interventi di manutenzione necessari
                per fatti eccezionali o non prevedibili e/o per caso fortuito o
                Forza Maggiore, WeeBo avrà la facoltà di sospendere il Servizio
                in ogni momento, in tutto o in parte, anche in assenza di
                preavviso; fatte salve le ipotesi sopra descritte, WeeBo
                comunicherà al Cliente gli interventi di manutenzione a mezzo
                e-mail con un preavviso di 48 ore, riportando in tale messaggio
                anche le tempistiche necessarie per il ripristino dei Servizi.
                Il Cliente, inoltre, conviene e concorda che WeeBo non potrà
                essere ritenuta responsabile per eventuali danni, diretti o
                indiretti, di qualsiasi natura, subiti in relazione o a causa di
                dette interruzioni.
            </p>

            <!-- Articolo 7 -->
            <h3>Art. 7. Durata, rinnovi e recesso</h3>
            <p>
                <b>7.1</b> Le presenti CGS hanno durata indeterminata,
                diversamente dai singoli OdS che avranno la durata ivi indicata
                e/o indicata nella relativa Scheda Prodotto.
            </p>
            <p>
                <b>7.2</b> Salvo diversa indicazione fornita al Cliente, i
                Servizi saranno rinnovati automaticamente alla scadenza per
                periodi di uguale durata rispetto al periodo iniziale.
            </p>
            <p>
                <b>7.3</b> Il Cliente prende atto che non sarà possibile
                attivare il rinnovo automatico del Servizio laddove sia
                selezionata la modalità di pagamento mediante bonifico bancario.
            </p>
            <p>
                <b>7.4</b> Il Cliente potrà modificare dal proprio pannello di
                controllo la modalità del rinnovo, da automatico a manuale fino
                a 20 (venti) giorni prima della data di scadenza, e da manuale
                ad automatico fino a 30 (trenta) giorni prima della scadenza. In
                ogni caso, prima della scadenza dei Servizi, verrà inviata al
                Cliente una comunicazione contenente le informazioni necessarie
                per gestire il rinnovo.
            </p>
            <p>
                <b>7.5</b> La cessazione di un Ordine di Servizio comporta la
                cessazione anche delle CGS qualora il Cliente non abbia altri
                Servizi attivi. Le presenti CGS rimarranno in vigore fino a
                quando non scadrà l'ultimo Servizio acquistato dal Cliente.
            </p>
            <p>
                <b>7.6</b> Fermo quanto previsto dal successivo art. 8 ("Recesso
                del consumatore. Eccezione al diritto di recesso"), ciascuna
                Parte avrà facoltà di recedere dal Contratto mediante
                comunicazione scritta all'altra Parte, con preavviso di almeno
                30 (trenta) giorni. Decorso detto termine, le CGS e/o gli OdS
                oggetto del recesso dovranno ritenersi cessati ed i Servizi ad
                essi relativi saranno disattivati. In caso di esercizio del
                diritto di recesso da parte del Cliente, WeeBo non rimborserà i
                corrispettivi per le prestazioni non ancora adempiute; in caso
                di recesso ad opera di WeeBo, questa rimborserà al Cliente la
                quota parte dell'importo pagato corrispondente ai giorni non
                utilizzati fino alla naturale scadenza del Servizio, detratti i
                costi da sostenere o sostenuti. È escluso ogni ulteriore
                rimborso, indennizzo o risarcimento in favore del Cliente.
            </p>
            <p>
                <b>7.7</b> Il Cliente conviene ed accetta che il recesso dalle
                presenti CGS non avrà efficacia qualora tra il Cliente stesso e
                WeeBo sia pendente un rapporto contrattuale relativamente ad un
                OdS. Pertanto, ove il Cliente intenda recedere dalle presenti
                CGS, dovrà previamente o contestualmente recedere, dove
                possibile, anche dagli eventuali OdS in essere al momento della
                comunicazione del recesso.
            </p>

            <!-- Articolo 8 -->
            <h3>Art. 8. Recesso del consumatore</h3>
            <p>
                <b>8.1</b> Il Cliente persona fisica che richieda la prestazione
                di un servizio per fini estranei alla propria attività
                professionale ("Consumatore"), avrà facoltà di recedere
                liberamente senza dover fornire alcuna motivazione, dalle
                presenti CGS e/o da un singolo OdS entro i 14 (quattordici)
                giorni lavorativi successivi alla conclusione del relativo
                contratto, ai sensi e per gli effetti dell'art. 52 del D. Lgs
                206/2005 ("Codice del Consumo"). Il recesso potrà essere
                esercitato dal Cliente, ai sensi dell'art. 54 comma 1 Codice del
                Consumo, utilizzando il modulo di recesso di cui all'allegato I,
                parte B del Codice del Consumo o presentando una qualsiasi altra
                dichiarazione esplicita della sua decisione di recedere dal
                contratto, da inviarsi, a mezzo lettera raccomandata a/r o PEC,
                a WeeBo,
                <!-- Via Zanchi n. 22, (24126) Bergamo (BG) -->
                oppure aprendo un ticket di assistenza dal proprio pannello di
                controllo, prima della scadenza del periodo di recesso. Le
                informazioni circa l'esercizio del diritto di recesso fornite da
                WeeBo al Cliente sono visibili in calce al presente documento.
                L'onere della prova relativa al corretto esercizio del diritto
                di recesso, conformemente alla procedura sopra indicata, è in
                capo al Cliente. A seguito del corretto esercizio del diritto di
                recesso da parte del Cliente, WeeBo provvederà, ai sensi
                dell'art. 56 comma 1 Codice del Consumo, entro e non oltre i
                successivi 14 (quattordici) giorni, a rimborsare allo stesso i
                pagamenti da questi ricevuti, comprensivi, ove siano state
                sostenute, delle spese di consegna. WeeBo eseguirà il rimborso
                utilizzando lo stesso metodo di pagamento usato dal Cliente per
                la transazione iniziale, salvo espresso accordo diverso con il
                Cliente e a condizione che il Cliente non debba sostenere alcun
                costo quale conseguenza dell'utilizzo del diverso metodo di
                pagamento. WeeBo non sarà tenuta a rimborsare le spese di
                consegna qualora il Cliente abbia scelto espressamente un tipo
                di consegna diversa e più onerosa di quella offerta da WeeBo.
                Resta inteso che WeeBo potrà trattenere il rimborso fino a
                quando non abbia ricevuto i beni oppure finché il Cliente non
                abbia dimostrato di aver rispedito i beni, a seconda di quale
                situazione si verifichi per prima. Salvo che WeeBo non ritiri i
                beni direttamente, il Cliente avrà l'onere di restituire i beni
                senza indebito ritardo e in ogni caso entro 14 (quattordici)
                giorni dalla data in cui ha comunicato a WeeBo la sua decisione
                di recedere dalle presenti CGS o da uno specifico OdS. I
                relativi costi di spedizione sono a carico del Cliente, salvo il
                caso in cui WeeBo non avesse informato il Cliente al riguardo
                all'atto della conclusione del contratto.
            </p>
            <p>
                <b>8.2</b> Eccezione al diritto di recesso. Resta in ogni caso
                inteso che, con riferimento alle prestazioni di servizio, il
                diritto di recesso non potrà essere esercitato dal Cliente dopo
                la completa fornitura del servizio, come di seguito indicata, da
                parte di WeeBo qualora la fornitura stessa sia iniziata con
                l'espresso accordo del Cliente e questi abbia accettato di
                perdere il diritto di recesso a seguito della piena esecuzione
                del servizio da parte di WeeBo. A titolo meramente
                esemplificativo e non esaustivo, il Cliente prende atto che
                l'avvio della procedura di registrazione di un nome a dominio,
                di un nome a dominio premium, come l'attivazione di caselle di
                posta elettronica, così come ogni altro servizio in cui WeeBo ha
                proceduto ad effettuare la richiesta alle varie Authorities
                competenti e/o a fornitori terze parti, equivalgono a "completa
                fornitura del servizio" ai sensi dell'art. 59, co.1, lett. a)
                del D. Lgs 206/2005.
            </p>

            <!-- Articolo 9 -->
            <h3>Art. 9. Clausola risolutiva espressa</h3>
            <p>
                <b>9.1</b> WeeBo potrà risolvere le presenti CGS e ciascun OdS,
                ai sensi e per gli effetti dell'art. 1456 c. c., in caso di:
            </p>
            <ol type="a">
                <li>
                    mancato rispetto da parte del Cliente di quanto previsto dai
                    seguenti articoli delle presenti Condizioni Generali di
                    Servizio: art. 4 ("Natura dei servizi, corrispettivi e
                    pagamenti"), art. 5 ("Utilizzo dei Servizi e responsabilità
                    del Cliente"), art. 12 ("Diritti di proprietà intellettuale
                    e industriale"), nonché dalle disposizioni contenute nella
                    Sezione I ("Disposizioni per la richiesta di servizi da
                    parte del Cliente per conto terzi");
                </li>
                <li>
                    eventi di Forza Maggiore secondo quanto previsto all'art. 15
                    ("Forza Maggiore") delle presenti CGS;
                </li>
                <li>
                    insolvenza, fallimento o sottoposizione ad altra procedura
                    concorsuale o a procedure esecutive del Cliente.
                </li>
            </ol>
            <p>
                <b>9.2</b> Restano salvi ed impregiudicati i diritti di WeeBo al
                pagamento dei corrispettivi maturati in suo favore alla data
                della risoluzione ed al risarcimento dei danni subiti.
            </p>

            <!-- Articolo 10 -->
            <h3>Art. 10. Modifiche</h3>
            <p>
                <b>10.1</b> WeeBo si riserva il diritto di modificare in ogni
                tempo le presenti CGS nonché i termini e le condizioni degli OdS
                o degli ulteriori documenti che compongono il Contratto con il
                Cliente, mediante comunicazione via e-mail al Cliente con
                preavviso di almeno 30 (trenta) giorni, qualora ricorrano i
                seguenti motivi:
            </p>
            <ol type="a">
                <li>
                    mutamenti delle condizioni tecniche/economiche/contrattuali
                    imposte da terze parti (a titolo esemplificativo e non
                    esaustivo, fornitori e partner commerciali) e/o conseguenti
                    all'entrata in vigore di nuove disposizioni normative o
                    regolamentari, o alla modifica di disposizioni esistenti
                    (incluse le regole imposte dalle Authority dei nomi a
                    dominio) e/o conseguenti a variazioni annuali del tasso di
                    inflazione;
                </li>
                <li>necessità di mantenere un adeguato livello di servizio;</li>
                <li>
                    assicurare adeguati standard di sicurezza della piattaforma;
                </li>
                <li>entrata in vigore di nuove disposizioni di legge;</li>
                <li>
                    oppure modifica dei prezzi di vendita dei servizi o delle
                    modalità di fornitura dei Servizi.
                </li>
            </ol>

            <p>
                <b>10.2</b> Le modifiche di cui al punto precedente diventeranno
                efficaci decorsi 30 (trenta) giorni della data di comunicazione
                al Cliente. Nel caso di modifiche che determinino una modifica
                dei servizi prestati o un incremento del prezzo dei Servizi,
                qualora non accetti le nuove condizioni, il Cliente avrà diritto
                di recedere dal Contratto entro la data di entrata in vigore
                delle modifiche, senza penali né costi di disattivazione.
            </p>

            <!-- Articolo 11 -->
            <h3>Art. 11. Comunicazioni tra le Parti</h3>
            <p>
                <b>11.1</b> Le Parti convengono e concordano che l'utilizzo
                della posta elettronica costituirà il principale mezzo per
                l'invio delle comunicazioni al Cliente. Le comunicazioni
                verranno inviate da WeeBo all'indirizzo e-mail associato dal
                Cliente al proprio account. Il Cliente si impegna ad aggiornare
                tale indirizzo in caso di variazioni e a controllarlo su base
                quotidiana per essere tempestivamente informato delle
                comunicazioni inviate alla sua attenzione.
            </p>
            <p>
                <b>11.2</b> Il Cliente potrà contattare WeeBo secondo quanto
                previsto alla pagina Contattaci del Sito disponibile al seguente
                link
                <a href="https://weebo.cloud/#/contact"
                    >https://weebo.cloud/#/contact</a
                >.
            </p>

            <!-- Articolo 12 -->
            <h3>Art. 12. Diritti di proprietà intellettuale e industriale</h3>
            <p>
                <b>12.1</b> WeeBo e/o ogni eventuale suo dante causa rimangono i
                titolari dei diritti di proprietà e di sfruttamento economico
                relativi a invenzioni, programmi testi e opere (inclusi
                software, documentazione e programmi scritti, studi, ecc.) o
                quant'altro sia stato ideato, realizzato, sviluppato in funzione
                dei Servizi offerti al Cliente, al quale è concesso solo un
                diritto d'uso limitato e non trasferibile.
            </p>

            <!-- Articolo 13 -->
            <h3>Art. 13. Legge applicabile e Foro competente</h3>
            <p>
                <b>13.1</b> Il presente Contratto e tutti i diritti e gli
                obblighi derivanti dallo stesso saranno regolati e dovranno
                essere interpretati secondo la legge italiana.
            </p>
            <p>
                <b>13.2</b> Qualsiasi controversia inerente il presente
                Contratto o derivante dal presente Contratto o dalla sua
                esecuzione sarà devoluta alla cognizione esclusiva del Foro di
                competenza. Nel caso di Contratto stipulato da Consumatore sarà
                competente il Tribunale di domicilio o di residenza del Cliente.
            </p>

            <!-- Articolo 14 -->
            <h3 style="color: red">
                Art. 14. Risoluzione alternativa delle controversie.
            </h3>
            <p style="color: red">
                <b>14.1</b> Il Cliente è informato che in alternativa alla
                controversia giudiziale, sia il Cliente che WeeBo potranno
                avvalersi della soluzione alternativa delle dispute on line
                presentando un reclamo attraverso la piattaforma istituita con
                Regolamento UE 524/2013 del 21 maggio 2013, raggiungibile alla
                pagina
                <a href="https://ec.europa.eu/consumers/odr/main/index.cfm"
                    >https://ec.europa.eu/consumers/odr/main/index.cfm</a
                >
                ("Piattaforma ADR"); WeeBo sarà contattabile dall'organismo al
                seguente indirizzo supporto@weebo.cloud.
            </p>
            <p>
                <b>14.2</b> Il reclamo dovrà essere corredato dalle informazioni
                richieste dalla Piattaforma ADR e il Cliente e WeeBo dovranno
                accordarsi sull'organismo di risoluzione delle dispute
                ("Organismo ADR") cui devolvere la risoluzione alternativa della
                disputa, pena l'improcedibilità della disputa medesima.
            </p>
            <p>
                <b>14.3</b> La controversia dovrà essere conclusa entro 90
                (novanta) giorni dal momento in cui l'Organismo ADR riceverà il
                fascicolo completo dalla Piattaforma ADR, salvo il caso in cui
                l'Organismo ADR, per questioni particolarmente complesse,
                ritenga di prorogare, a propria discrezione, il termine di 90
                (novanta) giorni di calendario. La decisone dell'Organismo ADR
                sarà vincolante tra le Parti e non potrà essere impugnata
                qualora sia il Cliente che WeeBo forniranno il loro specifico
                assenso in tale direzione al momento in cui esprimeranno la
                propria volontà di avvalersi di tale strumento di risoluzione
                alternativo della controversia.
            </p>
            <p>
                <b>14.4</b> Le norme procedurali e i costi della procedura
                saranno comunicati dall'Organismo ADR.
            </p>

            <!-- Articolo 15 -->
            <h3>Art. 15. Forza Maggiore</h3>
            <p>
                <b>15.1</b> Fermo restando quanto previsto al precedente art.
                6.4 delle presenti GCS, per Forza Maggiore si intende in ogni
                caso (ma non esclusivamente): ogni evento naturale, fulmine o
                incendio, disordini interni, misure governative, mobilitazione,
                guerra, attacchi terroristici, ostacoli nel trasporto, sciopero,
                serrata, interruzioni commerciali, stagnazione
                nell'approvvigionamento, indisponibilità di uno o più membri del
                personale (a causa di malattia), epidemie, pandemie, barriere
                all'importazione ed all'esportazione.
            </p>
            <p>
                <b>15.2</b> Inoltre, si considera Forza Maggiore ogni
                malfunzionamento o guasto di internet, dati, reti,
                infrastrutture e impianti di telecomunicazione e di energia
                elettrica, cyber-criminalità, attacchi alla rete, attacchi
                (D)DoS, attacchi informativi su larga scala, interruzioni di
                corrente elettrica, eventi informatici diffusi subiti da WeeBo o
                dai sub-fornitori di WeeBo.
            </p>
            <p>
                <b>15.3</b> La parte che subisce l'evento di Forza Maggiore non
                sarà considerata inadempiente al presente Contratto o in altro
                modo responsabile nei confronti dell'altra parte per eventuali
                ritardi o mancata esecuzione di qualsiasi obbligo (e il termine
                per l'esecuzione sarà prorogato di conseguenza) se e nella
                misura in cui il ritardo o la mancata esecuzione sia dovuto ad
                un evento di Forza Maggiore. La presente clausola non si estende
                all'obbligo di pagamento di qualsiasi importo dovuto, che dovrà
                comunque essere adempiuto al termine dell'evento di Forza
                Maggiore, fermo restando quanto previsto al successivo articolo
                15.4.
            </p>
            <p>
                <b>15.4</b> Se l'evento di Forza Maggiore persiste per un
                periodo continuativo superiore ad 1 (uno) mese dalla data di
                inizio, l'altra parte può dare avviso alla parte che subisce
                l'evento di Forza Maggiore di risoluzione del presente
                contratto. L'avviso di risoluzione deve specificare la data di
                risoluzione, che non deve essere inferiore a 7 (sette) giorni
                lavorativi dalla data in cui l'avviso di risoluzione è stato
                dato. Una volta che è stato dato un avviso di risoluzione
                valido, il presente Contratto terminerà alla data di risoluzione
                indicata nell'avviso.
            </p>

            <!-- Articolo 16 -->
            <h3>Art. 16. Privacy</h3>
            <p>
                <b>16.1</b> Con riferimento al trattamento dei dati personali
                relativi al Cliente per finalità di fatturazione,
                amministrative, di gestione in generale del rapporto
                contrattuale con il Cliente, di tutela dei propri interessi e di
                conformità agli obblighi normativi cui WeeBo è soggetta a
                specifiche normative (a titolo esemplificativo, la normativa in
                materia di conservazione di dati di traffico telematico), nonché
                con riferimento al trattamento dei dati personali oggetto di
                alcuni Servizi quali SPID, PEC, registrazione e gestione di nomi
                a dominio (a seconda del Top Level Domain oggetto di
                registrazione) e di certificati SSL, WeeBo agirà in qualità di
                titolare del trattamento, come meglio specificato
                nell'informativa privacy disponibile al seguente link:
                <a href="https://weebo.cloud/#/legal/privacy-policy"
                    >https://weebo.cloud/#/legal/privacy-policy</a
                >.
            </p>
            <p>
                <b>16.2</b> Con riferimento all'esecuzione delle prestazioni
                oggetto dei Servizi che prevedono un trattamento di dati
                personali per conto del Cliente, il Cliente agisce tipicamente
                nel ruolo di titolare del trattamento, salve le ipotesi in cui
                il Cliente agisce in qualità di Responsabile del trattamento per
                conto di una terza parte che agisce in qualità di titolare del
                trattamento o in qualità di responsabile del trattamento, mentre
                WeeBo agisce tipicamente in quello di responsabile del
                trattamento per conto del Cliente, attenendosi alle istruzioni
                impartite dal Cliente e dettagliate per iscritto nel "Contratto
                per il trattamento dei dati (DPA) Standard" raggiungibile dalla
                pagina
                <a href="https://weebo.cloud/#/legal"
                    >https://weebo.cloud/#/legal</a
                >
                e, ove applicabile, da considerarsi accettato dal Cliente in
                quanto parte integrante delle presenti CGS.
            </p>
            <p>
                <b>16.3</b> Qualora il Cliente intenda personalizzare i
                contenuti di cui all'Allegato 2 del "Contratto per il
                trattamento dei dati (DPA) Standard" può scaricarne una versione
                compilabile al seguente link:
                <a href="https://weebo.cloud/#/legal"
                    >https://weebo.cloud/#/legal</a
                >, selezionando "Contratto per il trattamento dei dati (DPA)
                Versione compilabile". In questo caso, il Cliente si impegna a
                compilare e sottoscrivere il contratto rilevante, e ad inviarlo
                a: <a href="mailto:supporto@weebo.cloud">supporto@weebo.cloud</a>.
            </p>

            <!-- Articolo 17 -->
            <h3>Art. 17. Adesione a codici di condotta</h3>
            <p style="color: red">
                <b>17.1</b> Con riferimento ai servizi Server Virtuali, ai
                servizi Server Dedicati e al Servizio Hosting WeeBo aderisce su
                base volontaria al Codice di Condotta CISPE. Si sottolinea che
                proprio per la natura volontaria dell'adesione, eventuali
                infrazioni potranno essere portate esclusivamente all'attenzione
                del Complaint Committee previsto dal Codice di Condotta CISPE e
                gestite ai sensi della Compliant Procedure.
            </p>

            <!-- Articolo 18 -->
            <h3>
                Art. 18 Conformità al Regolamento UE 2022/2065 - Digital
                Services Act
            </h3>
            <p>
                <b>18.1</b> WeeBo e le sue società controllate adempiono agli
                obblighi previsti dal Regolamento UE n. 2022/2065 - Digital
                Services Act (di seguito "DSA"). I Clienti sono responsabili dei
                contenuti che caricano, condividono o rendono altrimenti
                disponibili sui nostri servizi. Qualsiasi contenuto che violi il
                DSA o altre leggi applicabili, le presenti Condizioni Generali
                e/o singoli OdS può essere soggetto a rimozione ed i Clienti
                possono essere soggetti alla sospensione o alla chiusura
                dell'account, anche su iniziativa di WeeBo.
            </p>
            <p>
                <b>18.2</b> WeeBo collaborerà con le autorità competenti come
                richiesto dalla normativa, anche fornendo informazioni (compresi
                i dati personali) e assistenza nelle indagini. Il punto di
                contatto unico per le autorità degli Stati membri e per la
                Commissione e il Consiglio europeo per i servizi digitali sarà
                raggiungibile, in inglese o in italiano, al seguente indirizzo
                e-mail: <a href="mailto:abuse@weebo.cloud">abuse@weebo.cloud</a>.
            </p>
            <p>
                <b>18.3</b> Se un individuo o un'Autorità viene a conoscenza
                della presenza sui servizi di WeeBo di specifiche informazioni
                e/o contenuti che la persona o l'Autorità ritiene essere
                contenuti illegali, la persona o l'Autorità può contattare WeeBo
                presso il suo punto di contatto unico al seguente indirizzo
                e-mail: <a href="mailto:abuse@weebo.cloud">abuse@weebo.cloud</a> e
                inviare una segnalazione che soddisfi tutti i seguenti
                requisiti, come previsto dall'articolo 16 del DSA:
            </p>
            <ol type="a">
                <li>
                    una spiegazione sufficientemente motivata dei motivi per cui
                    la persona o l'ente presume che le informazioni in questione
                    costituiscano contenuti illegali;
                </li>
                <li>
                    una chiara indicazione dell'ubicazione elettronica esatta di
                    tali informazioni, quali l'indirizzo o gli indirizzi URL
                    esatti e, se necessario, informazioni supplementari che
                    consentano di individuare il contenuto illegale adeguato al
                    tipo di contenuto e al tipo specifico di servizio di
                    memorizzazione di informazioni;
                </li>
                <li>
                    il nome e l'indirizzo di posta elettronica della persona o
                    dell'ente che presenta la segnalazione, tranne nel caso di
                    informazioni che si ritiene riguardino uno dei reati di cui
                    agli articoli da 3 a 7 della direttiva 2011/93/UE;
                </li>
                <li>
                    una dichiarazione con cui la persona o l'ente che presenta
                    la segnalazione conferma la propria convinzione in buona
                    fede circa l'esattezza e la completezza delle informazioni e
                    delle dichiarazioni ivi contenute.
                </li>
            </ol>
            <p>
                Una volta ricevuta la segnalazione, WeeBo invierà una conferma
                di ricezione della segnalazione all'individuo o all'Autorità
                senza ingiustificato ritardo.
            </p>
            <p>
                Inoltre, si considera che le segnalazioni di cui al presente
                articolo permettono di acquisire una conoscenza o consapevolezza
                effettiva in relazione alle specifiche informazioni in questione
                qualora consentano a WeeBo di individuare l'illegalità della
                pertinente attività o informazione senza un esame giuridico
                dettagliato (di seguito "Segnalazione").
            </p>
            <p>
                <b>18.4</b> In caso di Segnalazioni che soddisfano i requisiti
                di cui sopra, WeeBo comunicherà alla persona o all'Autorità,
                senza ingiustificato ritardo, anche in considerazione del caso
                stesso e della sua complessità, la propria decisione in merito
                alle informazioni a cui la segnalazione si riferisce (di seguito
                "Motivazione").
            </p>
            <p>
                <b>18.5</b> Se l'individuo o l'Autorità non concorda con la
                decisione di WeeBo, può contattare nuovamente WeeBo, al seguente
                indirizzo e-mail:
                <a href="mailto:abuse@weebo.cloud">abuse@weebo.cloud</a>,
                motivando le ragioni del disaccordo con la decisione di WeeBo.
                WeeBo esaminerà la richiesta e comunicherà all'individuo o
                all'Autorità la propria decisione finale. Nonostante la
                procedura di cui sopra, è sempre possibile segnalare il
                contenuto o l'attività presumibilale illegale alle autorità
                pubbliche.
            </p>

            <!-- FINE MAIN CONTENUTO -->

            <!-- DISPOSIZIONI EXTRA 1 -->

            <h2>
                SEZIONE I - DISPOSIZIONI PER LA RICHIESTA DI SERVIZI DA PARTE
                DEL CLIENTE PER CONTO TERZI
            </h2>

            <h3>Art. 1. Ambito di applicazione</h3>
            <p>
                <b>1.1</b> La presente sezione descrive diritti e obblighi
                aggiuntivi del Cliente che effettui l'acquisto dei Servizi di
                WeeBo per conto di soggetti terzi (ai fini del presente articolo
                anche "Utilizzatore/i"), offrendo a questi ultimi i Servizi
                medesimi, anche a titolo gratuito (di seguito, anche "Cliente
                per conto Terzi").
            </p>

            <h3>Art. 2. Obblighi del Cliente per conto Terzi</h3>
            <p><b>2.1</b> Il Cliente per conto Terzi si impegna a:</p>

            <ol type="a">
                <li>
                    portare a conoscenza e richiedere agli Utilizzatori di
                    accettare la documentazione contrattuale predisposta in
                    relazione al Servizio acquistato e pubblicata nel Sito
                    internet di WeeBo (inclusi, a titolo esemplificativo e non
                    esaustivo, lo specifico OdS per il Servizio di interesse,
                    GCS, Informativa privacy e Contratto per il trattamento dei
                    dati);
                </li>
                <li>
                    garantire fin da ora che gli Utilizzatori abbiano
                    sottoscritto con il Cliente per conto Terzi e la
                    documentazione contrattuale predisposta in relazione al
                    Servizio acquistato ed a conservarne valida copia da fornire
                    a WeeBo su richiesta o in caso di Audit posto in essere da
                    WeeBo direttamente o tramite soggetti terzi da quest'ultima
                    indicati;
                </li>
                <li>
                    garantire fin da ora che gli Ordini di Acquisto effettuati
                    per conto degli Utilizzatori non violeranno diritti di
                    terzi;
                </li>
                <li>
                    non utilizzare marchi o segni distintivi di proprietà di
                    WeeBo in mancanza di espressa autorizzazione scritta della
                    stessa;
                </li>
                <li>
                    fornire a WeeBo tutte le informazioni necessarie per la
                    corretta esecuzione del presente Contratto, garantendo che
                    le stesse siano attuali, corrette e veritiere;
                </li>
                <li>
                    eseguire correttamente gli ordini dei Servizi sul sito
                    internet di WeeBo;
                </li>
                <li>
                    fornire agli Utilizzatori un'idonea Informativa privacy, la
                    quale, nel caso di servizio di registrazione nomi a dominio,
                    dovrà contenere anche la specifica sui dati personali che
                    verranno comunicati alla Registration Authority ed ai terzi
                    fornitori di cui WeeBo si avvale per l'erogazione del
                    Servizio di registrazione del nome a dominio, e ad
                    ottenerne, dove necessario, espresso consenso al trattamento
                    dei dati nel rispetto delle normative applicabili. Il
                    Cliente per conto di Terzi riconosce ed accetta che nei casi
                    previsti dalle Registration Authority ed espressamente
                    richiesti dalla stessa, WeeBo potrà contattare direttamente
                    gli Utilizzatori per l'invio o la richiesta di informazioni
                    non a carattere commerciale, documentazione o chiarimenti;
                </li>
                <li>
                    mantenere aggiornati i dati personali propri e quelli degli
                    Utilizzatori, inclusi, ove presenti, i dati personali
                    riferibili al proprio personale incaricato della gestione
                    del presente Contratto, dando pronta comunicazione a WeeBo
                    di ogni eventuale aggiornamento dei medesimi;
                </li>
                <li>
                    garantire fin da ora che le identità e le informazioni di
                    contatto fornite dagli Utilizzatori relative a qualsiasi
                    Prodotto e Servizio offerto, o rese disponibili in relazione
                    alla singola registrazione, saranno trattate in conformità
                    con quanto previsto dalla vigente normativa applicabile in
                    materia di protezione dei dati personali, e che potranno
                    essere comunicate a WeeBo o a soggetti da questa autorizzati
                    al trattamento o che trattino i dati per conto di WeeBo in
                    qualità di responsabili. Qualora WeeBo debba fornire il
                    Servizio per il tramite di un altro Registrar accreditato,
                    il Cliente per conto Terzi garantisce che i Clienti finali
                    saranno adeguatamente informati della circostanza che WeeBo
                    provvederà a trasferire i loro dati a tale Registrar
                    accreditato al solo fine di consentire la prosecuzione della
                    fornitura del Servizio acquistato. Il Cliente per conto
                    Terzi dichiara di voler tenere indenne e manlevata WeeBo
                    verso qualunque richiesta di risarcimento del danno possa
                    essere avanzata dagli Utilizzatori o da terzi riguardo ai
                    servizi erogati da WeeBo;
                </li>
                <li>
                    inviare agli Utilizzatori ogni comunicazione di servizio che
                    WeeBo ritenga opportuno comunicare, ed il Cliente per conto
                    Terzi sarà direttamente responsabile nei confronti degli
                    Utilizzatori, e nei confronti di WeeBo, nel caso di mancato
                    tempestivo invio delle informazioni di cui al presente
                    punto;
                </li>
                <li>
                    garantire fin da ora che gli acquisti effettuati per conto
                    degli Utilizzatori, o comunque tutte le attività svolte dal
                    Cliente per conto Terzi saranno effettuate solo ed
                    esclusivamente a seguito di conferimento dell'incarico da
                    parte degli Utilizzatori per l'acquisto dei Servizi.
                </li>
            </ol>

            <p>
                <b>2.2</b> Con particolare riferimento al servizio di
                registrazione e mantenimento dei nomi a dominio, il Cliente per
                conto Terzi si impegna a:
            </p>
            <ol type="a">
                <li>
                    rispettare, nonché a far conoscere agli Utilizzatori, i
                    termini e condizioni, gli standards, policy, procedure e
                    pratiche previste dalle relative Registration Authority,
                    consultabili sui loro siti, il cui indirizzo è disponibile
                    alla pagina
                    <a
                        style="color: red"
                        href="https://www.iana.org/domains/root/db"
                        >https://www.iana.org/domains/root/db</a
                    >
                    nonché sulla pagina di WeeBo, consultabile alla pagina
                    <a
                        style="color: red"
                        href="https://weebo.cloud/#/legal/policy-tld-e-gtld"
                        >https://weebo.cloud/#/legal/policy-tld-e-gtld</a
                    >;
                </li>
                <li>
                    avvisare gli Utilizzatori che il Registrant del nome a
                    dominio ha i diritti ed obblighi stabiliti da ICANN,
                    disponibili alla pagina
                    <a
                        href="https://www.icann.org/resources/pages/benefits-2013-09-16-en"
                        >https://www.icann.org/resources/pages/benefits-2013-09-16-en</a
                    >
                    ;
                </li>
                <li>
                    portare a conoscenza degli Utilizzatori che per qualsivoglia
                    variazione riguardante il nome, il cognome,
                    l'Organizzazione/Società e/o l'indirizzo e-mail del
                    Registrant di un dominio gTLD gestito da ICANN, si
                    applicherà la procedura di seguito riportata in ottemperanza
                    a quanto previsto da ICANN in materia, pubblicata alla
                    pagina
                    <a
                        href="https://www.icann.org/resources/pages/transfer-policy-2016-06-01-en"
                        >https://www.icann.org/resources/pages/transfer-policy-2016-06-01-en</a
                    >
                    ("Policy");
                </li>
                <li>
                    portare a conoscenza degli Utilizzatori che, nel caso di
                    contestazioni relative alla assegnazione di nomi a dominio,
                    si applicheranno le regole generali delle rispettive
                    Authority competenti. Nel caso di contestazioni relative
                    alla assegnazione di tutti i TLD per i quali l'Authority
                    competente abbia aderito alle policy determinate da ICANN
                    troveranno applicazione le Uniform Domain Name Dispute
                    Resolution Policy (UDRP) consultabili alla pagina
                    <a
                        href="https://www.icann.org/resources/pages/help/dndr/udrp-en"
                        >https://www.icann.org/resources/pages/help/dndr/udrp-en</a
                    >;
                </li>
                <li>
                    portare a conoscenza degli Utilizzatori che in caso di
                    disputa relativa all'uso di un nome a dominio,
                    l'assegnatario si impegna a prestare la massima
                    collaborazione e a presentarsi dinanzi alle corti del
                    domicilio dell'assegnatario e della sede legale del
                    Registrar, oltre che dinanzi a eventuali ulteriori
                    giurisdizioni competenti.
                </li>
            </ol>

            <h3>Art. 3. Responsabilità del Cliente per conto Terzi</h3>

            <p>
                <b>3.1</b> Il Cliente per conto Terzi è responsabile in via
                esclusiva, entro i limiti consentiti dalla normativa
                applicabile, in ordine a:
            </p>
            <ol type="a">
                <li>
                    gli obblighi assunti in forza del presente Contratto e, in
                    particolare, rispetto al corretto adempimento delle
                    disposizioni previste dal presente articolo;
                </li>
                <li>
                    le operazioni che egli effettua mediante il proprio account,
                    tra cui la creazione, la modifica, il rinnovo, la
                    soppressione, la rimozione dei Servizi per conto degli
                    Utilizzatori, la corretta comunicazione a WeeBo e alle
                    autorità competenti dei dati riguardanti gli Utilizzatori ed
                    il loro aggiornamento;
                </li>
                <li>
                    la vendita, la concessione o la messa a disposizione in
                    favore degli Utilizzatori dei Servizi di WeeBo a condizioni
                    non conformi da quelle praticate da WeeBo verso i propri
                    Clienti (a titolo esemplificativo, WeeBo non potrà essere
                    ritenuta responsabile nell'ipotesi in cui il Cliente per
                    conto Terzi venda o conceda agli Utilizzatori i Servizi per
                    una durata superiore a quella prevista da WeeBo per i
                    singoli Servizi che il Cliente per conto Terzi ha
                    acquistato);
                </li>
                <li>
                    ogni conseguenza in ordine alla mancata rinnovazione dei
                    Servizi per scelta del medesimo Cliente per conto Terzi o
                    degli Utilizzatori (es. perdita dei nomi a dominio, perdita
                    dei dati, etc.);
                </li>
                <li>
                    qualsiasi controversia, reclamo, disputa, condanna di
                    qualsiasi natura che dipenda direttamente o indirettamente
                    da un conflitto sorto tra il Cliente per conto Terzi ed
                    ulteriori soggetti terzi, o tra il primo e gli Utilizzatori;
                </li>
                <li>
                    ogni conseguenza derivante l'acquisto non autorizzato degli
                    Utilizzatori dei Servizi di WeeBo.
                </li>
            </ol>

            <p>
                <b>3.2</b> Il Cliente per conto Terzi assume ogni responsabilità
                in relazione agli Ordini inoltrati a WeeBo e si obbliga, nella
                misura massima consentita dalla legge, a manlevare e tenere
                indenne WeeBo, nonché i loro dipendenti ed ausiliari, da
                qualsiasi pretesa, costo, sanzione, verso terzi, ivi comprese le
                competenti Autorità giudiziarie, relativa e comunque connessa
                all'esecuzione del presente Contratto e/o alla violazione, da
                parte del primo o degli Utilizzatori, delle obbligazioni,
                dichiarazioni e garanzie di cui alle presenti CGS o al relativo
                OdS.
            </p>

            <p>
                <b>3.3</b> In caso di violazione da parte del Cliente per conto
                Terzi, ovvero degli Utilizzatori, di qualsiasi disposizione del
                presente Contratto, WeeBo si riserva il diritto di non attivare
                e/o sospendere i Servizi, ferme le ulteriori ipotesi di
                sospensione e cessazione del rapporto previste dalle presenti
                CGS o dai singoli OdS. WeeBo si riserva inoltre di avviare
                qualsiasi procedimento davanti alla competente autorità
                giudiziaria, amministrativa e arbitrale per avere la riparazione
                e/o il risarcimento del pregiudizio subito.
            </p>

            <p>
                <b>3.4</b> Fatto salvo quanto disposto negli articoli che
                precedono, resta inteso fra le parti che in caso di Servizi che
                abbiano ad oggetto la registrazione, la gestione e/o il
                mantenimento di nomi a dominio, il Cliente per conto Terzi
                dichiara di essere stato autorizzato per scritto
                dall'Utilizzatore del dominio ad effettuare in nome e per conto
                dell'Utilizzatore tutte le operazioni relative alla gestione, al
                mantenimento e/o alla gestione del dominio, e si impegna a
                fornire, su richiesta di WeeBo, la documentazione che attesti
                tale autorizzazione. Il Cliente per conto Terzi, pertanto,
                dichiara di volere tenere indenne e manlevata WeeBo verso
                qualunque richiesta possano avanzare gli Utilizzatori ed i terzi
                a riguardo.
            </p>

            <p>
                <b>3.5</b> Fatto salvo quanto disposto dal precedente art. 3.4,
                il Cliente per Conto Terzi prende atto ed accetta che la
                decisione ultima in merito a qualunque operazione di
                registrazione, mantenimento e gestione del dominio
                dell'Utilizzatore spetterà all'Utilizzatore medesimo. Qualora lo
                ritenga necessario, a suo insindacabile giudizio, prima di
                procedere con qualunque operazione che interessi il dominio
                dell'Utilizzatore, WeeBo potrà pertanto procedere a contattare
                l'Utilizzatore per chiedere relativa conferma.
            </p>

            <h3>Art. 4. Assenza di esclusiva</h3>
            <p>
                <b>4.1</b> In ogni caso, il Cliente per conto Terzi prende atto
                e accetta che non è previsto alcun diritto di esclusiva in suo
                favore e che WeeBo avrà la facoltà di stipulare contratti
                direttamente con gli Utilizzatori, anche aventi ad oggetto
                prodotti o servizi identici o analoghi a quelli forniti dal
                Cliente per conto Terzi.
            </p>

            <h3>
                Art. 5. Continuità dei Servizi in caso di cessazione del
                Contratto
            </h3>
            <p>
                <b>5.1</b> In caso di cessazione o risoluzione del presente
                Contratto per qualsiasi motivo, il Cliente per conto Terzi
                dovrà, con congruo preavviso, adoperarsi affinché i Servizi
                possano continuare ad essere forniti da WeeBo senza soluzione di
                continuità, salvo espressa indicazione contraria proveniente
                dall'Utilizzatore o da WeeBo. In tal caso, laddove la gestione
                dei Servizi fosse affidata direttamente agli Utilizzatori, tali
                Servizi saranno assegnati "così come sono", nello stato tecnico
                ed amministrativo nei quali sono stati lasciati dal Cliente per
                conto Terzi, salvo diversa volontà di WeeBo. WeeBo, a tal
                riguardo, declina ogni responsabilità nei confronti degli
                Utilizzatori circa le prestazioni fino a quel momento eseguite
                dal Cliente per conto Terzi. Inoltre, salva diversa volontà di
                WeeBo, quest'ultima non si sostituirà al Cliente per conto Terzi
                nelle prestazioni da questi eseguite per conto degli
                Utilizzatori, tra cui il servizio di gestione dei domini, di
                aggiornamento del sito internet del Cliente per Conto Terzi, di
                aggiornamento dei dati identificativi degli Utilizzatori, etc.
            </p>

            <!-- FINE DISPOSIZIONI EXTRA 1 -->

            <!-- DISPOSIZIONI EXTRA 2 -->

            <h2>SEZIONE II - DISPOSIZIONI PER I SERVIZI IN PROVA</h2>
            <h3>
                Art. 1. Oggetto e conclusione del contratto del Servizio in
                Prova
            </h3>
            <p>
                <b>1.1</b> Al fine di consentire al Cliente di testare le
                caratteristiche e le qualità dei propri prodotti e servizi, per
                taluni di essi o in occasione di particolari promozioni, WeeBo
                potrà offrire al Cliente la possibilità di acquistare i Servizi
                beneficiando di un periodo di utilizzo gratuito dei medesimi (di
                seguito anche "Periodo di Prova").
            </p>
            <p style="color: red">
                <b>1.2</b> Per quanto non diversamente previsto nei seguenti
                articoli, trovano applicazione le Condizioni Generali di
                Servizio di WeeBo e l'Ordine di Servizio per il servizio
                attivato con Periodo di Prova (disponibili alla pagina
                <a href="https://weebo.cloud/#/legal"
                    >https://weebo.cloud/#/legal</a
                >), da intendersi come parte integrante e sostanziale del
                presente documento, che il Cliente dichiara di aver visionato ed
                accettato e che si impegna, anche durante il Periodo di Prova, a
                rispettare. Il contratto avente ad oggetto il Servizio in cui è
                previsto un Periodo di Prova ("Servizio in Prova") si
                perfeziona, nel momento in cui WeeBo, previa ricezione
                dell'Ordine da parte del Cliente anche in via telematica,
                procederà all'attivazione del Servizio.
            </p>

            <h3>Art. 2. Durata del Periodo di Prova</h3>
            <p>
                <b>2.1</b> La durata del Periodo di prova gratuita è portata a
                conoscenza del Cliente assieme alle caratteristiche del Servizio
                in prova ed è visibile dal Pannello di controllo del Cliente.
            </p>

            <h3>Art. 3. Mezzi di Pagamento del Servizio in Prova</h3>
            <p>
                <b>3.1</b> A seconda del tipo di servizio offerto, in fase di
                attivazione del Servizio in Prova, sarà consentito al Cliente
                di:
            </p>
            <ol type="a">
                <li>
                    indicare i dati della propria carta di credito o di un
                    proprio account Stripe, o di associare al Servizio i dati di
                    carta di credito o account Stripe già memorizzati
                    all'interno del proprio account WeeBo, da poter utilizzare
                    per il pagamento del Servizio al termine del Periodo di
                    prova e per gli ulteriori rinnovi;
                </li>
                <li>
                    non indicare, né associare al Servizio, i dati di una carta
                    di credito o di un account Stripe.
                </li>
            </ol>

            <h3>Art. 4. Acquisto definitivo del Servizio</h3>
            <p>
                <b>4.1</b> A seconda dei casi previsti alle lettere a) e b) del
                precedente articolo 3.1, l'acquisto definitivo del Servizio
                verrà eseguito come di seguito indicato:
            </p>

            <ol type="a">
                <li>
                    Nel caso previsto dalla lettera a) del precedente art. 3
                    ("Mezzi di Pagamento del Servizio in Prova"), il Cliente
                    acquisterà il Servizio a titolo definitivo qualora non
                    comunichi la sua intenzione di non voler procedere a tale
                    acquisto definitivo entro i 5 (cinque) giorni antecedenti lo
                    scadere del Periodo di Prova. In tal caso WeeBo addebiterà
                    al Cliente il corrispettivo per il Servizio richiesto così
                    come indicato nella Scheda Prodotto, mediante addebito su
                    carta di credito o sull'account Stripe del Cliente; il
                    Servizio proseguirà senza soluzione di continuità dopo il
                    Periodo di Prova e resterà attivo per la durata prevista
                    nella Scheda Prodotto o nel relativo OdS. I dati della carta
                    di credito o dell'account Stripe saranno associati
                    all'account del Cliente per la gestione dei futuri pagamenti
                    ed i dati relativi alla carta di credito saranno memorizzati
                    per la gestione dei successivi pagamenti dalla Società
                    regolarmente certificata PCI - DSS che svolgerà il servizio
                    per conto di WeeBo.
                </li>
                <li>
                    nel caso previsto dalla lettera b) del precedente art. 3
                    ("Mezzi di Pagamento del Servizio in Prova"), il Cliente
                    acquisterà il Servizio a titolo definitivo qualora comunichi
                    la sua intenzione di voler procedere a tale acquisto entro
                    la scadenza del Periodo di Prova, conseguentemente, in
                    mancanza di comunicazione in tal senso, la fruizione del
                    Servizio terminerà allo scadere del Periodo di Prova.
                </li>
            </ol>

            <!-- FINE DISPOSIZIONI EXTRA 2 -->

            <h2>APPROVAZIONE DELLE CLAUSOLE VESSATORIE</h2>
            <p>
                Ai sensi e per gli effetti degli artt. 1341 e 1342 c. c., il
                Cliente dichiara di avere letto attentamente e di approvare
                specificamente le seguenti clausole delle CGS:
            </p>
            <ol type="i">
                <li>art. 6 ("Limitazioni della responsabilità di WeeBo");</li>
                <li>art. 7 ("Durata, rinnovi e recesso");</li>
                <li>
                    art. 8 ("Recesso del consumatore. Eccezione al diritto di
                    recesso");
                </li>
                <li>art. 9 ("Clausola risolutiva espressa");</li>
                <li>art. 10 ("Modifiche");</li>
                <li>art. 13 ("Legge applicabile e foro competente");</li>
                <li>
                    art. 15 ("Forza Maggiore") nonché le disposizioni contenute
                    (viii) nella Sezione I ("Disposizioni per la richiesta di
                    servizi da parte del cliente per conto terzi");
                </li>
                <li>nella Sezione II ("Disposizioni Servizi in prova")</li>
            </ol>

            <!-- SEZIONE 3 -->

            <h2>
                SEZIONE III - INFORMAZIONI RILEVANTI PER I CONSUMATORI AI SENSI
                DELL'ART. 49 DEL CODICE DEL CONSUMO
            </h2>
            <p>
                Le presenti Informazioni Rilevanti si applicano alla categoria
                dei cd. "Consumatori" così come definiti all'art. 3, comma 1,
                lett. a) del D. Lgs. 206/2005 e formano parte integrante delle
                Condizioni Generali di Servizio applicate da WeeBo e degli
                Ordini di Servizio relativi ai Servizi acquistati a distanza o
                fuori dei locali commerciali. Il Cliente/Consumatore è informato
                di quanto segue:
            </p>
            <ol type="a">
                <li>
                    Identità di WeeBo: WeeBo
                    <!-- è una società
                    con sede in Firenze (FI) Viale della Giovine Italia n. 17, e
                    C. F. P. IVA 04628270482, numero di telefono: 055-200211,
                    numero di fax: 055-20021550; -->
                    ulteriori indicazioni su come contattare WeeBo sono
                    pubblicati alla pagina
                    <a href="https://weebo.cloud/#/contact"
                        >https://weebo.cloud/#/contact</a
                    >
                    superficie o nelle forme indicate al punto f. Reclami;
                </li>
                <li>
                    Servizi offerti: i servizi offerti da WeeBo sono servizi di
                    registrazione di nomi a dominio, servizi hosting, e-mail,
                    PEC, SPID, fattura elettronica, servizi di fornitura di
                    piattaforme per la creazione di siti web, servizio server
                    dedicato e server virtuale, certificati SSL, servizi di
                    protezione dei siti web e servizi di promozione di siti web;
                    le caratteristiche di ognuno di questi servizi sono visibili
                    e facilmente accessibili dall'home page del sito di WeeBo
                    <a href="https://weebo.cloud">https://weebo.cloud</a>.
                </li>
                <li>
                    Prezzo dei servizi: il prezzo totale dei servizi,
                    comprensivi delle imposte e di eventuali spese di
                    spedizione, di consegna o postali, sono facilmente
                    accessibili all'interno di ciascuna area dedicata ai
                    servizi;
                </li>
                <li style="color: red">
                    Modalità di pagamento: le modalità di pagamento per
                    acquistare un servizio di WeeBo sono indicate in ogni
                    singolo Ordine di Servizio. Generalmente WeeBo accetta le
                    seguenti modalità di pagamento:
                    <ol type="i">
                        <li>carta di credito e carte prepagate</li>
                        <li>Stripe</li>
                        <li>Bonifico bancario</li>
                    </ol>
                    maggiori dettagli sono disponibili alla pagina
                    <a href="https://weebo.cloud/#/legal/payment"
                        >https://weebo.cloud/#/legal/payment</a
                    >.
                </li>
                <li>
                    Esecuzione dei servizi: l'esecuzione del servizio da parte
                    di WeeBo inizia a seguito del ricevimento del pagamento da
                    parte del Cliente e viene concluso nei termini indicati
                    nella parte illustrativa di ogni singolo servizio, come
                    detto in precedenza, facilmente accessibile dall'home page,
                    nonché nei singoli Ordini di Servizio; per alcuni servizi,
                    quali ad esempio la registrazione, trasferimento di domini,
                    il tempo di conclusione della fornitura di un servizio
                    dipende dalle competenti Registration Authorities;
                </li>
                <li style="color: red">
                    Reclami: i reclami dei Clienti, che potranno pervenire a
                    WeeBo
                    <!-- a mezzo posta di superficie al seguente indirizzo Via
                    Zanchi n. 22, 24126 Bergamo (BG), a mezzo PEC all'indirizzo
                    di Posta Elettronica Certificata di WeeBo comunicato al
                    Registro Imprese, oppure -->
                    tramite form Reclami messo a disposizione da WeeBo
                    (pubblicato alla pagina
                    <a href="https://weebo.cloud/#/contact"
                        >https://weebo.cloud/#/contact</a
                    >
                    ). I reclami vengono gestiti da WeeBo secondo l'ordine di
                    arrivo, salvo i casi più gravi che vengono gestiti con
                    precedenza rispetto agli altri. In ogni caso WeeBo offre il
                    massimo supporto ai propri Clienti e mediamente risponde ai
                    reclami entro 3 (tre) giorni lavorativi dal loro
                    ricevimento.
                </li>
                <li>
                    Diritto di recesso: il Cliente persona fisica che richieda
                    la prestazione di un servizio per fini estranei alla propria
                    attività professionale ("Consumatore"), avrà facoltà di
                    recedere liberamente senza dover fornire alcuna motivazione,
                    dalle Condizioni Generali di contratto e dai singoli Ordini
                    di Servizio entro i quattordici giorni lavorativi successivi
                    alla conclusione del relativo contratto, ai sensi e per gli
                    effetti dell'art. 52 del D. Lgs 206/2005 ("Codice del
                    Consumo"). Il recesso potrà essere esercitato dal Cliente,
                    ai sensi dell'art. 54 comma 1 Codice del Consumo,
                    utilizzando il modulo di recesso di cui all'allegato I,
                    parte B del Codice del Consumo o presentando una qualsiasi
                    altra dichiarazione esplicita della sua decisione di
                    recedere dal contratto, da inviarsi, a mezzo lettera
                    raccomandata a/r, a WeeBo,
                    <!-- Via Zanchi 22, 24126
                    Bergamo (BG), -->
                    ovvero aprendo un ticket dal proprio Pannello di Controllo,
                    prima della scadenza del periodo di recesso. L'onere della
                    prova relativa al corretto esercizio del diritto di recesso,
                    conformemente alla procedura sopra indicata, è in capo al
                    Cliente. A seguito del corretto esercizio del recesso da
                    parte del Cliente, WeeBo provvederà, ai sensi dell'art. 56
                    comma 1 Codice del Consumo, entro e non oltre i successivi
                    14 (quattordici) giorni, a rimborsare allo stesso i
                    pagamenti da questi ricevuti, comprensivi, ove siano state
                    sostenute, delle spese di consegna. WeeBo eseguirà il
                    rimborso utilizzando lo stesso mezzo di pagamento usato dal
                    Cliente per la transazione iniziale, salvo espresso accordo
                    diverso con il Cliente e a condizione che il Cliente non
                    debba sostenere alcun costo quale conseguenza dell'utilizzo
                    del diverso mezzo di pagamento. WeeBo non sarà tenuta a
                    rimborsare le spese di consegna qualora il Cliente abbia
                    scelto espressamente un tipo di consegna diversa e più
                    onerosa di quella offerta da WeeBo. Resta inteso che WeeBo
                    potrà trattenere il rimborso fino a quando non abbia
                    ricevuto i beni oppure finché il Cliente non abbia
                    dimostrato di aver rispedito i beni, a seconda di quale
                    situazione si verifichi per prima. Salvo che WeeBo non
                    ritiri i beni direttamente, il Cliente avrà l'onere di
                    restituire i beni senza indebito ritardo e in ogni caso
                    entro 14 (quattordici) giorni dalla data in cui ha
                    comunicato a WeeBo la sua decisione di recedere dalle
                    presenti CGS o da un ODS. I relativi costi di spedizione
                    sono a carico del Cliente, salvo il caso in cui WeeBo non
                    avesse informato il Cliente al riguardo all'atto della
                    conclusione del contratto. Eccezione al diritto di recesso.
                    Resta in ogni caso inteso che, con riferimento alle
                    prestazioni di servizio, il diritto di recesso non potrà
                    essere esercitato dal Cliente dopo la completa fornitura del
                    servizio, come di seguito indicata, da parte di WeeBo
                    qualora la fornitura stessa sia iniziata con l'espresso
                    accordo del Cliente e questi abbia accettato di perdere il
                    diritto di recesso a seguito della piena esecuzione del
                    servizio da parte di WeeBo. A titolo meramente
                    esemplificativo e non esaustivo, il Cliente prende atto che
                    l'avvio della procedura di registrazione di un nome a
                    dominio, di un nome a dominio premium, come l'attivazione di
                    caselle di posta elettronica, così come ogni altro servizio
                    in cui WeeBo ha proceduto ad effettuare la richiesta alle
                    varie Authorities competenti e/o a fornitori terze parti,
                    equivalgono a "completa fornitura del servizio" ai sensi
                    dell'art. 59, co.1, lett. a) del D. Lgs 206/2005.
                </li>
                <lI
                    >Assistenza post-vendita: il servizio di assistenza
                    post-vendita viene erogato tramite il canale Richiedi
                    Assistenza del pannello di controllo o chiamando il numero
                    presente su questa pagina
                    <a href="https://weebo.cloud/#/contact"
                        >https://weebo.cloud/#/contact</a
                    >
                    nei giorni e negli orari indicati.</lI
                >
                <li>
                    Durata delle condizioni generali di contratto e dei singoli
                    ordini di servizio: I singoli Ordini di Servizio formano
                    parte integrante e sostanziale delle Condizioni Generali di
                    Contratto e disciplinano i termini e le condizioni dello
                    specifico servizio acquistato dal Cliente, mentre le
                    Condizioni Generali di Contratto disciplinano il rapporto
                    tra WeeBo ed il Cliente, a prescindere dallo specifico
                    servizio acquistato. Le Condizioni Generali di Contratto
                    hanno una durata indeterminata, mentre i singoli Ordini di
                    Servizio relativi ai singoli servizi acquistati dal Cliente
                    hanno durata di un anno e sono rinnovabili o tacitamente o
                    espressamente. Il Cliente può modificare dal proprio
                    pannello di controllo la modalità del rinnovo da automatico
                    a manuale fino a 20 (venti) giorni prima della data utile
                    per il rinnovo e da manuale ad automatico fino a 30 (trenta)
                    giorni prima della data utile per il rinnovo. Prima della
                    scadenza dei servizi verrà inviata una mail con tutte le
                    informazioni necessarie per gestire il rinnovo. La
                    cessazione di un Ordine di Servizio comporta la cessazione
                    anche delle Condizioni Generali di Contratto qualora il
                    Cliente non abbia attivi altri servizi su WeeBo; in caso
                    contrario, le Condizioni Generali di Contratto rimarranno in
                    vigore fino a quando non scadrà l'ultimo servizio acquistato
                    dal Cliente. Non è possibile recedere dagli Ordini di
                    Servizio qualora il contratto si sia rinnovato ed il Cliente
                    in tal caso dovrà attendere la scadenza del contratto
                    stesso. Si invita dunque il Cliente, qualora abbia scelto la
                    modalità del rinnovo automatico e successivamente intenda
                    terminare il contratto, a prestare la massima attenzione e
                    ad impostare il proprio pannello di controllo da modalità da
                    rinnovo automatico a rinnovo manuale, in modo tale che alla
                    scadenza il contratto cesserà di produrre effetti tra WeeBo
                    ed il Cliente.
                </li>
                <li>
                    Depositi o altre garanzie finanziarie: generalmente non sono
                    richiesti al Cliente depositi o altre garanzie finanziarie.
                    Qualora nascesse la necessità di procedere con tali
                    richieste, sarà cura di WeeBo di informare prontamente il
                    Cliente con avvisi sul sito ed a mezzo e-mail.
                </li>
            </ol>
            <!-- FINE SEZIONE 3 -->

            <h1>Contatti</h1>
            <p class="p10">
                <span class="s1"
                    >Non esitate a contattarci se avete domande.</span
                >
            </p>
            <ul class="ul1">
                <li class="li16">
                    <span class="s3"
                        >Via Email:
                        <a href="mailto:supporto@weebo.cloud"
                            >supporto@weebo.cloud</a
                        ></span
                    >
                </li>
                <li class="li16">
                    <span class="s3"
                        >Via Phone Number:
                        <a href="tel:+393517994419">(+39) 351 7994 419</a></span
                    >
                </li>
                <li class="li16">
                    <span class="s3"
                        >Via this Link:<a href="https://weebo.cloud/#/contact"
                            >weebo.cloud/#/contact</a
                        ></span
                    >
                </li>
                <!---->
            </ul>
        </div>
    </div>
</section>
