<section class="solutions-area pb-100">
    <div class="container">

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="solutions-img">
                    <img src="assets/img/sol-complete.png" alt="Image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="solutions-wrap">
                    <div class="solutions-title">
                        <h2>Soluzioni complete di hosting</h2>
                        <p>Configurazione ed installazione facile e veloce. Dopo aver effettuato l'acquisto di un piano di hosting riceverete un email contenente tutti i dati necessari per la configurazione iniziale. Per qualsiasi problema potrai rivolgerti a uno dei nostri esperti che ti guiderà nella fase di configurazione (servizio di configurazione iniziale incluso).</p>
                    </div>
                    <ul class="solutions-list">
                        <li><i class="flaticon-right"></i> Dopo aver acquistato un piano di Hosting sarà possibile accedere immediatamente al proprio pannello di controllo</li>
                        <li><i class="flaticon-right"></i> Per collegare il proprio dominio all'hosting basterà modificare i DNS dal proprio register inserendo quelli di WeeBo che riceverete via email</li>
                        <li><i class="flaticon-right"></i> All'interno del proprio account, nella sezione servizi attivi, basterà cliccare su 'Accedi a Plesk'</li>
                        <li><i class="flaticon-right"></i> Installare un CMS con un solo clic, come WordPress, o optare per il caricamento del proprio sito web.</li>
                    </ul>
                    <a class="default-btn" href="https://weebo.cloud/area-clienti/index.php/store/hosting-condiviso">Ottieni il tuo hosting</a>
                </div>
            </div>
            
        </div>
    </div>
</section>