<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>News Grid</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Novità</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/news-details" class="d-block text-center"><img src="assets/img/blog/blog-img-one.png" alt="Image"></a>

                    <div class="blog-content-wrap">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-male"></i> Admin</a></li>
                            <li class="date"><i class="flaticon-appointment"></i> 20 Feb 2020</li>
                        </ul>
                        <h3><a routerLink="/news-details">Web domain hosting the best service work</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolorem.</p>

                        <a class="read-more" routerLink="/news-details">Read More<i class="bx bx-chevron-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/news-details" class="d-block text-center"><img src="assets/img/blog/blog-img-two.png" alt="Image"></a>

                    <div class="blog-content-wrap">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-male"></i> Admin</a></li>
                            <li class="date"><i class="flaticon-appointment"></i> 21 Feb 2020</li>
                        </ul>
                        <h3><a routerLink="/news-details">Web development hosting the best service work</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolorem.</p>

                        <a class="read-more" routerLink="/news-details">Read More<i class="bx bx-chevron-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/news-details" class="d-block text-center"><img src="assets/img/blog/blog-img-three.png" alt="Image"></a>

                    <div class="blog-content-wrap">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-male"></i> Admin</a></li>
                            <li class="date"><i class="flaticon-appointment"></i> 22 Feb 2020</li>
                        </ul>
                        <h3><a routerLink="/news-details">Web hosting the best service work future</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolorem.</p>

                        <a class="read-more" routerLink="/news-details">Read More<i class="bx bx-chevron-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/news-details" class="d-block text-center"><img src="assets/img/blog/blog-img-four.png" alt="Image"></a>

                    <div class="blog-content-wrap">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-male"></i> Admin</a></li>
                            <li class="date"><i class="flaticon-appointment"></i> 23 Feb 2020</li>
                        </ul>
                        <h3><a routerLink="/news-details">Kindness and motivation tips challenge difference</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolorem.</p>

                        <a class="read-more" routerLink="/news-details">Read More<i class="bx bx-chevron-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/news-details" class="d-block text-center"><img src="assets/img/blog/blog-img-five.png" alt="Image"></a>

                    <div class="blog-content-wrap">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-male"></i> Admin</a></li>
                            <li class="date"><i class="flaticon-appointment"></i> 24 Feb 2020</li>
                        </ul>
                        <h3><a routerLink="/news-details">Web hosting server very first the best works</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolorem.</p>

                        <a class="read-more" routerLink="/news-details">Read More<i class="bx bx-chevron-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <a routerLink="/news-details" class="d-block text-center"><img src="assets/img/blog/blog-img-six.png" alt="Image"></a>

                    <div class="blog-content-wrap">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-male"></i> Admin</a></li>
                            <li class="date"><i class="flaticon-appointment"></i> 25 Feb 2020</li>
                        </ul>
                        <h3><a routerLink="/news-details">Generally lorem ipsum is best suited to from server</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolorem.</p>

                        <a class="read-more" routerLink="/news-details">Read More<i class="bx bx-chevron-right"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="page-navigation-area">
                    <nav>
                        <ul class="pagination">
                            <li class="page-item"><a class="page-link page-links" routerLink="/news-grid"><i class='bx bx-chevrons-left'></i></a></li>

                            <li class="page-item active"><a class="page-link" routerLink="/news-grid">1</a></li>

                            <li class="page-item"><a class="page-link" routerLink="/news-grid">2</a></li>

                            <li class="page-item"><a class="page-link" routerLink="/news-grid">3</a></li>

                            <li class="page-item"><a class="page-link" routerLink="/news-grid"><i class='bx bx-chevrons-right'></i></a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</section>