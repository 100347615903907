<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Recover Password!</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Pages</li>
                        <li>User</li>
                        <li>Recover Password!</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="user-area-all-style recover-password-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="contact-form-action">
                    <div class="form-heading text-center">
                        <h3 class="form-title">Reset Password!</h3>
                        <p class="reset-desc">Enter the email of your account to reset the password. Then you will receive a link to email to reset the password. If you have any issue about reset password <a routerLink="/contact">contact us.</a></p>
                    </div>

                    <form >
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input class="form-control" type="text" name="name" placeholder="Enter Email Address">
                                </div>
                            </div>
                            
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <a class="now-log-in font-q" routerLink="/log-in">Log In</a>
                            </div>

                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <p class="now-register">Not a member? <a class="font-q" routerLink="/sign-up">Register</a></p>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button class="default-btn btn-two recover-password-btn" type="submit">Reset Password</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>