import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { ServicesComponent } from './pages/services/services.component';
import { AffiliateComponent } from './pages/affiliate/affiliate.component';
import { TeamComponent } from './pages/team/team.component';
import { FaqComponent } from './pages/faq/faq.component';
import { LogInComponent } from './pages/log-in/log-in.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { RecoverPasswordComponent } from './pages/recover-password/recover-password.component';
import { TermsConditionsComponent } from './pages/legal/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './pages/legal/privacy-policy/privacy-policy.component';
import { ContactComponent } from './pages/contact/contact.component';
import { PricingComponent } from './pages/pricing/pricing.component';
import { NewsGridComponent } from './pages/news-grid/news-grid.component';
import { NewsLeftSidebarComponent } from './pages/news-left-sidebar/news-left-sidebar.component';
import { NewsRightSidebarComponent } from './pages/news-right-sidebar/news-right-sidebar.component';
import { NewsDetailsComponent } from './pages/news-details/news-details.component';
import { DomainComponent } from './pages/domain/domain.component';
import { SharedHostingComponent } from './pages/shared-hosting/shared-hosting.component';
import { ResellerHostingComponent } from './pages/reseller-hosting/reseller-hosting.component';
import { DedicatedHostingComponent } from './pages/dedicated-hosting/dedicated-hosting.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { ErrorComponent } from './pages/error/error.component';
import { WordpressHostingComponent } from './pages/wordpress-hosting/wordpress-hosting.component';
import { Section1Component } from './components/section/section1/section1.component';
import { Section2Component } from './components/section/section2/section2.component';
import { SectionAssistenzaComponent } from './components/section/section3-assistenza/section3-assistenza.component';
import { SectionHostSolComponent } from './components/section/section4-host-sol/section4-host-sol.component';
import { SectionCompromessiComponent } from './components/section/section5-compromessi/section5-compromessi.component';
import { SectionSharedHostingComponent } from './components/section/section6-shared-hosting/section6-shared-hosting.component';
import { Section3TeamComponent } from './components/section/section7-team/section7-team.component';
import { Section4MapComponent } from './components/section/section8-map/section8-map.component';
import { Section5AskComponent } from './components/section/section9-ask/section9-ask.component';
import { Section6RecensioniComponent } from './components/section/section10-recensioni/section10-recensioni.component';
import { Section7NewsComponent } from './components/section/section11-news/section11-news.component';
import { SectionSolCompleteComponent } from './components/section/section13-sol-complete/section13-sol-complete.component';
import { SectionContactFormComponent } from './components/section/section12-contact-form/section12-contact-form.component';
import { TechnologyComponent } from './components/section/hostingCondiviso/technology/technology.component';
import { SearchDomainComponent } from './components/search-domain/search-domain.component';
import { BrandCollaborationComponent } from './components/brand-collaboration/brand-collaboration.component';
import { ContactFormWhiteComponent } from './components/section/contact-form-white/contact-form-white.component';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { SendMsgOkComponent } from './components/banner/send-msg-ok/send-msg-ok.component';
import { SendMsgErrorComponent } from './components/banner/send-msg-error/send-msg-error.component';
import { PowerHostingComponent } from './components/section/power-hosting/power-hosting.component';
import { WeeboServicesComponent } from './components/section/weebo-services/weebo-services.component';
import { NataleComponent } from './eventi/natale/natale.component';
import { PromoComponent } from './components/banner/promo/promo.component';
import { HomeBannerComponent } from './components/banner/home-banner/home-banner.component';
import { CookieComponent } from './pages/legal/cookie/cookie.component';
import { LegalComponent } from './pages/legal/legal.component';
import { PaymentComponent } from './pages/legal/payment/payment.component';
import { ListinoPrezziComponent } from './pages/legal/listino-prezzi/listino-prezzi.component';
import { OfferteComponent } from './pages/legal/offerte/offerte.component';
import { CreditoComponent } from './pages/legal/credito/credito.component';
import { InfoProdottoDominiComponent } from './pages/legal/info-prodotto-domini/info-prodotto-domini.component';
import { InfoProdottoHostingComponent } from './pages/legal/info-prodotto-hosting/info-prodotto-hosting.component';
import { InfoProdottoServerComponent } from './pages/legal/info-prodotto-server/info-prodotto-server.component';
import { InfoProdottoSitiWebComponent } from './pages/legal/info-prodotto-siti-web/info-prodotto-siti-web.component';
import { RegionMarketingComponent } from './components/section/region-marketing/region-marketing.component';
import { GdprComponent } from './pages/legal/gdpr/gdpr.component';
import { RegistrazioneDominiComponent } from './pages/legal/registrazione-domini/registrazione-domini.component';
import { AccetazioneClausoleComponent } from './pages/legal/accetazione-clausole/accetazione-clausole.component';
import { DomainSearchComponent } from './components/domain-search/domain-search.component';
import { HttpClientModule } from '@angular/common/http';
//import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    FooterComponent,
    HomeComponent,
    NavbarComponent,
    AboutComponent,
    ServicesComponent,
    AffiliateComponent,
    TeamComponent,
    FaqComponent,
    LogInComponent,
    SignUpComponent,
    RecoverPasswordComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    ContactComponent,
    PricingComponent,
    NewsGridComponent,
    NewsLeftSidebarComponent,
    NewsRightSidebarComponent,
    NewsDetailsComponent,
    DomainComponent,
    SharedHostingComponent,
    ResellerHostingComponent,
    DedicatedHostingComponent,
    ComingSoonComponent,
    ErrorComponent,
    WordpressHostingComponent,
    Section1Component,
    Section2Component,
    SectionAssistenzaComponent,
    SectionHostSolComponent,
    SectionCompromessiComponent,
    SectionSharedHostingComponent,
    Section3TeamComponent,
    Section4MapComponent,
    Section5AskComponent,
    Section6RecensioniComponent,
    Section7NewsComponent,
    SectionSolCompleteComponent,
    SectionContactFormComponent,
    TechnologyComponent,
    SearchDomainComponent,
    BrandCollaborationComponent,
    ContactFormWhiteComponent,
    SendMsgOkComponent,
    SendMsgErrorComponent,
    PowerHostingComponent,
    WeeboServicesComponent,
    NataleComponent,
    PromoComponent,
    HomeBannerComponent,
    CookieComponent,
    LegalComponent,
    PaymentComponent,
    ListinoPrezziComponent,
    OfferteComponent,
    CreditoComponent,
    InfoProdottoDominiComponent,
    InfoProdottoHostingComponent,
    InfoProdottoServerComponent,
    InfoProdottoSitiWebComponent,
    RegionMarketingComponent,
    GdprComponent,
    RegistrazioneDominiComponent,
    AccetazioneClausoleComponent,
    DomainSearchComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxGoogleAnalyticsModule.forRoot('G-4VGQV1DSVF'),
    NgxGoogleAnalyticsRouterModule,
    HttpClientModule,
    //FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
