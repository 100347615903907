<nav class="navbar navbar-expand-md navbar-light">
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <img src="assets/img/white-logo.png" alt="Logo">
        </a>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav">

                <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/" class="nav-link">Home</a></li>
                <li class="nav-item"><a routerLink="/domain" class="nav-link">Domini</a></li>
                <li class="nav-item">
                    <a href="javascript:void(0)" class="nav-link">Hosting <i class='bx bx-chevron-down'></i></a>

                    <ul class="dropdown-menu">
                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/shared-hosting" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Hosting Condiviso</a></li>

                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/wordpress-hosting" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Hosting WordPress</a></li>

                        <!-- <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/reseller-hosting" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Reseller Hosting</a></li> -->

                        <!-- <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Hosting Dedicato</a></li> -->
                    </ul>
                </li>

                <!-- <li class="nav-item">
                    <a href="javascript:void(0)" class="nav-link">Novità <i class='bx bx-chevron-down'></i></a>

                    <ul class="dropdown-menu">
                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/news-grid" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">News Grid</a></li>

                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/news-left-sidebar" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">News Left Sidebar</a></li>

                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/news-right-sidebar" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">News Right Sidebar</a></li>

                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/news-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">News Details</a></li>
                    </ul>
                </li> -->

                <li class="nav-item">
                    <a href="javascript:void(0)" class="nav-link">Supporto <i class='bx bx-chevron-down'></i></a>

                    <ul class="dropdown-menu">
                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Chi siamo</a></li>
                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/team" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team</a></li>
                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/services" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Informazioni</a></li>
                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contatti</a></li>
                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>
                        
                        

                        <!-- <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Tutorial</a></li> -->

                        

                        <!-- <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Prezzi</a></li> -->

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">User <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/log-in" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Log In</a></li>

                                <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/sign-up" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign Up</a></li>

                                <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/recover-password" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Recover Password</a></li>
                            </ul>
                        </li> -->

                        <!-- <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/terms-conditions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms Conditions</a></li>

                        <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li> -->

                        <!-- <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li> -->

                        <!-- <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error Page</a></li> -->
                    </ul>
                </li>

                <li class="nav-item others-option2">
                    <a href="javascript:void(0)" class="nav-link">Account <i class='bx bx-chevron-down'></i></a>
                    <ul class="dropdown-menu">
                        <li class="nav-item"><a class="nav-link" href="https://weebo.cloud/area-clienti/index.php">Login</a></li>
                        <li class="nav-item"><a class="nav-link" href="https://weebo.cloud/area-clienti/register.php">Registrati</a></li>
                    </ul>
                </li>

                <!-- <li class="nav-item" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show"><a routerLink="/contact" class="nav-link">Contatti</a></li> -->
                <li class="nav-item"><a href="https://tutorial.weebostore.it" target="_blank" class="nav-link">Tutorial</a></li>

                
            </ul>

            <div class="others-option">
                <!-- <a class="default-btn" routerLink="/sign-up">Registrati</a> -->
                <a class="default-btn" href="https://weebo.cloud/area-clienti/index.php">Login</a>
                <a class="default-btn" href="https://weebo.cloud/area-clienti/register.php">Registrati</a>
                
            </div>
        </div>
    </div>
</nav>