import { Component } from '@angular/core';
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';

@Component({
  selector: 'app-section12-contact-form',
  templateUrl: './section12-contact-form.component.html',
  styleUrls: ['./section12-contact-form.component.scss']
})
export class SectionContactFormComponent {

  showBanner = false;
  showError = false;

  public sendEmail(e: Event) {
    this.showBanner = true;
    e.preventDefault();
    emailjs.sendForm('service_0yeflyd', 'template_fbrn35g', e.target as HTMLFormElement, 'user_GD2ajDS9BoKDJLSU3CXMB')
      .then((result: EmailJSResponseStatus) => {
        // console.log(result.text);
      }, (error) => {
        this.showError = true;
      });
  }

}
