<div class="color">
    <div class="container">
        <h3>Clicca su i regali per ottenere un codice sconto a sopresa!</h3>
        <div class="xmas-stage">
            <div class="panel">
                <!-- <p>Countdown to {{ currentTime }}</p> -->
                <h1>Xmas is coming</h1>
                <!-- Counter -->
                <div class="counter">
                    <div class="counter-block">
                        <p #days class="counter-block-number"></p>
                        <div class="counter-block-label">Giorni</div>
                    </div>
                    <div class="counter-block">
                        <p #hours class="counter-block-number"></p>
                        <div class="counter-block-label">Ore</div>
                    </div>
                    <div class="counter-block">
                        <p #minutes class="counter-block-number"></p>
                        <div class="counter-block-label">Minuti</div>
                    </div>
                    <div class="counter-block">
                        <p #seconds class="counter-block-number"></p>
                        <div class="counter-block-label">Secondi</div>
                    </div>
                </div>
            </div>
            <!-- Albero -->
            <img src="./../../../assets/img/natale/tree.png" alt="albero" />

            <!-- Regali -->
            <a (click)="BannerShow()"><img class="gift gift_1" src="./../../../assets/img/natale/gift_1.png" alt="regalo 1"/></a>
            <a (click)="BannerShow()"><img class="gift gift_2" src="./../../../assets/img/natale/gift_2.png" alt="regalo 2"/></a>
            <a (click)="BannerShow()"><img class="gift gift_3" src="./../../../assets/img/natale/gift_3.png" alt="regalo 3"/></a>
            <app-promo *ngIf="showBanner"></app-promo>
            <!-- Palline -->
            <img
                class="ball ball_1"
                src="./../../../assets/img/natale/ball_1.png"
                alt="pallina 1"
            />
            <img
                class="ball ball_2"
                src="./../../../assets/img/natale/ball_2.png"
                alt="pallina 2"
            />
            <img
                class="ball ball_3"
                src="./../../../assets/img/natale/ball_3.png"
                alt="pallina 3"
            />
            <img
                class="ball ball_4"
                src="./../../../assets/img/natale/ball_4.png"
                alt="pallina 4"
            />
            <img
                class="ball ball_5"
                src="./../../../assets/img/natale/ball_5.png"
                alt="pallina 5"
            />
        </div>
    </div>
</div>