<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Prezzi Hosting</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Altro</li>
                        <li>Prezzi</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<app-section6-shared-hosting></app-section6-shared-hosting>

<app-contact-form-white></app-contact-form-white>