<section class="contact-area ptb-100" *ngIf="!showBanner">
    <div class="container">
        <div class="section-title">
            <h2>Contattaci</h2>
            <p>Per saperne di più o avere maggiori informazioni.</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <form id="contactForm" (submit)="sendEmail($event)">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>Nome</label>
                                <input type="text" name="user_name" class="form-control" placeholder="Nome">
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>Email</label>
                                <input type="email" name="user_email" class="form-control" placeholder="Email">
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>Numero</label>
                                <input type="text" name="user_phone" class="form-control" placeholder="Cellulare">
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <label>Oggetto</label>
                                <input type="text" name="user_subject" class="form-control" placeholder="Oggetto">
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Testo</label>
                                <textarea name="message" class="form-control" cols="30" rows="5" placeholder="Messaggio"></textarea>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <button type="submit" class="default-btn">Invia Messaggio</button>
                        </div>
                    </div>
                </form>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="contact-img text-center">
                    <img src="assets/img/contacts-img.png" alt="contacts">
                </div>
            </div>
        </div>
    </div>
</section>

<app-send-msg-ok *ngIf="showBanner"></app-send-msg-ok>
<app-send-msg-error *ngIf="showError"></app-send-msg-error>