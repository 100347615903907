import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-accetazione-clausole',
  templateUrl: './accetazione-clausole.component.html',
  styleUrls: ['./accetazione-clausole.component.scss']
})
export class AccetazioneClausoleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
