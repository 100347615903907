import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-send-msg-error',
  templateUrl: './send-msg-error.component.html',
  styleUrls: ['./send-msg-error.component.scss']
})
export class SendMsgErrorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
