<section class="domain-banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row">
                    <div class="banner-text">
                        <h1>
                            Cerca qui il tuo nuovo nome di dominio perfetto!
                        </h1>
                        <p>
                            Nomi di dominio a partire da 1,38€/Mese* Certificato
                            SSL gratuito incluso
                        </p>
                        <!-- 16,46€ dominio .com -->

                        <app-domain-search></app-domain-search>

                        <!-- <div class="search-domain">
                            <form class="domain-wrap only-domain">
                                <input type="text" class="input-domain" placeholder="Find your domain name here" name="Domain">
                                <button class="default-btn" type="submit" value="submit">Search</button>

                                <select class="select-domain-name">
                                    <option>.com</option>
                                    <option>.net</option>
                                    <option>.info</option>
                                    <option>.store</option>
                                    <option>.biz</option>
                                    <option>.xyz</option>
                                    <option>.rog</option>
                                </select>
                            </form>
                        </div> -->
                    </div>
                </div>
            </div>
            <p class="note">
                *La registrazione di un nome di dominio avviene su base annuale.
                Il prezzo indicato si riferisce a un dominio .com e può variare
                a seconda dell'estensione del nome di dominio scelta.
            </p>
        </div>
    </div>
</section>

<section class="domain-pricing-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Il Prezzo Migliore per i tuoi Domini</h2>
            <p>
                Trovare il giusto dominio per il tuo sito web non è mai stato
                così conveniente! Con la nostra vasta selezione di domini a
                prezzi competitivi, puoi trovare l'indirizzo web perfetto per
                rappresentare la tua attività online. Dai uno sguardo alle
                nostre offerte esclusive e assicurati il dominio migliore per il
                successo del tuo progetto online!
            </p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-domain">
                    <div class="domain-name-wrap">
                        <img
                            src="assets/img/shape/technology-icon-bg.png"
                            alt=""
                        />
                        <div class="domain-name">
                            <h3>.it</h3>
                        </div>
                    </div>

                    <p>
                        L'Italia e i suoi prodotti sono noti in tutto il mondo
                        per lo stile, la qualità e la bellezza. Se hai
                        un'attività sul territorio italiano o desideri
                        rivolgerti a residenti e turisti italiani il nome di
                        dominio .it è la scelta perfetta per te. Il nome di
                        dominio .it significa "Made in Italy" come poche altre
                        cose.
                    <span>€34,49 EUR <sub>/anno</sub></span>
                    <a
                        class="default-btn"
                        href="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                        >Registra</a
                    >
                </div>
            </div>
            
            <div class="col-lg-4 col-sm-6">
                <div class="single-domain">
                    <div class="domain-name-wrap">
                        <img
                            src="assets/img/shape/technology-icon-bg.png"
                            alt=""
                        />
                        <div class="domain-name">
                            <h3>.com</h3>
                        </div>
                    </div>

                    <p>
                        Creato nel primo gruppo di domini Internet all'inizio
                        del 1985, il suo nome deriva dalla parola commerciale,
                        indicando il suo scopo originale per sottodomini
                        registrati da organizzazioni commerciali.
                    </p>
                    <span>€16,46 EUR <sub>/anno</sub></span>
                    <a
                        class="default-btn"
                        href="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                        >Registra</a
                    >
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-domain">
                    <div class="domain-name-wrap">
                        <img
                            src="assets/img/shape/technology-icon-bg.png"
                            alt=""
                        />
                        <div class="domain-name">
                            <h3>.net</h3>
                        </div>
                    </div>

                    <p>
                        Il nome deriva dalla parola rete, indicando che
                        originariamente era destinato a organizzazioni coinvolte
                        nelle tecnologie di rete, come fornitori di servizi
                        Internet e altre aziende di infrastrutture.
                    </p>
                    <span>€19,59 EUR <sub>/anno</sub></span>
                    <a
                        class="default-btn"
                        href="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                        >Registra</a
                    >
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-domain">
                    <div class="domain-name-wrap">
                        <img
                            src="assets/img/shape/technology-icon-bg.png"
                            alt=""
                        />
                        <div class="domain-name">
                            <h3>.org</h3>
                        </div>
                    </div>

                    <p>
                        Il nome è troncato da 'organizzazione'. È stato uno dei
                        domini originali istituiti nel 1985 e è stato gestito
                        dal Public Interest Registry dal 2003.
                    </p>
                    <span>€18,90 EUR <sub>/anno</sub></span>
                    <a
                        class="default-btn"
                        href="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                        >Registra</a
                    >
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-domain">
                    <div class="domain-name-wrap">
                        <img
                            src="assets/img/shape/technology-icon-bg.png"
                            alt=""
                        />
                        <div class="domain-name">
                            <h3>.xyz</h3>
                        </div>
                    </div>

                    <p>
                        Il nome di dominio è stato scelto perché le tre lettere
                        sono le ultime dell'alfabeto latino e anche per fare
                        riferimento alle persone delle Generazioni X, Y e Z.
                    </p>
                    <span>€19,61 EUR <sub>/anno</sub></span>
                    <a
                        class="default-btn"
                        href="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                        >Registra</a
                    >
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-domain">
                    <div class="domain-name-wrap">
                        <img
                            src="assets/img/shape/technology-icon-bg.png"
                            alt=""
                        />
                        <div class="domain-name">
                            <h3>.info</h3>
                        </div>
                    </div>

                    <p>
                        Il dominio ".info" è destinato a essere un gTLD
                        versatile ed è comunemente utilizzato per siti web
                        informativi e contenuti online generici.
                    </p>
                    <span>€30,11 EUR <sub>/anno</sub></span>
                    <a
                        class="default-btn"
                        href="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                        >Registra</a
                    >
                </div>
            </div>
        </div>
    </div>
</section>

<section class="domain-pricing-list-area pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="domain-pricing-list">
                    <h2>Nomi di Dominio Popolari:</h2>

                    <div class="table-responsive">
                        <table class="domain-price">
                            <tr class="domain-mane-title">
                                <th>Dominio</th>

                                <th>Prezzo/Anno</th>

                                <th>Registra</th>
                            </tr>

                            <tr>
                                <td class="table-domain-name">
                                    .it
                                    <!-- <span class="sale">sale</span> -->
                                </td>

                                <td class="table-domain-price">€34,49 EUR</td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a
                                            href="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                                            class="default-btn"
                                            >Registra</a
                                        >
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="table-domain-name">.blog</td>

                                <td class="table-domain-price">
                                    €37,80 EUR
                                    <!-- <span class="save">save 50%</span> -->
                                </td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a
                                            href="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                                            class="default-btn"
                                            >Registra</a
                                        >
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="table-domain-name">.ca</td>

                                <td class="table-domain-price">€19,61 EUR</td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a
                                            href="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                                            class="default-btn"
                                            >Registra</a
                                        >
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="table-domain-name">
                                    .club<!-- <span class="sale">sale</span> -->
                                </td>

                                <td class="table-domain-price">€23,81 EUR</td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a
                                            href="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                                            class="default-btn"
                                            >Registra</a
                                        >
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="table-domain-name">.cn</td>

                                <td class="table-domain-price">
                                    €19,61 EUR
                                    <!-- <span class="save">save 50%</span> -->
                                </td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a
                                            href="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                                            class="default-btn"
                                            >Registra</a
                                        >
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="table-domain-name">.co.uk</td>

                                <td class="table-domain-price">€11,19 EUR</td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a
                                            href="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                                            class="default-btn"
                                            >Registra</a
                                        >
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="table-domain-name">
                                    .com
                                    <span class="sale">sale</span>
                                </td>

                                <td class="table-domain-price">€16,46 EUR</td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a
                                            href="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                                            class="default-btn"
                                            >Registra</a
                                        >
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="table-domain-name">.de</td>

                                <td class="table-domain-price">
                                    €11,55 EUR
                                    <span class="save">save 10%</span>
                                </td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a
                                            href="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                                            class="default-btn"
                                            >Registra</a
                                        >
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="table-domain-name">
                                    .eu<span class="sale">sale</span>
                                </td>

                                <td class="table-domain-price">
                                    €11,90 EUR
                                    <!-- <span class="save">save 50%</span> -->
                                </td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a
                                            href="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                                            class="default-btn"
                                            >Registra</a
                                        >
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="table-domain-name">
                                    .info<!-- <span class="sale">sale</span></td> -->
                                </td>

                                <td class="table-domain-price">
                                    €30,11 EUR
                                    <!-- <span class="save">save 50%</span> -->
                                </td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a
                                            href="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                                            class="default-btn"
                                            >Registra</a
                                        >
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="table-domain-name">
                                    .vip<!-- <span class="sale">sale</span></td> -->
                                </td>

                                <td class="table-domain-price">
                                    €23,81 EUR
                                    <!-- <span class="save">save 50%</span> -->
                                </td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a
                                            href="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                                            class="default-btn"
                                            >Registra</a
                                        >
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="table-domain-name">
                                    .xyz<!-- <span class="sale">sale</span></td> -->
                                </td>

                                <td class="table-domain-price">
                                    €19,61 EUR
                                    <span class="save">save 20%</span>
                                </td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a
                                            href="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                                            class="default-btn"
                                            >Registra</a
                                        >
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="domain-pricing-list">
                    <h2>Nomi di Dominio Internazionali:</h2>

                    <div class="table-responsive">
                        <table class="domain-price">
                            <tr class="domain-mane-title">
                                <th>Dominio</th>

                                <th>Prezzo/Anno</th>

                                <th>Registra</th>
                            </tr>

                            <tr>
                                <td class="table-domain-name">.io</td>

                                <td class="table-domain-price">
                                    €67,20 EUR
                                    <!-- <span class="save">save 50%</span> -->
                                </td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a
                                            href="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                                            class="default-btn"
                                            >Registra</a
                                        >
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="table-domain-name">
                                    .me <span class="sale">sale</span>
                                </td>

                                <td class="table-domain-price">€28,01 EUR</td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a
                                            href="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                                            class="default-btn"
                                            >Registra</a
                                        >
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="table-domain-name">.mobi</td>

                                <td class="table-domain-price">€40,61 EUR</td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a
                                            href="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                                            class="default-btn"
                                            >Registra</a
                                        >
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="table-domain-name">.net</td>

                                <td class="table-domain-price">
                                    €19,59 EUR
                                    <span class="save">save 20%</span>
                                </td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a
                                            href="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                                            class="default-btn"
                                            >Registra</a
                                        >
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="table-domain-name">.online</td>

                                <td class="table-domain-price">€42,00 EUR</td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a
                                            href="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                                            class="default-btn"
                                            >Registra</a
                                        >
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="table-domain-name">.org</td>

                                <td class="table-domain-price">
                                    €18,90 EUR
                                    <span class="save">save 30%</span>
                                </td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a
                                            href="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                                            class="default-btn"
                                            >Registra</a
                                        >
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="table-domain-name">
                                    .site
                                    <!-- <span class="sale">sale</span> -->
                                </td>

                                <td class="table-domain-price">€43,40 EUR</td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a
                                            href="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                                            class="default-btn"
                                            >Registra</a
                                        >
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="table-domain-name">
                                    .cloud
                                    <!-- <span class="sale">sale</span> -->
                                </td>

                                <td class="table-domain-price">€42,00 EUR</td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a
                                            href="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                                            class="default-btn"
                                            >Registra</a
                                        >
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="table-domain-name">
                                    .top <span class="sale">sale</span>
                                </td>

                                <td class="table-domain-price">€14,00 EUR</td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a
                                            href="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                                            class="default-btn"
                                            >Registra</a
                                        >
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="table-domain-name">
                                    .tw
                                    <!-- <span class="sale">sale</span> -->
                                </td>

                                <td class="table-domain-price">€33,60 EUR</td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a
                                            href="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                                            class="default-btn"
                                            >Registra</a
                                        >
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="table-domain-name">
                                    .us <span class="sale">sale</span>
                                </td>

                                <td class="table-domain-price">€14,00 EUR</td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a
                                            href="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                                            class="default-btn"
                                            >Registra</a
                                        >
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td class="table-domain-name">
                                    .education
                                    <!-- <span class="sale">sale</span> -->
                                </td>

                                <td class="table-domain-price">€40,61 EUR</td>

                                <td class="buy-btn-wrapper">
                                    <div class="btn-wrapper">
                                        <a
                                            href="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
                                            class="default-btn"
                                            >Registra</a
                                        >
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="protected-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Why Go With Hosta</h2>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                ipsum suspendisse.
            </p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="frequently-content-wrap">
                    <ul>
                        <li>
                            <i class="flaticon-database-1"></i>
                            <h3>What is wohust hosting</h3>
                            <p>
                                Lorem ipsum consectetur dolor sit amet,
                                consectetur adipiscing elit, sed do eiusmod
                                tempor incididun.
                            </p>
                        </li>
                        <li>
                            <i class="flaticon-diagram"></i>
                            <h3>Why choose wohust hosting</h3>
                            <p>
                                Lorem ipsum consectetur dolor sit amet,
                                consectetur adipiscing elit, sed do eiusmod
                                tempor incididun.
                            </p>
                        </li>
                        <li>
                            <i class="flaticon-uptime"></i>
                            <h3>If this an affiliate program?</h3>
                            <p>
                                Lorem ipsum consectetur dolor sit amet,
                                consectetur adipiscing elit, sed do eiusmod
                                tempor incididun.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="frequently-content-wrap">
                    <ul>
                        <li>
                            <i class="flaticon-migrating"></i>
                            <h3>Award-wining support</h3>
                            <p>
                                Lorem ipsum consectetur dolor sit amet,
                                consectetur adipiscing elit, sed do eiusmod
                                tempor incididun.
                            </p>
                        </li>
                        <li>
                            <i class="flaticon-network-1"></i>
                            <h3>The visoin for our mision</h3>
                            <p>
                                Lorem ipsum consectetur dolor sit amet,
                                consectetur adipiscing elit, sed do eiusmod
                                tempor incididun.
                            </p>
                        </li>
                        <li>
                            <i class="flaticon-database"></i>
                            <h3>All in one place</h3>
                            <p>
                                Lorem ipsum consectetur dolor sit amet,
                                consectetur adipiscing elit, sed do eiusmod
                                tempor incididun.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section> -->

<section class="dedicated-server-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="server-content-wrap">
                    <h2>Ogni dominio include</h2>
                    <p>
                        Con ogni dominio, offriamo un servizio completo che
                        comprende ciò che segue:
                    </p>

                    <ul>
                        <li>
                            <i class="flaticon-migrating"></i>
                            <span>Migrazione record DNS</span>
                            <p>
                                La migrazione dei record DNS è il trasferimento
                                di informazioni chiave, come gli indirizzi IP e
                                i record per la posta elettronica, da un
                                provider DNS a un altro. Una corretta esecuzione
                                garantisce la continuità operativa online e una
                                transizione senza problemi verso la nuova
                                infrastruttura DNS.
                            </p>
                        </li>
                        <li>
                            <i class="flaticon-migrating"></i>
                            <span>Diverse opzioni di Hosting</span>
                            <p>
                                Esistono diverse opzioni di hosting per siti
                                web: condiviso, VPS, cloud e dedicato. La scelta
                                dipende dalle esigenze di traffico, sicurezza e
                                budget del sito. <br />Hosting condiviso: per
                                siti di piccole-medie dimensioni con budget
                                limitato.<br />
                                Hosting WordPress: ottimizzato per siti basati
                                su WordPress. <br />Hosting Sitejet: piattaforma
                                intuitiva per la creazione e gestione di siti
                                web. <br />
                                La scelta dipende dalle esigenze di dimensioni,
                                budget e CMS del sito. <br /><strong>
                                    Contattaci per trovare insieme la soluzione
                                    migliore alle tue esigenze.</strong
                                >
                            </p>
                        </li>
                        <li>
                            <i class="flaticon-verified"></i>
                            <span>Protezione della Privacy (ID Protect)</span>
                            <p>
                                ID Protect Whois Privacy è un servizio
                                aggiuntivo che sostituisce i dettagli di
                                contatto su un dominio, visibili in una ricerca
                                Whois, con informazioni generiche. WeeBo è
                                conforme al GDPR, quindi la maggior parte delle
                                informazioni di contatto dei domini sarà
                                oscurata in una ricerca pubblica Whois per
                                impostazione predefinita.
                            </p>
                        </li>
                        <li>
                            <i class="flaticon-migrating"></i>
                            <span>Gestione Record DNS dal portale</span>
                            <p>
                                Gestisci facilmente i record DNS del tuo dominio
                                direttamente dal nostro portale, garantendo un
                                controllo totale e una configurazione rapida e
                                sicura.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="include-img text-center">
                    <img src="assets/img/include-img.png" alt="Image" />
                </div>
            </div>
        </div>
    </div>
</section>
