<!-- <div class="search-domain">
    <form class="domain-wrap" (ngSubmit)="submitForm()">
        <input type="text" class="input-domain" placeholder="Trova il tuo nuovo dominio" name="query" size="20" [(ngModel)]="domainQuery">
        <select class="select-domain-name" [(ngModel)]="selectedExtension">
            <option>.com</option>
            <option>.net</option>
            <option>.info</option>
            <option>.store</option>
            <option>.it</option>
            <option>.xyz</option>
            <option>.rog</option>
        </select>
        <button class="default-btn" type="submit">Cerca</button>
    </form>
</div>

<div [innerHTML]="serverResponse"></div> -->

<div class="search-domain">
    <form
        class="domain-wrap"
        action="https://weebo.cloud/area-clienti/cart.php?a=add&domain=register"
        method="post"
    >
        <input
            type="text"
            class="input-domain"
            placeholder="Trova il tuo nuovo dominio"
            name="query"
            size="20"
        />

        <button
            class="default-btn"
            type="submit"
            value="submit"
        >
            Cerca
        </button>
        <select class="select-domain-name">
            <option>.com</option>
            <option>.net</option>
            <option>.info</option>
            <option>.store</option>
            <option>.it</option>
            <option>.xyz</option>
            <option>.rog</option>
        </select>
    </form>
</div>