<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Dedicated Hosting</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Hosting</li>
                        <li>Dedicated Hosting</li>
                    </ul>
                </div>
            </div>

            <div class="special-intro-offer-wrap">
                <div class="row align-items-center">
                    <div class="col-lg-8 col-md-6">
                        <div class="special-intro-offer-list">
                            <ul>
                                <li><i class="flaticon-tick"></i> Number of Websites Unlimited</li>
                                <li><i class="flaticon-tick"></i> Bandwidth Unlimited</li>
                                <li><i class="flaticon-tick"></i> One Click Instant WordPress Install</li>
                                <li><i class="flaticon-tick"></i> Zero-Risk Money-Back Guarantee</li>
                                <li><i class="flaticon-tick"></i> 2.1 Million User Trusted Us</li>
                                <li><i class="flaticon-tick"></i> Subdomains</li>
                                <li><i class="flaticon-tick"></i> FTP/SFTP/Shell Users</li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="special-intro-offer">
                            <h3>Special Intro Offer</h3>
                            <span>$0.99 <sub>Monthly</sub></span>
                            <p>Regular Price <strong>$9.99/Monthly</strong></p>
                            <a class="default-btn" routerLink="/">Get Started</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="domain-pricing-list-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="domain-pricing-list">
                    <h2>Popular Domain Zones:</h2>

                    <table class="domain-price">
                        <tr class="domain-mane-title">
                            <th>Domain Zone</th>

                            <th>Price/Year</th>

                            <th>Register</th>
                        </tr>
                        
                        <tr>
                            <td class="table-domain-name">
                                .com <span class="sale">sale</span>
                            </td>

                            <td class="table-domain-price">
                                $1.99
                            </td>

                            <td class="buy-btn-wrapper">
                                <div class="btn-wrapper">
                                    <a routerLink="/" class="default-btn">Register</a>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="table-domain-name">
                                .net
                            </td>

                            <td class="table-domain-price">
                                $2.99 <span class="save">save 50%</span>
                            </td>

                            <td class="buy-btn-wrapper">
                                <div class="btn-wrapper">
                                    <a routerLink="/" class="default-btn">Register</a>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="table-domain-name">
                                .org
                            </td>

                            <td class="table-domain-price">
                                $3.99
                            </td>

                            <td class="buy-btn-wrapper">
                                <div class="btn-wrapper">
                                    <a routerLink="/" class="default-btn">Register</a>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="table-domain-name">
                                .store<span class="sale">sale</span>
                            </td>

                            <td class="table-domain-price">
                                $4.99
                            </td>

                            <td class="buy-btn-wrapper">
                                <div class="btn-wrapper">
                                    <a routerLink="/" class="default-btn">Register</a>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="table-domain-name">
                                .pro
                            </td>

                            <td class="table-domain-price">
                                $5.99 <span class="save">save 50%</span>
                            </td>

                            <td class="buy-btn-wrapper">
                                <div class="btn-wrapper">
                                    <a routerLink="/" class="default-btn">Register</a>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="table-domain-name">
                                .vip
                            </td>

                            <td class="table-domain-price">
                                $6.99
                            </td>

                            <td class="buy-btn-wrapper">
                                <div class="btn-wrapper">
                                    <a routerLink="/" class="default-btn">Register</a>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="table-domain-name">
                                .me <span class="sale">sale</span>
                            </td>

                            <td class="table-domain-price">
                                $7.99
                            </td>

                            <td class="buy-btn-wrapper">
                                <div class="btn-wrapper">
                                    <a routerLink="/" class="default-btn">Register</a>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="table-domain-name">
                                .co
                            </td>

                            <td class="table-domain-price">
                                $8.99 <span class="save">save 50%</span>
                            </td>

                            <td class="buy-btn-wrapper">
                                <div class="btn-wrapper">
                                    <a routerLink="/" class="default-btn">Register</a>
                                </div>
                            </td>
                        </tr>
                    </table> 
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="domain-pricing-list">
                    <h2>International Domain Zones:</h2>

                    <table class="domain-price">
                        <tr class="domain-mane-title">
                            <th>Domain Zone</th>

                            <th>Price/Year</th>

                            <th>Register</th>
                        </tr>

                        <tr>
                            <td class="table-domain-name">
                                .agency
                            </td>

                            <td class="table-domain-price">
                                $9.99 <span class="save">save 50%</span>
                            </td>

                            <td class="buy-btn-wrapper">
                                <div class="btn-wrapper">
                                    <a routerLink="/" class="default-btn">Register</a>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="table-domain-name">
                                .biz <span class="sale">sale</span>
                            </td>

                            <td class="table-domain-price">
                                $10.99
                            </td>

                            <td class="buy-btn-wrapper">
                                <div class="btn-wrapper">
                                    <a routerLink="/" class="default-btn">Register</a>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="table-domain-name">
                                .ga
                            </td>

                            <td class="table-domain-price">
                                $11.99
                            </td>

                            <td class="buy-btn-wrapper">
                                <div class="btn-wrapper">
                                    <a routerLink="/" class="default-btn">Register</a>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="table-domain-name">
                                .click
                            </td>

                            <td class="table-domain-price">
                                $12.99 <span class="save">save 50%</span>
                            </td>

                            <td class="buy-btn-wrapper">
                                <div class="btn-wrapper">
                                    <a routerLink="/" class="default-btn">Register</a>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="table-domain-name">
                                .me <span class="sale">sale</span>
                            </td>

                            <td class="table-domain-price">
                                $13.99
                            </td>

                            <td class="buy-btn-wrapper">
                                <div class="btn-wrapper">
                                    <a routerLink="/" class="default-btn">Register</a>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="table-domain-name">
                                .de
                            </td>

                            <td class="table-domain-price">
                                $14.99
                            </td>

                            <td class="buy-btn-wrapper">
                                <div class="btn-wrapper">
                                    <a routerLink="/" class="default-btn">Register</a>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="table-domain-name">
                                .education
                            </td>

                             <td class="table-domain-price">
                                $15.99 <span class="save">save 50%</span>
                            </td>

                            <td class="buy-btn-wrapper">
                                <div class="btn-wrapper">
                                    <a routerLink="/" class="default-btn">Register</a>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td class="table-domain-name">
                                .tk <span class="sale">sale</span>
                            </td>

                            <td class="table-domain-price">
                                $16.99
                            </td>

                            <td class="buy-btn-wrapper">
                                <div class="btn-wrapper">
                                    <a routerLink="/" class="default-btn">Register</a>
                                </div>
                            </td>
                        </tr>
                    </table> 
                </div>
            </div>
        </div>
    </div>
</section>

<section class="pricing-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Reseller Hosting Price Table</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>Basic</h3>
                        <p>Build a website</p>
                    </div>
                    <span>$29.00 <sub>/Month</sub></span>

                    <ul>
                        <li><i class="flaticon-tick"></i> 10GB Storage Space</li>
                        <li><i class="flaticon-tick"></i> 50GB Bandwidth</li>
                        <li><i class="flaticon-tick"></i> 10 Free Sub-Domains</li>
                        <li><i class="flaticon-tick"></i> 10GB Storage Space</li>
                        <li><i class="flaticon-tick"></i> 50GB Bandwidth</li>
                        <li><i class="flaticon-tick"></i> 10 Free Sub-Domains</li>
                    </ul>
                    <a class="default-btn" routerLink="/">Get Started</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>Comfort</h3>
                        <p>Build a website</p>
                    </div>
                    <span>$59.00 <sub>/Month</sub></span>

                    <ul>
                        <li><i class="flaticon-tick"></i> 15GB Storage Space</li>
                        <li><i class="flaticon-tick"></i> 50GB Bandwidth</li>
                        <li><i class="flaticon-tick"></i> 10 Free Sub-Domains</li>
                        <li><i class="flaticon-tick"></i> 15GB Storage Space</li>
                        <li><i class="flaticon-tick"></i> 100GB Bandwidth</li>
                        <li><i class="flaticon-tick"></i> 50 Free Sub-Domains</li>
                    </ul>
                    <a class="default-btn" routerLink="/">Get Started</a>
                </div>
                <span class="popular">Popular</span>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>Premium</h3>
                        <p>Build a website</p>
                    </div>
                    <span>$79.00 <sub>/Month</sub></span>

                    <ul>
                        <li><i class="flaticon-tick"></i> 20GB Storage Space</li>
                        <li><i class="flaticon-tick"></i> 50GB Bandwidth</li>
                        <li><i class="flaticon-tick"></i> 10 Free Sub-Domains</li>
                        <li><i class="flaticon-tick"></i> 420GB Storage Space</li>
                        <li><i class="flaticon-tick"></i> 50GB Bandwidth</li>
                        <li><i class="flaticon-tick"></i> 50 Free Sub-Domains</li>
                    </ul>
                    <a class="default-btn" routerLink="/">Get Started</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="technology-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>The Latest Hosting Server Technology</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-technology">
                    <div class="technology-icon-bg">
                        <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                        <i class="flaticon-settings"></i>
                    </div>

                    <h3>Easy To Setup</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                    <a class="read-more" routerLink="/shared-hosting">Read More <i class="bx bx-chevron-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-technology">
                    <div class="technology-icon-bg">
                        <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                        <i class="flaticon-password"></i>
                    </div>

                    <h3>Privacy Protected</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                    <a class="read-more" routerLink="/shared-hosting">Read More <i class="bx bx-chevron-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-technology">
                    <div class="technology-icon-bg">
                        <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                        <i class="flaticon-backup"></i>
                    </div>

                    <h3>Monthly Backup</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                    <a class="read-more" routerLink="/shared-hosting">Read More <i class="bx bx-chevron-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-technology">
                    <div class="technology-icon-bg">
                        <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                        <i class="flaticon-speedometer-1"></i>
                    </div>

                    <h3>Fast Performance</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                    <a class="read-more" routerLink="/shared-hosting">Read More <i class="bx bx-chevron-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-technology">
                    <div class="technology-icon-bg">
                        <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                        <i class="flaticon-database"></i>
                    </div>

                    <h3>Unlimited Database</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                    <a class="read-more" routerLink="/shared-hosting">Read More <i class="bx bx-chevron-right"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-technology">
                    <div class="technology-icon-bg">
                        <img src="assets/img/shape/technology-icon-bg.png" alt="Image">
                        <i class="flaticon-molecule"></i>
                    </div>

                    <h3>Multiple Network</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                    <a class="read-more" routerLink="/shared-hosting">Read More <i class="bx bx-chevron-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="hosting-solution-area ptb-100">
    <div class="container">
        <div class="hosting-solution-wrap">
            <h2>We Don't Compromise With The Best Hosting Solution</h2>
            <a class="default-btn" routerLink="/shared-hosting">Get Started</a>
            <a class="default-btn active" routerLink="/contact">Contact Us</a>
        </div>
    </div>

    <div class="shape shape-one"><img src="assets/img/shape/hosting-solution-one.png" alt=""></div>
    <div class="shape shape-two"><img src="assets/img/shape/hosting-solution-two.png" alt=""></div>
</section>

<section class="frequently-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Frequently Asked Questions</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="frequently-img">
                    <img src="assets/img/frequently-img.png" alt="Image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="frequently-content-wrap">
                    <ul>
                        <li>
                            <i class="flaticon-database"></i>
                            <h3>What is wohust hosting?</h3>
                            <p>Lorem ipsum consectetur dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.</p>
                        </li>
                        <li>
                            <i class="flaticon-database-1"></i>
                            <h3>Why choose wohust hosting?</h3>
                            <p>Lorem ipsum consectetur dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.</p>
                        </li>
                        <li>
                            <i class="flaticon-uptime"></i>
                            <h3>If this an affiliate program?</h3>
                            <p>Lorem ipsum consectetur dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididun.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="client-area client-area-four ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>What Our Client Say</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="client-wrap-two owl-carousel owl-theme">
                <div class="single-client">
                    <img src="assets/img/client/client-img-one.jpg" alt="Image">

                    <h3>Denial Peer</h3>
                    <span>Designer</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>

                    <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                    </ul>
                </div>

                <div class="single-client">
                    <img src="assets/img/client/client-img-two.jpg" alt="Image">

                    <h3>Juhon Dew</h3>
                    <span>Developer</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>

                    <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                    </ul>
                </div>

                <div class="single-client">
                    <img src="assets/img/client/client-img-three.jpg" alt="Image">

                    <h3>Jon Smith</h3>
                    <span>UI UX Designer</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>

                    <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                    </ul>
                </div>

                <div class="single-client">
                    <img src="assets/img/client/client-img-four.jpg" alt="Image">

                    <h3>Dath Smith Peer</h3>
                    <span>Designer</span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>

                    <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    
    <div class="shape shape-ome"><img src="assets/img/shape/client.png" alt="Image"></div>
</section>