import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section9-ask',
  templateUrl: './section9-ask.component.html',
  styleUrls: ['./section9-ask.component.scss']
})
export class Section5AskComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
